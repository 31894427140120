import { Paper } from '@material-ui/core'
import React from 'react'

const DetalleEmpleado = () => {
    return (
        <div>
            <div>
            <Paper elevation={3} className="paper">
                </Paper>
            </div>
        </div>
    )
}

export default DetalleEmpleado
