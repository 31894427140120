import {
  requestEventsInRange,
  requestEventCreate,
  requestEventUpdate,
  requestEventDelete,
} from "./requests";

export const GET_TOOGLE_WEEKENDS = 'GET_TOOGLE_WEEKENDS';
export const SET_TOOGLE_WEEKENDS = 'SET_TOOGLE_WEEKENDS';
export const GET_TOOGLE_WEEKENDS_REQUEST = 'GET_TOOGLE_WEEKENDS_REQUEST';
export const GET_TOOGLE_WEEKENDS_SUCCESS = 'GET_TOOGLE_WEEKENDS_SUCCESS';
export const GET_TOOGLE_WEEKENDS_FAILURE = 'GET_TOOGLE_WEEKENDS_FAILURE';
export const getToogleWeekendsAction = req => ({
    type: GET_TOOGLE_WEEKENDS,
    req
});

export const setToogleWeekendsAction = data => ({
    type: SET_TOOGLE_WEEKENDS,
    data
});

export const GET_REQUEST_EVENTS = 'GET_REQUEST_EVENTS';
export const SET_REQUEST_EVENTS = 'SET_REQUEST_EVENTS';
export const GET_REQUEST_EVENTS_REQUEST = 'GET_REQUEST_EVENTS_REQUEST';
export const GET_REQUEST_EVENTS_SUCCESS = 'GET_REQUEST_EVENTS_SUCCESS';
export const GET_REQUEST_EVENTS_FAILURE = 'GET_REQUEST_EVENTS_FAILURE';
export const getRequestEventsAction = req => ({
    type: GET_REQUEST_EVENTS,
    req,
    promise: requestEventsInRange(req),
});

export const setRequestEventsAction = data => ({
    type: SET_REQUEST_EVENTS,
    data
});
export default {
  toggleWeekends() {
    return {
      type: "TOGGLE_WEEKENDS",
    };
  },

  requestEvents(startStr, endStr) {
    return (dispatch) => {
      return requestEventsInRange(startStr, endStr).then(
        (plainEventObjects) => {
          dispatch({
            type: "RECEIVE_EVENTS",
            plainEventObjects,
          });
        }
      );
    };
  },

  createEvent(plainEventObject) {
    return (dispatch) => {
      return requestEventCreate(plainEventObject).then((newEventId) => {
        dispatch({
          type: "CREATE_EVENT",
          plainEventObject: {
            id: newEventId,
            ...plainEventObject,
          },
        });
      });
    };
  },

  updateEvent(plainEventObject) {
    return (dispatch) => {
      return requestEventUpdate(plainEventObject).then(() => {
        dispatch({
          type: "UPDATE_EVENT",
          plainEventObject,
        });
      });
    };
  },

  deleteEvent(eventId) {
    return (dispatch) => {
      return requestEventDelete(eventId).then(() => {
        dispatch({
          type: "DELETE_EVENT",
          eventId,
        });
      });
    };
  },
};
