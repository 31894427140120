import React, { Fragment, useState } from "react";
import { fade, makeStyles } from "@material-ui/core/styles";
import { Button, Modal, TextField } from "@material-ui/core";
import {
  getProfesionalAction,
  getUserAction,
  setProfesionalAction,
  setUserAction,
} from "../../../User/userAction";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUserNormalizer } from "../../../services/user";
import GoogleLogin from "react-google-login";
import { loginClinicaAPI } from "services/endpoint";
import axios from "axios";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";
import { EnumApi } from "Common/Enums";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  paper: {
    position: "absolute",
    //width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "95%",
    },
  },
  buttonLogin: {
    width: "100%",
  },
  btnGoogle: {
    width: "100%",
  },
  buttonRegister: {
    width: "100%",
    backgroundColor: "black",
    color: "white",
  },
  buttonTypeUser: {
    width: "100%",
    marginBottom: "1rem",
  },
  viewTypeUsers: {},
  title: {
    marginBottom: "1.5rem",
  },
}));
// const mapStateToProps = (state) => {
//   return state;
// };

// // const mapDispatchToProps = dispatch => bindActionCreators(ducks, dispatch);
// const mapDispatchToProps = (dispatch) => ({
//   setUserAction: (data) => dispatch(setUserAction(data)),
//   getUserAction: () => dispatch(getUserAction),
//   getProfesionalAction: () => dispatch(getProfesionalAction),
//   setProfesionalAction: (data) => dispatch(setProfesionalAction(data)),
// });

const AuthBasic = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const userSelector = useSelector((state) => state.user);
  //console.log(userSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setemail] = useState("");
  const [pass, setpass] = useState("");
  const onChangeEmail = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setemail(event.target.value);
  };

  const onChangePass = (event) => {
    setpass(event.target.value);
  };
  // const getdatauser = async (data) => {
  // setIsLoading(true);
  // const requestOptions = {
  //   method: "POST",
  //   headers: { "Content-Type": "application/json" },
  //   body: JSON.stringify({ email: data.email, uid: data.uid || null }),
  // };
  // await fetch(
  //   "https://us-central1-nowcompanyglobal.cloudfunctions.net/getdatauser",
  //   requestOptions
  // )
  //   .then((response) => response.json())
  //   .then(async (data) => {
  //     data = { ...data, isLogin: true };
  //     props.setUser(data);
  //     localStorage.setItem("user", JSON.stringify(data));
  //     await setUserAction({ data });
  //     props.handleCloseModal();
  //     setIsLoading(false);
  //     history.push("/dashboard");
  //   });

  // return;
  // };
  const loginUser = () => {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: email,
        password: pass,
        email: email,
        pass: pass,
      }),
    };
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: pass,
    });
    
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        const type = data.getIdToken().payload["custom:type"];
        axios
          .post(EnumApi[type], {
            username: email,
            email: email,
          })
          .then((resp) => {
            if (resp) {
              if (resp.status === 403) {
                alert("Usuario o clave incorrecta");
                return;
              }
              let data = { ...resp.data, isLogin: true };
              props.setUser(data);
              localStorage.setItem("user", JSON.stringify(data));
              props.handleCloseModal();
              history.push("/dashboard");
              setIsLoading(false);
            }
          });
      },
      onFailure: (err) => {
        console.error("onFailure: ", err);
      },
      newPasswordRequired: (data) => {
        //console.log("newPasswordRequired: ", data);
      },
    });
    // axios.post(loginClinicaAPI(),{
    //   username: email,
    //   password: pass,
    //   email: email,
    //   pass: pass,
    // })
    // // .then((resp) => resp.data)
    // .then((resp)=>{
    //   if (resp) {
    //     if(resp.status === 403){
    //       alert('Usuario o clave incorrecta');
    //       return;
    //     }
    //     //   const dataN = await getUserNormalizer({ email: email });
    //     let data = { ...resp.data, isLogin: true };
    //     props.setUser(data);
    //     props.setUserAction({ ...data });
    //     localStorage.setItem("user", JSON.stringify(data));
    //     props.handleCloseModal();
    //     history.push("/dashboard");
    //     setIsLoading(false);
    //   }
    // })
  };

  const responseGoogle = async (response) => {
    //if(!response.profileObj) return;
    setIsLoading(true);
    // props.setUserAction({ ...response });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: response.profileObj.email }),
    };
    // fetch(
    //   "https://us-central1-nowcompanyglobal.cloudfunctions.net/getdatauser",
    //   requestOptions
    // )
    //   .then(async (response) => {
    //     const userData = await response.json();

    //     getdatauser({ email: userData.data.email });
    //     setIsLoading(false);
    //   })
    //   .then((data) => console.log(data))
    //   .catch((err) => setIsLoading(false));
  };

  return (
    <div>
      {/* <p>Login de Clinica</p> */}
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          id="standard-basic"
          label="Correo"
          value={email}
          onChange={onChangeEmail}
        />
        <TextField
          id="standard-password-input"
          label="Clave"
          type="password"
          autoComplete="current-password"
          value={pass}
          onChange={onChangePass}
        />
      </form>
      <Button
        variant="contained"
        className={classes.buttonLogin}
        onClick={() => {
          loginUser();
        }}
      >
        Iniciar
      </Button>
      <p style={{ textAlign: "center" }}> - O - </p>
      <GoogleLogin
        clientId="728061136989-eff00ij347vf6r1kp2a486c4ogenk0gh.apps.googleusercontent.com"
        buttonText="Google Account"
        onSuccess={() => responseGoogle}
        onFailure={() => responseGoogle}
        cookiePolicy={"single_host_origin"}
        className={classes.btnGoogle}
      />
      <hr></hr>
    </div>
  );
};

// export default connect(mapStateToProps, mapDispatchToProps)(AuthBasic);
export default AuthBasic;
