import React from 'react'
import { connect } from 'react-redux'

export const LoginEmail = (props) => {
    return (
        <div>
            LoginEmail
        </div>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

 export default connect(mapStateToProps, mapDispatchToProps)(LoginEmail)
