import { getSucursalEditService, getSucursalesService ,getSucursalEliminarService} from "./SucursalService";

export const GET_SUCURSALES = 'GET_SUCURSALES';
export const SET_SUCURSALES = 'SET_SUCURSALES';
export const GET_SUCURSALES_REQUEST = 'GET_SUCURSALES_REQUEST';
export const GET_SUCURSALES_SUCCESS = 'GET_SUCURSALES_SUCCESS';
export const GET_SUCURSALES_FAILURE = 'GET_SUCURSALES_FAILURE';
export const getSucursalesAction = req => ({
    type: GET_SUCURSALES,
    req,
    promise: getSucursalesService(req),
});

export const setSucursalesAction = data => ({
    type: SET_SUCURSALES,
    data
});


export const GET_SUCURSAL_EDIT = 'GET_SUCURSAL_EDIT';
export const SET_SUCURSAL_EDIT = 'SET_SUCURSAL_EDIT';
export const GET_SUCURSAL_EDIT_REQUEST = 'GET_SUCURSAL_EDIT_REQUEST';
export const GET_SUCURSAL_EDIT_SUCCESS = 'GET_SUCURSAL_EDIT_SUCCESS';
export const GET_SUCURSAL_EDIT_FAILURE = 'GET_SUCURSAL_EDIT_FAILURE';
export const getSucursalEditAction = req => ({
    type: GET_SUCURSAL_EDIT,
    req,
    promise: getSucursalEditService(req),
});

export const setSucursalEditAction = data => ({
    type: SET_SUCURSAL_EDIT,
    data
});

export const GET_SUCURSAL_ELIMINAR = 'GET_SUCURSAL_ELIMINAR';
export const SET_SUCURSAL_ELIMINAR = 'SET_SUCURSAL_ELIMINAR';
export const GET_SUCURSAL_ELIMINAR_REQUEST = 'GET_SUCURSAL_ELIMINAR_REQUEST';
export const GET_SUCURSAL_ELIMINAR_SUCCESS = 'GET_SUCURSAL_ELIMINAR_SUCCESS';
export const GET_SUCURSAL_ELIMINAR_FAILURE = 'GET_SUCURSAL_ELIMINAR_FAILURE';
export const getSucursalEliminarAction = req => ({
    type: GET_SUCURSAL_ELIMINAR,
    req,
    promise: getSucursalEliminarService(req),
});

export const setSucursalEliminarAction = data => ({
    type: SET_SUCURSAL_ELIMINAR,
    data
});