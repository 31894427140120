import React from "react";
import "./CardClinica.scss";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { setClinicaSelectedAction } from "Pages/ExploreClinicas/ExploreClinicasAction";
import { getDataProfileClinica } from "Pages/ProfileClinica/ProfileClinicaService";
/**
 * @name CardClinica
 * @param {*} props
 */
const CardClinica = (props) => {
  //console.log(props)
  const {
    name,
    email,
    region,
    commune,
    direction,
    photo_profile,
    photo_portada,
    description,
    name_clinica,
    tag_name,
  } = props.data;
  let history = useHistory();
  const dispatch = useDispatch();
  const handleGoToProfile = async (tag_name) => {
    //props.setClinicaSelectedAction({...props.data})
    // const result = await getDataProfileClinica(tag_name).then((resp)=>{
    //   // //console.log(resp);
    //   return resp.data[0];
    // })
    // dispatch(setClinicaSelectedAction({...result}))
    history.push(`/profile-clinica/${tag_name}?from=card`);
  }
  return (
    <div
      className="card-clinica_container"
      style={{
        backgroundImage: `url(${photo_profile})`,
        backgroundSize: "cover",
      }}
    >
      <div className="card-clinica_container__title">
        <p>{name_clinica} - {tag_name}</p>
      </div>
      <div
        className="card-clinica_container__footer"
        style={{ backgroundColor: "white" }}
      >
        <Button
          fullWidth
          onClick={() => {
            handleGoToProfile(tag_name)
          }}
        >
          Ver Más
        </Button>
      </div>
    </div>
  );
};

CardClinica.propTypes = {};

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = (dispatch)=>({
  setClinicaSelectedAction:(data) => dispatch(setClinicaSelectedAction(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CardClinica);
