import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState } from "react";
import { registerAPI } from "services/endpoint";
import { REGEX_RUT, formatRut, validateRut, cleanFormatRut } from "../../Common/utils";
import "./index.scss";
import UserPool from "Pages/AuthLive/AuthViews/UserPool";
import {
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";


const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    width: "100%",
    maxWidth: "400px",
    //display: "block",
  },
  inputs: {
    display: "block",
    width: "100%",
    maxWidth: "400px",
  },
  buttonRegister: {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    marginTop: "3rem",
  },
  buttonSecondary: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    marginTop: "3rem",
  },
  // textField: {
  //     marginLeft: theme.spacing(1),
  //     marginRight: theme.spacing(1),
  //     width: '100%',
  //   },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const FormCliente = (props) => {
  const [email, setemail] = useState("");
  const [Rut, setRut] = useState("");
  const [pass, setpass] = useState("");
  const [nombre, setnombre] = useState("");
  const [tipo, settipo] = useState("");
  const [apellido, setapellido] = useState("");
  const classes = useStyles();
  const [ShowForm, setShowForm] = useState(true);
  const [Error, setError] = useState({});
  const [PasswordShow, setPasswordShow] = useState({ showPassword: false });
  const [ErrorPass, setErrorPass] = useState(false)
const [Genero, setGenero] = useState(-1);
  const handleChangeEmail = (event) => {
    setemail(event.target.value);
  };
  const handleChangeNombre = (event) => {
    setnombre(event.target.value);
  };
  const handleChangeApellido = (event) => {
    setapellido(event.target.value);
  };
  const handleChangeTipo = (event) => {
    settipo(event.target.value);
  };
  const handleChangePass = (event) => {
    setpass(event.target.value);
  };
  const handleChangeRut = (event) => {
    //validar Rut
    setRut(event.target.value)
  };
  const handleOnBlurRut = () => {
    if (validateRut(Rut)) {
      setRut(formatRut(Rut))
    }
    else {
      return false;
    }
  }
  const handleOnFocus = () => {
    setRut(cleanFormatRut(Rut))
  }
  const handleClickShowPassword = () => {
    setPasswordShow({ showPassword: !PasswordShow.showPassword });
    setpass(pass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const setCognitoUserAttribute = (attributeKey, attributeValue) => {
    let data = {
      Name: attributeKey,
      Value: attributeValue,
    };

    return new CognitoUserAttribute(data);
  };
  const signupCognitoUser = (values) => {
    //console.log('values : ',values)
    let { email, password, name, last_name, name_clinica, rut, type } = values;
    let attributeList = [];

    // const userPool = UserPool()

    attributeList.push(setCognitoUserAttribute("custom:name", name));
    attributeList.push(setCognitoUserAttribute("name", name));
    attributeList.push(setCognitoUserAttribute("profile", ''));
    attributeList.push(setCognitoUserAttribute("custom:last_name", last_name));
    attributeList.push(setCognitoUserAttribute("family_name", last_name));
    // attributeList.push(setCognitoUserAttribute("custom:name_clinica", name_clinica));
    attributeList.push(setCognitoUserAttribute("custom:rut", rut));
     attributeList.push(setCognitoUserAttribute("custom:type", '0'));

    return new Promise((resolve, reject) =>
      UserPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      })
    );
  };
  const handleSubmit = async () => {
    const payload = {
      email: email,
      name: nombre,
      last_name: apellido,
      tag_name: nombre.trim().toLowerCase()+apellido.trim().toLowerCase(),
      password: pass,
      display_name: nombre + " " + apellido,
      disabled: true,
      email_verified: false,
      rut: Rut,
      gender: "",
      type: 0,
      application: "mipetnow",
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    const data = {
      name: nombre,
      last_name: apellido,
      rut: Rut,
      email: email,
      password: pass,
      type:0
    };
    await signupCognitoUser(data)
      .then((res) => {
        setErrorPass(false)
        console.log(
          "Your registration is successful. Instructions have been sent to your email."
        );
        alert('Tu cuenta ha sido creada con exito')
        // setShowForm(false);
        //      props.handleShowForm(false)
      })
      .catch((err) => {
        setErrorPass(true)
        //console.log(err.code, err.message);
      });
    
    
  };

  return (
    <div className="registerEmail--inputs" >
      <div className="title">
        <h3>Registrate aqui</h3>
        <p>
          Estos datos son necesarios, tanto para agendar horas, como para poder
          hacer valer tus derechos.
        </p>
      </div>
      {/* <form onSubmit={handleSubmit}> */}
      {/* <TextField
        id="standard-helperText"
        label="Rut"
        value={Rut}
        onChange={handleChangeRut}
        onBlur={handleOnBlurRut}
        onFocus={handleOnFocus}
        className={classes.inputs}
        fullWidth
        variant="outlined"
        variant="outlined"
      /> */}
      <TextField
        id="standard-helperText"
        label="Email"
        value={email}
        onChange={handleChangeEmail}
        className={classes.inputs}
        fullWidth
        variant="outlined"
      />
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={PasswordShow.showPassword ? "text" : "password"}
          value={pass}
          onChange={handleChangePass}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {PasswordShow.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>
      <TextField
        id="standard-helperText"
        label="Nombre"
        value={nombre}
        onChange={handleChangeNombre}
        className={classes.inputs}
        fullWidth
        variant="outlined"
      />
      <TextField
        id="standard-helperText"
        label="Apellido"
        value={apellido}
        onChange={handleChangeApellido}
        className={classes.inputs}
        fullWidth
        variant="outlined"
      />
       <p style={{ color: "red" }}>{Error.message}</p>
      <Button
        variant="contained"
        color="primary"
        className="button-primary"
        onClick={handleSubmit}
        fullWidth
      >
        Crear Cuenta
      </Button>
      <p style={{ color: "red" }}>{props.Error.message}</p>
      <Button
        variant="outlined"
        className="button-secondary"
        onClick={() => {
          props.setTypeUser(-1);
          props.handleShowForm(-1)
        }}
        fullWidth
      >
        Soy otro tipo de usuario
      </Button>
    </div>
  );
};
