import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import './PrivateRoute.scss';
import { Redirect, Route } from 'react-router';
import { getUserAction, setUserAction } from '../../User/userAction';
import { connect, useSelector } from 'react-redux';
import { user } from 'Common/normalizers';
/**
 * @name PrivateRoute
 * @param {*} props
 */
 const mapStateToProps = (state) => {

    return state;
  };

  const mapDispatchToProps = (dispatch) => ({
    setUserAction: (data) => dispatch(setUserAction(data)),
    getUserAction: () => dispatch(getUserAction),
  });
const PrivateRoute = ({children,...rest}) => {
  const User = useSelector(state => state.user);
  // //console.log(User.isLogin)
  // //console.log('/empleados/crear')
    return (
        <Route
        {...rest}
        render={({ location }) =>
          User.isLogin ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />
    )
}

PrivateRoute.propTypes = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
