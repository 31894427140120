import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
// import ExploreClinicasPage from "./ExploreClinicasPage";
import Geocode from "react-geocode";
import * as regionsandcommunes from "../../Common/regionsandcomunes.json";
import axios from "axios";
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import { getClinicasGeoAPI } from "services/endpoint";
import ExploreClinicasPage from "./ExploreClinicasPage";
import useTitle from "Hooks/useTitle";

Geocode.setApiKey("AIzaSyAmCuGdeXjLuB1tANy3BjVfxBeJlNtNF6s");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();
const ExploreClinicasContainer = (props) => {
  //React Router
  const history = useHistory();

  //React Redux
  const dispatch = useDispatch();

  //React States

  //React Events
  
  const handleClick = () => {};

  const handleClickNextPage = () => {};

  useTitle({title:'Clinicas'})
  return (
    <div>
      <ExploreClinicasPage
        handleClick={handleClick}
        handleClickNextPage={handleClickNextPage}
        
      />
    </div>
  );
};

export default ExploreClinicasContainer;