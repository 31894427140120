import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory, withRouter } from "react-router-dom";
import { useParams } from "react-router-dom";
const ModificarSucursal = (props) => {
  let history = useHistory();

  const { id } = useParams();

  const [Direction, setDirection] = useState("");
  const [NombreSucursal, setNombreSucursal] = useState("");
  const [Validado, setValidado] = useState(false);

  const [UrlDir, setUrlDir] = useState("");
  const refCol = useRef(null);

  useEffect(() => {
    //console.log("get data to update", props.sucursal)
    setDirection(props.sucursal.direccion);
    setNombreSucursal(props.sucursal.nombre);
  }, [])
  const handleChangeDirection = (e) => {
    setDirection(e.target.value);
    setUrlDir(
      `https://www.google.com/maps/embed/v1/place?key=AIzaSyAmCuGdeXjLuB1tANy3BjVfxBeJlNtNF6s&q=${Direction}`
    );
  };
  const handleChangeNombreSucursal = (e) => {
    setNombreSucursal(e.target.value);
  };
  const handleChangeValidado = (e) => {
    setValidado(!Validado);
  };
  const dynamicMap = () => {
    if (Direction === "") return;
    //console.log(refCol);
    const w = refCol.current.offsetWidth;
    const h = refCol.current.offsetHeight;
    return (
      <iframe
        width={w}
        height="200"
        style={{ border: 0 }}
        loading="lazy"
        allowFullScreen
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAmCuGdeXjLuB1tANy3BjVfxBeJlNtNF6s&q=${Direction}`}
      ></iframe>
    );
  };

  const handleClickCrear = async () => {
    const url = "https://y4w1yetyxb.execute-api.us-west-2.amazonaws.com/dev";
    //modificar sucursal elegida
    const newData = {
      nombre: NombreSucursal,
      direccion: Direction,
      uuid:id
    }
    //modificar en arreglo
    var newSucursales = props.user.sucursales;
    let pos = props.user.sucursales.findIndex(x => x.uuid == id);
    newSucursales[pos] = newData;
    const payload = {
      email:props.user.email,
      sucursales:newSucursales
    };
    await fetch(url, {
      method: "POST",

      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (data) => {
        history.push("/sucursales");
      });
  };
  const handleClickBack = () => {
    history.goBack();
  }
  return (
    <div className="crear-sucursal">
      <div className="crear-sucursal_title">
        <h1>Modifica aqui tú sucursal</h1>
        <p></p>
      </div>
      <div className="crear-sucursal_content">
        <Paper elevation={3} className="paper">
          {/* <h2>Datos</h2> */}
          <div className="form">
            <div className="col-1" ref={refCol}>
              <TextField
                className="input-form"
                id="outlined-basic"
                label="Nombre Empresa"
                variant="outlined"
                value={NombreSucursal}
                fullWidth
                onChange={(e) => handleChangeNombreSucursal(e)}
              />
              <TextField
                className="input-form"
                id="outlined-basic"
                label="Dirección"
                variant="outlined"
                value={Direction}
                fullWidth
                onChange={(e) => handleChangeDirection(e)}
              />
              <Button
                onClick={handleChangeValidado}
                className="button"
                variant="contained"
                color="primary"
                fullWidth
                disabled={Direction === ""}
              >
                Mostrar mapa
              </Button>
              {Validado ? <div>{dynamicMap()}</div> : null}
            </div>
            <div className="col-2"></div>
          </div>
          <div className="options">
            <Button className="button" variant="outlined" color="primary" onClick={()=>{handleClickBack()}}>
              Cencelar
            </Button>
            <Button
              className="button"
              variant="contained"
              color="primary"
              onClick={handleClickCrear}
            >
              Guardar
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default ModificarSucursal;
