import React, {
  Component,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import "./index.scss";
import { Loader } from "../../../Components/Loader/Loader";
import ProviderCard from "../../../Components/ProviderCard/ProviderCard";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import { connect, useSelector } from "react-redux";
import { getUserAction, setUserAction } from "../../../User/userAction";
import { Button, Checkbox, TextField } from "@material-ui/core";
import { convertBase64 } from "../../../Common/utils";
import defaultImagePortada from "./pexels-francesco-ungaro-96938.jpg";
import axios from "axios";
import { uploadImage } from "Common/services";
import { updateClinicaAPI } from "services/endpoint";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Modal from "react-modal";
import Cropper from "react-easy-crop";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import useTitle from "Hooks/useTitle";
import { dispatch } from "d3-dispatch";
import QueryBuilderOutlinedIcon from "@material-ui/icons/QueryBuilderOutlined";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  TimePicker,
} from "@material-ui/pickers";

const ProfilePublic = (props) => {
  const User = useSelector((state) => state.user);
  // const [Photo_portada, setPhoto_portada] = useState(User.photo_portada);
  const [Base64, setBase64] = useState(() => {
    if (User.photo_portada) return User.photo_portada;
    return "";
  });

  const [modalidad, setModalidad] = useState("");

  const handleChangeRadio = (event) => {
    setModalidad(event.target.value);
  };

  const refFile = useRef(null);
  const refCol = useRef(null);
  const [file, setFile] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [Direction, setDirection] = useState("");
  const [ImgX, setImgX] = useState("center");
  const [ImgY, setImgY] = useState("center");
  const [ImgYEnd, setImgYEnd] = useState("");
  const [ImgYStart, setImgYStart] = useState("");
  const [DescriptionEditable, setDescriptionEditable] = useState(false);
  const [Description, setDescription] = useState(User.description);
  const [TempDescription, setTempDescription] = useState(User.description);
  const [RecentChange, setRecentChange] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [Apertura, setApertura] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [Cierre, setCierre] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [HorarioColacion, setHorarioColacion] = useState(false);
  const [InicioColacion, setInicioColacion] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [TerminoColacion, setTerminoColacion] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [HoraAtencionI, setHoraAtencionI] = useState({});
  const [HoraAtencionF, setHoraAtencionF] = useState({});
  const [HoraColacionI, setHoraColacionI] = useState({});
  const [HoraColacionF, setHoraColacionF] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [ProfileConfig, setProfileConfig] = useState(User.profile_config || {});

  useEffect(() => {
    if (!HorarioColacion) {
      setHoraColacionI({});
      setHoraColacionF({});
    }
  }, [HorarioColacion]);

  useEffect(() => {
    console.log("ProfileConfig : ", ProfileConfig);
    setModalidad(
      ProfileConfig && ProfileConfig.h_atencion && ProfileConfig.h_atencion.mod
        ? ProfileConfig.h_atencion.mod
        : ""
    );
  }, [ProfileConfig]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [CroppedArea, setCroppedArea] = useState({
    height: 0,
    width: 0,
    x: (User.photo_profile_config && User.photo_profile_config.x) || 0,
    y: (User.photo_profile_config && User.photo_profile_config.y) || 0,
  });
  const [crop, setCrop] = useState({
    x: (User.photo_profile_config && User.photo_profile_config.x) || 0,
    y: (User.photo_profile_config && User.photo_profile_config.y) || 0,
  });
  useEffect(() => {
    setCrop({
      x: (User.photo_profile_config && User.photo_profile_config.x) || 0,
      y: (User.photo_profile_config && User.photo_profile_config.y) || 0,
    });
    //console.log("User.photo_profile_config.y : ", User.photo_profile_config.y);
  }, []);
  const [zoom, setZoom] = useState(1);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    //console.log(croppedArea, croppedAreaPixels);
    // if(file.height <= croppedAreaPixels)
    setCroppedArea(croppedArea);
  }, []);

  const handleChangeToEdit = () => {
    setDescriptionEditable(true);
    setRecentChange(false);
  };
  const handleChangeDescription = async (value) => {
    //console.log(value);
    await setDescription(value);
    setDescriptionEditable(false);
    setRecentChange(true);
    setTimeout(() => {
      setRecentChange(false);
      saveData();
    }, 2000);
  };
  const saveData = async () => {
    console.log("TempDescription", TempDescription);
    console.log("HoraAtencionI ", HoraAtencionI);
    // if (
    //   HoraAtencionI &&
    //   HoraAtencionI.open === undefined &&
    //   HoraAtencionI.close === undefined
    // )
    //   console.log("Error");
    // return;
    setisLoading(true);
    const dataToUpdate = {
      email: User.email,
      name: User.name,
      last_name: User.last_name,
      direction: User.direction,
      description: TempDescription,
      commune: User.commune,
      region: User.region,
      uuid: User._id,
      name_clinica: User.name_clinica,
      number_phone: User.number_phone,
      profile_config: ProfileConfig,
    };
    //console.log("dataToUpdate : ", dataToUpdate);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...dataToUpdate,
      }),
    };

    if (User.type === "2") {
      await axios
        .post(updateClinicaAPI(), { ...dataToUpdate })
        .then((resp) => resp.data)
        .then((resp) => {
          //console.log(resp);
          props.setUserAction(resp.user);
          setisLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };
  const savePhoto = async () => {
    await axios
      .post("https://79t7vbwdw7.execute-api.us-west-2.amazonaws.com/dev", {
        email: User.email,
        x: CroppedArea.x,
        y: CroppedArea.y,
      })
      .then((r) => {
        console.log(r);
        props.setUserAction({
          photo_profile_config: {
            x: CroppedArea.x,
            y: CroppedArea.y,
          },
        });
        closeModal();
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const handleCancelChangeDescription = () => {
    setTempDescription(Description);
    setDescriptionEditable(false);
  };

  const handlechangePhoto = async (e) => {
    //console.log("handle click input change pohoto");
    e.preventDefault();
    var file = e.target.files[0];
    setFile(file);
    let base64 = file === undefined ? "" : await convertBase64(file);
    //base64 = base64.replace("data:image/jpeg;base64,", "");
    // //console.log(base64);
    setBase64(base64);
    setisLoading(true);
    // setPhoto_portada(file);
    //console.log(file);
    await uploadImage({
      _id: User._id,
      image: file,
      folder: "pic_portada",
      description: "test",
      attr: "photo_portada",
    });
    //await postImage({ image: file, description: "test" });
    setisLoading(false);
  };

  // const saveData = async (e) => {
  //   // const base64 = selectedFile;
  //   updatePhotoProfessional({
  //     email: props.user.email,
  //     key: "photo_professional",
  //     // value: base64,
  //     typeKey: "S",
  //   })
  //     .then((response) => response.json())
  //     .then(async (data) => {
  //       props.setUserAction({ isLogin: true, ...User });
  //       localStorage.setItem("user", JSON.stringify(User));
  //       //setSelectedFile(base64);
  //       setisLoading(false);
  //     })
  //     .catch((err) => {
  //       setisLoading(false);
  //       alert("Hubo un error", err);
  //     });
  // };

  function openModal() {
    let img = new Image();
    img.src = Base64;
    //console.log(img);
    setFile(img);
    //console.log(img.width);
    //console.log(img.height);
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }
  function closeModal() {
    setIsOpen(false);
  }

  const dynamicMap = () => {
    //console.log("dynamic map ");
    if (User.direction === "") return;
    ////console.log(refCol);
    // const w = refCol.current.offsetWidth >= 900 ? 500 : refCol.current.offsetWidth;
    const w = refCol.current
      ? refCol.current.offsetWidth >= 900
        ? 500
        : refCol.current.offsetWidth
      : "300";
    const h = refCol.current ? refCol.current.offsetHeight : "200";
    return (
      <iframe
        width={w}
        height="200"
        style={{ border: 0 }}
        loading="lazy"
        allowFullScreen
        title="mapa"
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAmCuGdeXjLuB1tANy3BjVfxBeJlNtNF6s&q=${User.direction}`}
      ></iframe>
    );
  };
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2014-08-18T21:11:54")
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSetTieneColacion = () => {
    setHorarioColacion(!HorarioColacion);

    console.log(HorarioColacion);
  };
  const handleSetInicioAtencion = (e) => {
    setApertura(e);
    const f = new Date(e);
    const h = f.getHours();
    const m = f.getMinutes();
    const payload = {
      h: h,
      m: m,
      text: `${h}:${m}`,
      full: e.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      date: e,
    };
    setHoraAtencionI(payload);
  };
  const handleSetTerminoAtencion = (e) => {
    setCierre(e);
    const f = new Date(e);
    const h = f.getHours();
    const m = f.getMinutes();
    const payload = {
      h: h,
      m: m,
      text: `${h}:${m}`,
      full: e.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      date: e,
    };
    setHoraAtencionF(payload);
  };

  const handleSetInicioCol = (e) => {
    setInicioColacion(e);
    const f = new Date(e);
    const h = f.getHours();
    const m = f.getMinutes();
    const payload = {
      h: h,
      m: m,
      text: `${h}:${m}`,
      full: e.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      date: e,
    };
    setHoraColacionI(payload);
  };
  const handleSetTerminoCol = (e) => {
    setTerminoColacion(e);
    const f = new Date(e);
    const h = f.getHours();
    const m = f.getMinutes();
    const payload = {
      h: h,
      m: m,
      text: `${h}:${m}`,
      full: e.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      date: e,
    };
    setHoraColacionF(payload);
  };
  const handleClickButtonSave = () => {
    const payload = {
      h_atencion: {
        mod: modalidad,
        open: HoraAtencionI,
        close: HoraAtencionF,
      },
      h_colacion: {
        tiene_colacion: HorarioColacion,
        inicio_colacion: HoraColacionI,
        termino_colacion: HoraColacionF,
      },
    };
    console.log(payload);
    setProfileConfig(payload);
    saveData();
  };
  return (
    <div className="profile-comercial_public-page">
      {isLoading ? <Loader></Loader> : null}

      <div className="title-description">
        <h2>
          Asi se veria tu pagina al publico, puedes editar su contenido, para
          que se acomode aun más a ti y tu empresa.
        </h2>
      </div>
      <div className="profile_data">
        <div className="profile_data__title">
          <h1>{User.name_clinica}</h1>
          <h2>{User.tag_name}</h2>
        </div>
        <div
          className="profile_data__photo-portada"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <div
            className="img"
            alt="Photo by Francesco Ungaro from Pexels"
            style={{
              backgroundImage: `url(${
                Base64 === undefined || Base64 === ""
                  ? defaultImagePortada
                  : Base64
              })`,
              backgroundSize: "cover",
              backgroundPositionY: `${CroppedArea.y}%`,
            }}
            onClick={() => setAnchorEl(true)}
          ></div>
          <input
            type="file"
            hidden
            ref={refFile}
            onChange={(e) => handlechangePhoto(e)}
          />
          <span className="tooltiptext">Editar foto perfil</span>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Typography
              style={{ padding: "1rem", cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(null);

                refFile.current.click();
              }}
            >
              Subir nueva imagen
            </Typography>
            <Typography
              style={{ padding: "1rem", cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(null);
                openModal();
              }}
            >
              Editar Imagen
            </Typography>
            <Typography
              style={{ padding: "1rem", cursor: "pointer" }}
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(null);
              }}
            >
              Cancelar
            </Typography>
          </Popover>
        </div>
        <div className="profile_data__sections">
          <ul>
            <li>
              <a href="#about">Sobre nosotros</a>
            </li>
            <li>
              <a href="#calendar">Nuestros horarios</a>
            </li>
            <li>
              <a href="#direction">Ubicacion</a>
            </li>
            <li>
              <a href="#gallery">Galeria de fotos</a>
            </li>
            <li>
              <a href="#ratings">Calificaciones</a>
            </li>
          </ul>
        </div>
        <section className="profile_data__description" id="about">
          {DescriptionEditable ? (
            <div className="container-editable">
              <TextField
                id="standard-basic"
                label="Descripción"
                className="input"
                multiline
                rows={4}
                fullWidth
                value={TempDescription}
                onChange={(e) => {
                  setTempDescription(e.target.value);
                }}
              />
              <div className="options">
                <Button
                  onClick={() => {
                    handleCancelChangeDescription();
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    handleChangeDescription(TempDescription);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Guardar
                </Button>
              </div>
            </div>
          ) : Description ? (
            <p
              onClick={() => {
                handleChangeToEdit();
              }}
              className={`${RecentChange ? "changed" : "no-changed"}`}
            >
              {Description}
            </p>
          ) : (
            <p
              onClick={() => {
                handleChangeToEdit();
              }}
              className={`${RecentChange ? "changed" : "no-changed"}`}
              style={{ background: "#ffe6cc" }}
            >
              Recuerda ingresar una descripcion breve sobre tu negocio, esto
              ayudara a que el publico tenga uan mayor confianza.
              <strong>Presiona aqui para editar!</strong>
            </p>
          )}
          {/* <CSSTransition
            in={!DescriptionEditable}
            timeout={300}
            classNames="alert"
            unmountOnExit
            onEnter={() => setDescriptionEditable(false)}
            onExited={() => setDescriptionEditable(true)}
          > */}
          {/* <p
              onClick={() =>{ handleChangeToEdit()}}
              style={{ backgroundColor: RecentChange ? "green" : "" }}
            >
              {Description}
            </p> */}
          {/* </CSSTransition> */}
        </section>
        <section className="profile_data__form-calendar" id="calendar">
          <h3>Revisa nuestros horarios</h3>
          <div className="profile_data__form-calendar-edit">
            <div className="mode">
              <span>
                <strong>Selecciona una opción</strong>
              </span>
              <div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Modalidad</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={modalidad}
                    onChange={handleChangeRadio}
                  >
                    <FormControlLabel
                      value="lunvie"
                      control={<Radio />}
                      label="Lun/Vie"
                    />
                    <FormControlLabel
                      value="lunsab"
                      control={<Radio />}
                      label="LunSab"
                    />
                    <FormControlLabel
                      value="lundom"
                      control={<Radio />}
                      label="Lun/Dom"
                    />
                    {/* <FormControlLabel
                      value="custom"
                      control={<Radio />}
                      label="Personalizar"
                    /> */}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="days" disabled={modalidad === ""}>
              <span>
                <strong>Horarios de</strong>
              </span>
              {modalidad === "lunvie" ? (
                <div>
                  <div>Lunes a Viernes</div>
                  <div>
                    <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {/* <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          label="Desde"
                          value={Apertura}
                          onChange={(e)=>{ handleSetInicioAtencion(e)}}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        
                        /> */}
                        <TimePicker
                          label="Desde"
                          value={Apertura}
                          onChange={(e) => {
                            handleSetInicioAtencion(e);
                          }}
                        >
                          <QueryBuilderOutlinedIcon></QueryBuilderOutlinedIcon>
                        </TimePicker>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {/* <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          label="Hasta"
                          value={Cierre}
                          onChange={(e) => {
                            handleSetTerminoAtencion(e);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        /> */}
						<TimePicker
                          label="Hasta"
						  id="time-picker"
                          value={Cierre}
                          onChange={(e) => {
                            handleSetTerminoAtencion(e);
                          }}
                        >
                          <QueryBuilderOutlinedIcon></QueryBuilderOutlinedIcon>
                        </TimePicker>
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
              ) : null}
              {modalidad === "lunsab" ? (
                <div>
                  <div>Lunes a Sabado</div>{" "}
                  <div>
                    <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {/* <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          label="Desde"
                          value={Apertura}
                          onChange={(e) => {
                            handleSetInicioAtencion(e);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        /> */}
						 <TimePicker
                          label="Desde"
                          value={Apertura}
                          onChange={(e) => {
                            handleSetInicioAtencion(e);
                          }}
                        >
                          <QueryBuilderOutlinedIcon></QueryBuilderOutlinedIcon>
                        </TimePicker>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {/* <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          label="Hasta"
                          value={Cierre}
                          onChange={(e) => {
                            handleSetTerminoAtencion(e);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        /> */}
						<TimePicker
                          label="Hasta"
						  id="time-picker"
                          value={Cierre}
                          onChange={(e) => {
                            handleSetTerminoAtencion(e);
                          }}
                        >
                          <QueryBuilderOutlinedIcon></QueryBuilderOutlinedIcon>
                        </TimePicker>
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
              ) : null}
              {modalidad === "lundom" ? (
                <div>
                  <div>Lunes a Domingo</div>
                  <div>
                    <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {/* <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          label="Desde"
                          value={Apertura}
                          onChange={(e) => {
                            handleSetInicioAtencion(e);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        /> */}
						 <TimePicker
                          label="Desde"
                          value={Apertura}
                          onChange={(e) => {
                            handleSetInicioAtencion(e);
                          }}
                        >
                          <QueryBuilderOutlinedIcon></QueryBuilderOutlinedIcon>
                        </TimePicker>
                      </MuiPickersUtilsProvider>
                    </div>
                    <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {/* <KeyboardTimePicker
                          margin="normal"
                          id="time-picker"
                          label="Hasta"
                          value={Cierre}
                          onChange={(e) => {
                            handleSetTerminoAtencion(e);
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        /> */}
						<TimePicker
                          label="Hasta"
						  id="time-picker"
                          value={Cierre}
                          onChange={(e) => {
                            handleSetTerminoAtencion(e);
                          }}
                        >
                          <QueryBuilderOutlinedIcon></QueryBuilderOutlinedIcon>
                        </TimePicker>
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                </div>
              ) : null}
              {modalidad === "custom" ? <div>Personalizar</div> : null}
            </div>
            <div className="confirm">
              <div>
                <span>
                  <strong>¿Horario de colación? </strong>
                  <p>
                    Falta dejar hora fin colacion con 1 min + que inicio
                    colacion por defecto, hay que ajustar mecanicas de
                    formularios.
                  </p>
                  <Checkbox
                    checked={HorarioColacion}
                    disabled={modalidad === ""}
                    onChange={() => {
                      handleSetTieneColacion();
                    }}
                  ></Checkbox>
                </span>
              </div>
              {modalidad === "" ? null : (
                <>
                  {!HorarioColacion ? null : (
                    <div>
                      <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Desde"
                            value={InicioColacion}
                            onChange={(e) => {
                              handleSetInicioCol(e);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardTimePicker
                            margin="normal"
                            id="time-picker"
                            label="Hasta"
                            value={TerminoColacion}
                            onChange={(e) => handleSetTerminoCol(e)}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  )}

                  <div>
                    {/* <p>
                  Si decides repetir, y necesitas cambiarlo, solo vuelve a
                  editarlo
                </p> */}
                    <Button
                      variant="contained"
                      disabled={modalidad === ""}
                      onClick={() => {
                        handleClickButtonSave();
                      }}
                    >
                      Guardar
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        <section className="profile_data__location" ref={refCol} id="direction">
          <div className="content">
            Estamos ubicados en{" "}
            <b>
              <p>
                {User.direction}, {User.commune}
              </p>
            </b>
          </div>
          <div className="map">
            <div>{dynamicMap()}</div>
            {/* <iframe
              width="600"
              height="450"
              style={{ border: 0 }}
              loading="lazy"
              allowFullScreen
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAmCuGdeXjLuB1tANy3BjVfxBeJlNtNF6s&q=${User.direction}`}
            ></iframe> */}
          </div>
        </section>
        <section className="profile_data__gallery" id="gallery">
          <div className="title">
            <h2>Revisa nuestras fotos</h2>
          </div>
          <div className="gallery">{}</div>
        </section>
        <section className="profile_data__recomendaciones" id="ratings">
          Calificaciones
        </section>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        // style={customStyles}
        className="modal-photo-profile"
        contentLabel="Informacion"
        ariaHideApp={false}
      >
        <div className="modal_content_editor-img">
          <div
            style={{
              width: "100%",
              height: "100vh",
              border: "1px solid black",
              position: "relative",
            }}
          >
            <div>
              {file && file.src ? (
                <Cropper
                  image={file.src}
                  crop={crop}
                  zoom={zoom}
                  aspect={4 / 1}
                  // cropShape="round"
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              ) : null}
            </div>
            <p>{crop.y}</p>
            <div className="section-buttons">
              <Button
                variant="contained"
                color="primary"
                onClick={() => savePhoto()}
                className="button-accept"
              >
                Confirmar
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  closeModal();
                }}
                className="button-cancel"
              >
                Cancelar
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  setUserAction: (data) => dispatch(setUserAction(data)),
  getUserAction: () => dispatch(getUserAction),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePublic);
