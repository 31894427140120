import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import "./AuthLive.scss";
import { Button, Modal, TextField } from "@material-ui/core";
import { Loader } from "../../Components/Loader/Loader";
import GoogleLogin from "react-google-login";
import { fade, makeStyles } from "@material-ui/core/styles";
import {
  getProfesionalAction,
  getUserAction,
  setProfesionalAction,
  setUserAction,
} from "../../User/userAction";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getUserNormalizer } from "../../services/user";
import AuthBasic from "./AuthViews/AuthBasic";
import AuthClinica from "./AuthViews/AuthClinica";


const mapStateToProps = (state) => {
  return state;
};

// const mapDispatchToProps = dispatch => bindActionCreators(ducks, dispatch);
const mapDispatchToProps = (dispatch) => ({
  setUserAction: (data) => dispatch(setUserAction(data)),
  getUserAction: () => dispatch(getUserAction),
  getProfesionalAction: () => dispatch(getProfesionalAction),
  setProfesionalAction: (data) => dispatch(setProfesionalAction(data)),
});

/**
 * @name AuthLive
 * @param {*} props
 */
const AuthLive = (props) => {
  let history = useHistory();

  const [isLoading, setisLoading] = useState(false);
  const [email, setemail] = useState("");
  const [pass, setpass] = useState("");
  const [TypeUserSelected, setTypeUserSelected] = useState(-1);
  const onChangeEmail = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setemail(event.target.value);
  };

  const onChangePass = (event) => {
    setpass(event.target.value);
  };
  const getdatauser = async (data) => {
    setisLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: data.email, uid: data.uid || null }),
    };
    await fetch(
      "https://us-central1-nowcompanyglobal.cloudfunctions.net/getdatauser",
      requestOptions
    )
      .then((response) => response.json())
      .then(async (data) => {
        data = { ...data, isLogin: true };
        props.setUser(data);
        localStorage.setItem("user", JSON.stringify(data));
        await setUserAction({ data });
        props.handleCloseModal();
        setisLoading(false);
        history.push("/dashboard");
      });

    return;
  };
  const loginUser = () => {
    setisLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: email,
        password: pass,
        email: email,
        pass: pass,
      }),
    };
    fetch(
      // "https://us-central1-nowcompanyglobal.cloudfunctions.net/loginEmail",
      // "https://7bgbn89p7k.execute-api.us-west-2.amazonaws.com/dev",
      "http://35.162.226.98:3000/users/login",
      requestOptions
    )
      .then((response) => response.json())
      .then(async (data) => {
        if (
          data.code === "auth/wrong-password" ||
          data.userByEmail === "auth/wrong-password"
        ) {
          alert("Clave incorrecta, vuelve a intentar");
          setisLoading(false);
          return;
        }
        if (
          data.code === "auth/too-many-requests" ||
          data.userByEmail === "auth/too-many-requests"
        ) {
          alert(
            "Has intentado iniciar sesion muchas veces, vuelve en 5 minutos :D"
          );
          setisLoading(false);
          return;
        }
        if (data) {
          // const dataUser = JSON.parse(data.body);
          const dataN = await getUserNormalizer({ email: email });
          data = { ...dataN, isLogin: true };
          props.setUser(data);
          props.setUserAction({ ...data });
          localStorage.setItem("user", JSON.stringify(data));
          props.handleCloseModal();
          history.push("/dashboard");
          setisLoading(false);

          props.handleMenuClose();
        }
      })
      .catch((err) => {
        //console.log("Err : ", err);
      });
  };

  const responseGoogle = async (response) => {
    //if(!response.profileObj) return;
    //console.log(response);
    setisLoading(true);
    // props.setUserAction({ ...response });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: response.profileObj.email }),
    };
    fetch(
      "https://us-central1-nowcompanyglobal.cloudfunctions.net/getdatauser",
      requestOptions
    )
      .then(async (response) => {
        const userData = await response.json();

        getdatauser({ email: userData.data.email });
        setisLoading(false);
      })
      .then((data) => console.log(data))
      .catch((err) => setisLoading(false));
  };

  const goToRegisterPage = () => {
    history.push("/register");
    props.handleCloseModal();
  };

  const onCloseModal = () => {
    setTypeUserSelected(-1);
    props.handleCloseModal();
  };
  return (
    <div className="authLive_container">
      <Modal
        open={props.open}
        onClose={onCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="modal-login"
      >
        <div className="modal-content">
          {isLoading ? <Loader></Loader> : null}
          {TypeUserSelected === -1 ? (
            <div  className="content">
              <h1 className="title" id="simple-modal-title">
                Inicia tu sesion
              </h1>
              <div className="container-types">
              <div>
              {/* <AuthClinica
                    setUser={props.setUser}
                    handleCloseModal={props.handleCloseModal}
                  ></AuthClinica> */}
                <AuthBasic
                   setUser={props.setUser}
                  handleCloseModal={props.handleCloseModal}
                ></AuthBasic>
              </div>
                {/* <Button
                  className="button-type-user"
                  variant="contained"
                  onClick={() => setTypeUserSelected(0)}
                >
                  Dueña(o) de mascotas
                </Button>
                <Button
                  className="button-type-user"
                  variant="contained"
                  onClick={() => setTypeUserSelected(1)}
                >
                  Profesional
                </Button>
                <Button
                  className="button-type-user"
                  variant="contained"
                  onClick={() => setTypeUserSelected(2)}
                >
                  Clinica
                </Button>
                <Button
                  className="button-type-user"
                  variant="contained"
                  onClick={() => setTypeUserSelected(3)}
                >
                  Tienda
                </Button>
                <Button
                  className="button-type-user"
                  variant="contained"
                  onClick={() => setTypeUserSelected(3)}
                >
                  Estetica
                </Button> */}
              </div>
              <div className="container_options">
                <Button
                  variant="contained"
                  color="primary"
                  className="btn-register"
                  onClick={goToRegisterPage}
                >
                  Registrate
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  className="btn-cancelar"
                  onClick={props.handleCloseModal}
                  fullWidth
                >
                  Cancelar
                </Button>
              </div>
            </div>
          ) : (
            <>
              {/* {TypeUserSelected === 0 ? (
                <div>
                  <AuthUser
                    setUser={props.setUser}
                    handleCloseModal={props.handleCloseModal}
                  ></AuthUser>
                </div>
              ) : null}
              {TypeUserSelected === 1 ? (
                <div>
                  <AuthProfesional
                    setUser={props.setUser}
                    handleCloseModal={props.handleCloseModal}
                  ></AuthProfesional>
                </div>
              ) : null}
              {TypeUserSelected === 2 ? (
                <div>
                  <AuthClinica
                    setUser={props.setUser}
                    handleCloseModal={props.handleCloseModal}
                  ></AuthClinica>
                </div>
              ) : null}
              {TypeUserSelected === 3 ? (
                <div>
                  <AuthTienda
                    setUser={props.setUser}
                    handleCloseModal={props.handleCloseModal}
                  ></AuthTienda>
                </div>
              ) : null} */}
              
              <div>
                <Button
                  className="button-volver"
                  variant="contained"
                  onClick={() => setTypeUserSelected(-1)}
                >
                  Volver
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

AuthLive.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(AuthLive);
