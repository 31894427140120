import {
  SET_PROFESSIONALS_PROFILES,
  GET_PROFESSIONALS_PROFILES
} from './PageInitAction';

const initialProfessionalsState = {
  
};
export const professionalsProfilesReducer = (state = initialProfessionalsState, action) => {
  switch (action.type) {
    case SET_PROFESSIONALS_PROFILES:
      return Object.assign({}, state, action.data);
    case GET_PROFESSIONALS_PROFILES:
      return Object.assign({}, state, action.response);
    default:
      return state;
  }
};


