import React, { Component, useState, useRef, useEffect } from "react";
import "./index.scss";
import { Loader } from "../../../Components/Loader/Loader";
import ProviderCard from "../../../Components/ProviderCard/ProviderCard";

import { connect } from "react-redux";
import { getUserAction, setUserAction } from "../../../User/userAction";
import { Button, TextField } from "@material-ui/core";
import { convertBase64 } from "../../../Common/utils";
import { getUserData, updateDataUser } from "../../../User/userService";

const ProfilePublic = (props) => {
  const [User, setUser] = useState(() => {
    return { ...props.user };
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  let dataCardUser = {
    displayName: User.displayName,
    description: User.description,
    photo_professional: selectedFile || props.user.photo_professional,
  };
  //console.log(dataCardUser);
  const setStatex = (value, k) => {
    setUser({ ...User, [k]: value });
  };

  const handlechangePhoto = async (e) => {
    var file = e.target.files[0];
    let base64 = await convertBase64(file);
    //console.log("base64 : : : ",base64);
    setisLoading(true);
    // function toDataURL(url, callback) {
    //   var xhr = new XMLHttpRequest();
    //   xhr.onload = function () {
    //     var reader = new FileReader();
    //     reader.onloadend = function () {
    //       callback(reader.result);
    //     };
    //     reader.readAsDataURL(xhr.response);
    //   };
    //   xhr.open("GET", url);
    //   xhr.responseType = "blob";
    //   xhr.send();
    // }
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     image: base64,
    //     username: User.displayName.toLowerCase().trim(),
    //   }),
    // };

    const user = {
      ...props.user,
      photo_professional: base64,
      username: User.displayName.toLowerCase().trim(),
    };
    //console.log('user : : ', user.photo_professional);
    updateDataUser(user)
      .then((response) => response.json())
      .then(async (data) => {
        //console.log(data);
        props.setUserAction({ isLogin: true, ...user });
        let dataUser = { isLogin: true, ...user };
        localStorage.setItem("user", JSON.stringify(user));
        setSelectedFile(base64);
        // const res = await getUserData({ email: props.user.email })
        //   .then((response) => response.json())
        //   .then((resp) => {
        //     const result = JSON.parse(resp.body);
        //     //console.log(result.data);
        //     props.setUserAction({ ...res.data, isLogin: true });
        //     return result.data;
        //   });
        
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        alert("Hubo un error", err);
      });
  };
  return (
    <div className="profile_public-page">
      {isLoading ? <Loader></Loader> : null}
      <div className="profile_photo">
        <h3>Vista previa </h3>
        <div className="how_show" style={{ width: "300px" }}>
          <ProviderCard prov={dataCardUser} isPrivate={true}></ProviderCard>
        </div>
      </div>
      <div className="profile_data">
        <TextField
          id="standard-multiline-static"
          label="Nombre Profesional"
          multiline
          fullWidth
          value={User.displayName || ""}
          onChange={(e) => {
            setStatex(e.target.value, "displayName");
          }}
        />
        <TextField
          id="standard-multiline-static"
          label="Descripción"
          multiline
          rows={4}
          fullWidth
          value={User.description || ""}
          onChange={(e) => {
            setStatex(e.target.value, "description");
          }}
        />
        <Button
          variant="contained"
          component="label"
          fullWidth
          className="btn-upload"
        >
          Subir foto de portada
          <input type="file" hidden onChange={(e) => handlechangePhoto(e)} />
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  setUserAction: (data) => dispatch(setUserAction(data)),
  getUserAction: () => dispatch(getUserAction),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePublic);
