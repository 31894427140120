import Modal from "react-modal";

import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { DashboardCliente } from "../DashboardCliente/DashboardCliente";
import { useHistory } from "react-router-dom";
import { DashboardProfessional } from "../DashboardProfessional/DashboardProfessional";
import DashboardClinica from "../DashboardClinica/DashboardClinica";
import "./Dashboard.scss";
import bannerMipetnow from "../../assets/mipetnow-banner.png";
export const Dashboard = (props) => {
  const User = useSelector((state) => state.user);
  const history = useHistory();

  const [modalIsOpen, setIsOpen] = useState(false);
  const [ProviderSelected, setProviderSelected] = useState({});
  const [ShowModal, setShowModal] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeModal() {
    setIsOpen(false);
  }
  const setShowModal_ = (provider) => {
    // setShowModal(!ShowModal);

    if (ShowModal) {
      closeModal();
    } else {
      setProviderSelected({ ...provider });
      openModal();
    }
    ////console.log("State 1",props.state.showmodal);
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "60%",
      "@media only screen and (max-width: 991px)": {
        width: "80%",
      },
    },
  };

  return (
    <div className="dashboard">
      <div>
        <div className="dashboard_fundaciones_section">
          <div className="banner">
            <img src={bannerMipetnow} alt="banner-mipetnow"></img>
          </div>
          {/* <div className="container">
            <h2>Fundaciones</h2>
            <div className="content">
              <div className="card">A</div>
              <div className="card">B</div>
              <div className="card">C</div>
            </div>
          </div> */}
        </div>
        {User && User.type === "0" ? (
          <DashboardCliente {...props}></DashboardCliente>
        ) : null}
        {User && User.type === "1" ? (
          <DashboardProfessional {...props}></DashboardProfessional>
        ) : null}
        {User && User.type === "2" ? (
          <DashboardClinica {...props}></DashboardClinica>
        ) : null}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Informacion"
        ariaHideApp={false}
      ></Modal>
    </div>
  );
};
export default Dashboard;
