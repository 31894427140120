import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import './Loader.scss';
const useStyles = makeStyles((theme) => ({
  root: {
     
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));
export const Loader = (props) => {
  const classes = useStyles();
  return (
    <div className='loader--container'>
      <CircularProgress />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
