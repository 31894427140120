export const updateDataUser = async (payload) => {
    //console.log("in services")
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
     ...payload
    }),
  };
  const data = await fetch(
    "https://ew76qej03e.execute-api.us-west-2.amazonaws.com/dev",
    requestOptions
  );
  return data;
};

export const getProfessionalsProfiles = async () =>{
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  const data =  await fetch(
    "https://o6p2brh7ml.execute-api.us-west-2.amazonaws.com/dev",
    requestOptions
  );
  return data;
}

export const updatePhotoProfessional = async (payload) => {
  //console.log("in services")
const requestOptions = {
  method: "POST",
  headers: { "Content-Type": "application/json" },
  body: JSON.stringify({
   ...payload
  }),
};
const data = await fetch(
  "https://zi5mry1s70.execute-api.us-west-2.amazonaws.com/dev",
  requestOptions
);
return data;
};

export const getUserData = async (payload) =>{
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      ...payload
     }),
  };
  const data =  await fetch(
    "http://localhost:3000/users/login",
    requestOptions
  );
  return data;
}