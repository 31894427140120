import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlogPage from "./BlogPage";
// import {
//   useHistory,
//   useLocation,
//   useParams,
//   withRouter,
// } from "react-router-dom";
/**
 * @author John Doe
 * @returns 
 */
const BlogContainer = () => {
  //React Router
  // const history = useHistory();

  //React Redux
  // const dispatch = useDispatch();

  //React States

  //React Events

  const handleClick = () => {};

  const handleClickNextPage = () => {};

  return (
    <div>
      <BlogPage
        handleClick={handleClick}
        handleClickNextPage={handleClickNextPage}
      />
    </div>
  );
};

export default BlogContainer;
