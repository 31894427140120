export const REGEX_RUT = /^[1-9]{1}\d{0,8}([\d|k|K]){0,1}$/;
export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const toBase64 = (arr) => {
  //arr = new Uint8Array(arr) if it's an ArrayBuffer
  return btoa(arr.reduce((data, byte) => data + String.fromCharCode(byte), ""));
};

export const ecnryptHash = (args) => {
  const joined = args.elements.join();
  const hash = joined + (+new Date()).toString(36);
};

export const decnryptHash = (args) => {};

export const validateRut = (value) => {
  if (value.length < 8) return false;
  return validateRutFunction(value) && REGEX_RUT.test(value) ? true : false;
};

const validateRutFunction = (value) => {
  if (value.length > 0) {
    let rut = value.slice(0, -1);
    let dv = value.slice(-1).toUpperCase();
    if (validateDigitoVerificador(rut) + "" === dv) {
      return true;
    }
  }
  return false;
};

const validateDigitoVerificador = (value) => {
  let M = 0;
  let S = 1;
  for (; value; value = Math.floor(value / 10)) {
    S = (S + (value % 10) * (9 - (M++ % 6))) % 11;
  }
  return S ? S - 1 : "K";
};

export const formatRut = (value) => {
  let result = value;
  if (value.length > 1) {
    result = `${value.slice(-4, -1)}-${value.substr(value.length - 1)}`;
    for (let i = 4; i < value.length; i += 3) {
      result = `${value.slice(-3 - i, -i)}.${result}`;
    }
  }
  return result;
};

export const cleanFormatRut = (value) =>
  value.length === 11 || value.length === 12
    ? value.replace(/^0+|[^0-9kK]+/g, "")
    : value;

