import {  getExploreClinicasService } from "./ExploreClinicasService";
export const GET_EXPLORECLINICAS = 'GET_EXPLORECLINICAS';
export const SET_EXPLORECLINICAS = 'SET_EXPLORECLINICAS';
export const GET_EXPLORECLINICAS_REQUEST = 'GET_EXPLORECLINICAS_REQUEST';
export const GET_EXPLORECLINICAS_SUCCESS = 'GET_EXPLORECLINICAS_SUCCESS';
export const GET_EXPLORECLINICAS_FAILURE = 'GET_EXPLORECLINICAS_FAILURE';
export const getExploreClinicasAction = req => ({
    type: GET_EXPLORECLINICAS,
    req,
    promise: getExploreClinicasService(req),
});

export const setExploreClinicasAction = data => ({
    type: SET_EXPLORECLINICAS,
    data
});

export const GET_COMMUNE = 'GET_COMMUNE';
export const SET_COMMUNE = 'SET_COMMUNE';
export const GET_COMMUNE_REQUEST = 'GET_COMMUNE_REQUEST';
export const GET_COMMUNE_SUCCESS = 'GET_COMMUNE_SUCCESS';
export const GET_COMMUNE_FAILURE = 'GET_COMMUNE_FAILURE';
export const getCommuneAction = req => ({
    type: GET_COMMUNE,
    req,
});

export const setCommuneAction = data => ({
    type: SET_COMMUNE,
    data
});
export const GET_CLINICA_SELECTED = 'GET_CLINICA_SELECTED';
export const SET_CLINICA_SELECTED = 'SET_CLINICA_SELECTED';
export const GET_CLINICA_SELECTED_REQUEST = 'GET_CLINICA_SELECTED_REQUEST';
export const GET_CLINICA_SELECTED_SUCCESS = 'GET_CLINICA_SELECTED_SUCCESS';
export const GET_CLINICA_SELECTED_FAILURE = 'GET_CLINICA_SELECTED_FAILURE';
export const getClinicaSelectedAction = req => ({
    type: GET_CLINICA_SELECTED,
    req,
    // promise: getClinicaSelected(req),
});

export const setClinicaSelectedAction = data => ({
    type: SET_CLINICA_SELECTED,
    data
});