import React, { useState } from "react";
import { connect } from "react-redux";

// import { Calendar } from "react-big-calendar";
import * as BigCalendar from "react-big-calendar";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "./CalendarCenter.scss";
import { Calendar } from "../../Components/Calendar/Calendar";
const localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 200,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));
export const CalendarCenter = (props) => {
  const [User, setUser] = useState(() => {
    if (props.user.isLogin) {
      return props.user;
    } else {
      return props.user;
    }
  });
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [Events, setEvents] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [Listado, setListado] = useState([]);
  const [FechaDisponible, setFechaDisponible] = useState();

  const addEvents = (ev) => {
    let events = [...Events, ev];
    setEvents([...events]);
  };
  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  const handleAdd = (value) => {
    // let listado = [...Listado];
    // listado.push({
    //   fecha: value.toLocaleDateString(),
    //   hora: value.toLocaleTimeString(),
    // });
    // setListado(listado);
    const start = moment().toDate(value);
    const end = moment().toDate(start.setMinutes(value.getMinutes() + 30));
    const title = "hora";
    setEvents([
      ...Events,
      {
        start,
        end,
        title,
      },
    ]);
    //setFechaDisponible(new Date());
  };
  const body = (
    <div className="modal-content">
      <h2>Crea horas disponibles</h2>
      <p>
        Aqui puedes organizar tus horarios, ya sea para hoy, para mañana, la
        semana que viene, recuerda respetar estos horarios, una buena atencion,
        atrae más clientes
      </p>
      <div className="date-container">
        <p>Selecciona una fecha y hora</p>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker
            value={selectedDate}
            onChange={(value) => {
              handleAdd(value);
            }}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className="date-list">
        <List>
          {Listado.map((elem, i) => {
            return (
              <ListItem key={i} className="itemList">
                <ListItemText primary={elem.fecha + " / " + elem.hora} />
              </ListItem>
            );
          })}
        </List>
      </div>
      <div className="date-options">
        <Button variant="contained" color="primary">
          Cancelar
        </Button>
        <Button variant="contained" color="primary">
          Confirmar
        </Button>
      </div>
    </div>
  );
  /**
   * Evento  que almacena en Events los nuevos eventos
   * Aqui debemos tambien guardar en redux
   * @param {*} param0
   */
  const handleSelect = ({ start, end }) => {
    const title = window.prompt("New Event name");
    if (title)
      setEvents([
        ...Events,
        {
          start,
          end,
          title,
        },
      ]);
  };
  return (
    <div>
      {User.type === "2" ? (
        <div className="calendar-center-container">
          <h1>Administra tú agenda</h1>
          {/* <div>
            <h4>Configura tus horarios para hoy (horas disponibles)</h4>
            <Button variant="contained" color="primary" onClick={handleOpen}>
              {" "}
              Agregar hora disponible
            </Button>
          </div> */}
          <div>
            {/* Revisa tus proximas agendas. */}
            {/* <Calendar
              selectable
              localizer={localizer}
              events={Events}
              //   defaultView={Views.WEEK}
              scrollToTime={new Date(1970, 1, 1, 6)}
              defaultDate={new Date(2015, 3, 12)}
              onSelectEvent={(event) => {
                alert(event.title);
                addEvents(event);
              }}
              defaultView="day"
              onSelectSlot={handleSelect}
            ></Calendar> */}
            <Calendar data={User}></Calendar>
          </div>
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className="modal-calendar-add"
          >
            {body}
          </Modal>
        </div>
      ) : (
        <div className="calendar-center-container">
          Revisa tu agenda y horas tomadas
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarCenter);
