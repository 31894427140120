import React, { Component, useState, useRef, useEffect } from "react";
import "./index.scss";
import { connect } from "react-redux";
import { getUserAction, setUserAction } from "../../../User/userAction";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import * as regionsandcommunes from "../../../Common/regionsandcomunes.json";
import { Loader } from "../../../Components/Loader/Loader";
import * as defaultProfile from "./defaultProfile.png";
import { convertBase64 } from "../../../Common/utils";
import { updateDataUser } from "../../../User/userService";
import Cookies from 'universal-cookie';



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  setUserAction: (data) => dispatch(setUserAction(data)),
  getUserAction: () => dispatch(getUserAction),
});
export const ProfilePrivate = (props) => {


const cookies = new Cookies();
cookies.set('mipetnow.com', 'Mipetnow', {  secure: true,
  sameSite: 'none'});
  const classes = useStyles();
  const [User, setUser] = useState(() => {
    //console.log(props.user)
    if(props.user.isLogin){
      return props.user;
    }else{
    return props.user;
    }
  });
  const [isLoading, setisLoading] = useState(false);
  const [Region, setRegion] = useState("");
  const [Regiones, setRegiones] = useState(() => {
    let regiones = regionsandcommunes.default.regions;
    const listregiones = regiones.map((region) => {
      let objRegion = { id: region.number + "", value: region.name };
      return objRegion;
    });
    return listregiones;
  });
  const [photo, setphoto] = useState();
  const inputFile = useRef(null);
  useEffect(() => {
    let region = regionsandcommunes.default.regions.filter((region) => {
      return region.number === User.region;
    });
    setRegion(region);
  }, [User.commune]);
  const handleChangeRegion = (event) => {
    setUser({ ...User, region: event.target.value });
    let region = regionsandcommunes.default.regions.filter((region) => {
      return region.number === event.target.value;
    });
    setRegion(region);
  };
  const handleChangeComuna = (event) => {
    setUser({ ...User, commune: event.target.value });
  };

  const handlechangePhoto = async (e) => {
    var file = e.target.files[0];
    let base64 = await convertBase64(file);
    //base64 = base64.replace("data:image/jpeg;base64,", "");
    setisLoading(true);
    function toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        image: base64,
        username: User.displayName.toLowerCase().trim(),
      }),
    };

    const user = { ...User, photoProfessional: base64,username: User.displayName.toLowerCase().trim() };
    updateDataUser(user)
      .then((response) => response.json())
      .then(async (data) => {
        //console.log(data);
        props.setUserAction({ isLogin: true,  ...user  });
        let dataUser = { isLogin: true,  ...user  };
        localStorage.setItem("user", JSON.stringify(dataUser));
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        alert("Hubo un error", err);
      });
  };

  const saveData = () => {
    setisLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...User,
      }),
    };
    fetch(
      "https://ew76qej03e.execute-api.us-west-2.amazonaws.com/dev",
      requestOptions
    )
      .then((response) => response.json())
      .then(async (data) => {
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        alert("Hubo un error", err);
      });
    props.setUserAction({ isLogin: true, data: { ...User } });
    let data = { isLogin: true, data: { ...User } };
    localStorage.setItem("user", JSON.stringify(data));
  };

  const setStatex = (event, k) => {
    setUser({ ...User, [k]: event.target.value });
  };
  return (
    <div className="profile-page">
      {isLoading ? <Loader></Loader> : null}
      {/* <div className="profile-page--photo-portada">
        <figure>
          <img></img>
          <a>Subir</a>
        </figure>
      </div> */}
      <div className="profile-page--container">
        <div className="profile--col1">
          <div className="profile_container">
            <div className="profile--img--container">
              <img
                className="profile--img"
                style={{
                  backgroundImage: `url(${
                    User.photoProfile === undefined || User.photoProfile === ""
                      ? "https://i.ibb.co/bzj5jvH/default-Profile.png"
                      : User.photoProfile
                  })`,
                  backgroundSize: "cover",
                }}
              ></img>
            </div>
            <div className="btn-container">
              <Button
                variant="contained"
                component="label"
                className="btn-upload"
              >
                Subir
                <input
                  type="file"
                  hidden
                  onChange={(e) => handlechangePhoto(e)}
                />
              </Button>
            </div>
          </div>
          <div className="profile_container">
            <h3>{User.name + " " + User.lastName}</h3>
            <h4>{User.username}</h4>
            {User.type === "0" ? <p>Cliente</p> : null}
            {User.type === "1" ? <p>Profesional</p> : null}
            {User.type === "2" ? <p>Proveedor</p> : null}
          </div>
        </div>
        <div className="profile--col2">
          <div className="container">
            <div className="title">
              <h3>Edita tus datos</h3>
            </div>
            <Button
              variant="contained"
              className="button--save"
              color="primary"
              onClick={() => {
                saveData();
              }}
            >
              Guardar datos
            </Button>
          </div>
          <div>
            <TextField
              id="standard-helperText"
              label="Nombre"
              value={User.name}
              onChange={(e) => {
                setStatex(e, "name");
              }}
            />
            <TextField
              id="standard-helperText"
              label="Apellidos"
              value={User.lastName}
              onChange={(e) => {
                setStatex(e, "lastName");
              }}
            />
          </div>
          <div>
            {User.type !== "0" ? (
              <TextField
                id="standard-multiline-static"
                label="Descripción"
                multiline
                rows={4}
                fullWidth
                value={User.description || ""}
                onChange={(e) => {
                  setStatex(e, "description");
                }}
              />
            ) : null}
          </div>
          <div>
            <TextField
              id="standard-helperText"
              label="Dirección"
              helperText="Donde atiendes"
              value={User.direction || ""}
              onChange={(e) => {
                setStatex(e, "direction");
              }}
              fullWidth
            />
          </div>
          <div style={{ display: "flex" }}>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Region</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={User.region || ""}
                onChange={handleChangeRegion}
              >
                {Regiones.map((region) => {
                  return (
                    <MenuItem key={region.id} value={region.id + ""}>
                      {region.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">Comuna</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={User.commune || ""}
                onChange={handleChangeComuna}
              >
                {Region[0] &&
                  Region[0]["communes"].map((commune, index) => {
                    return (
                      <MenuItem key={index} value={index + ""}>
                        {commune.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              id="standard-helperText"
              label="Email"
              type="email"
              helperText="Email de contacto"
              value={User.email || ""}
              onChange={(e) => {
                setStatex(e, "email");
              }}
              fullWidth
            />
          </div>
        </div>
      </div>
    </div>
  );
};


export default connect(mapStateToProps, mapDispatchToProps)(ProfilePrivate);
