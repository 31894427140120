import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export const DayCalendar = () => {
  return (
    <div>
       <FullCalendar
        plugins={[dayGridPlugin,timeGridPlugin]}
        headerToolbar={{
          left: "today",
          center: "title",
          right: "timeGridDay",
        }}
        nextDayThreshold='08:00:00'
        initialView="timeGridDay"
      />
    </div>
  )
}
