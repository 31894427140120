import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import "./CalendarCenterProfessional.scss";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";
import * as BigCalendar from "react-big-calendar";
import { Calendar as Cal, Views as Views } from "react-big-calendar";
import moment from "moment";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import rootReducer from "./reducer";
import DemoApp from "./DemoApp";
import "./main.css";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { DayCalendar } from "./DayCalendar";
/**
 * @name CalendarCenterProfessional
 * @param {*} props
 */

let store = createStore(rootReducer, applyMiddleware(thunk));
const localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const CalendarCenterProfessional = (props) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [Events, setEvents] = useState([]);
  const classes = useStyles();
  const [dateSelected, setdateSelected] = useState("");
  const handleDateChange = (date) => {
    //console.log(date);
    setSelectedDate(date);
  };
  const handleSelect = ({ start, end }) => {
    const title = window.prompt("New Event name");
    if (title)
      setEvents([
        ...Events,
        {
          start,
          end,
          title,
        },
      ]);
  };
  return (
    <div className="CalendarCenterProfessional_container">
      <div className="calendar-container">
        <div className="calendar-year-options">
          <form className={classes.container} noValidate></form>

          <div className="example">
            <Provider store={store}>
              <DemoApp
                dateSelected={dateSelected}
                setdateSelected={(data) => handleDateChange(data)}
              />
            </Provider>
            <div style={{ display: "flex" }}>
              <h1>{selectedDate.toDateString()}</h1>
              <form className={classes.container} noValidate>
                <TextField
                  id="time"
                  label="Hora de inicio"
                  type="time"
                  defaultValue="08:30"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </form>
              <form className={classes.container} noValidate>
                <TextField
                  id="time"
                  label="Hora de termino"
                  type="time"
                  defaultValue="18:30"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </form>
            </div>
           
            <DayCalendar></DayCalendar>
            {/* <div className="listProfessionals">
            <h1>Profesionales</h1>
            <ul>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          ,{" "} */}
            {/* <Cal
          selectable
          localizer={localizer}
          events={Events}
        //   defaultView={Views.WEEK}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date(2015, 3, 12)}
          onSelectEvent={(event) => {
              //console.log(event);
            alert(event.title);
          }}
          defaultView='day'
          views='day'
          onSelectSlot={handleSelect}
        /> */}
          </div>
          {/* <div className="horas">
          <table>
            <tbody>
              <tr>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td>10</td>
                <td>11</td>
                <td>12</td>
              </tr>
              <tr>
                <td>13</td>
                <td>14</td>
                <td>15</td>
                <td>16</td>
                <td>17</td>
                <td>18</td>
                <td>19</td>
                <td>20</td>
                <td>21</td>
                <td>22</td>
                <td>23</td>
                <td>00</td>
              </tr>
            </tbody>
          </table>
        </div> */}
        </div>
      </div>
    </div>
  );
};

CalendarCenterProfessional.propTypes = {};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarCenterProfessional);
