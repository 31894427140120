import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import './FicheroClinico.scss';
/**
 * @name FicheroClinico
 * @param {*} props 
 */
const FicheroClinico = (props) => {
    return (
        <div className="FicheroClinico_container"> 
            Hi i am a FicheroClinico
        </div>
    )
}

FicheroClinico.propTypes = {

}

export default FicheroClinico
