import React, { Component, useState, useRef, useEffect } from "react";
import "./index.scss";
import { Loader } from "../../../Components/Loader/Loader";
import ProviderCard from "../../../Components/ProviderCard/ProviderCard";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { getUserAction, setUserAction } from "../../../User/userAction";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { convertBase64 } from "../../../Common/utils";
import { updateDataUser } from "../../../User/userService";
import ButtonPet from "../../../Components/ButtonPet/ButtonPet";
import { TextFields } from "@material-ui/icons";
import ButtonPetAdd from "../../../Components/ButtonPetAdd/ButtonPetAdd";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    minWidth: 200,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Pet = (props) => {
  const classes = useStyles();

  const [User, setUser] = useState(() => {
    if(props.user.isLogin){
      return props.user;
    }else{
    return props.user;
    }
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [age, setAge] = useState("");
  const [Raza, setRaza] = useState(null);

  let dataCardUser = {
    displayName: User.displayName,
    description: User.description,
    photoProfessional: selectedFile || User.photoProfessional,
  };
  const setStatex = (value, k) => {
    setUser({ ...User, [k]: value });
  };

  const handlechangePhoto = async (e) => {
    var file = e.target.files[0];
    let base64 = await convertBase64(file);
    //base64 = base64.replace("data:image/jpeg;base64,", "");
    setisLoading(true);
    function toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        image: base64,
        username: User.displayName.toLowerCase().trim(),
      }),
    };

    const user = { ...User, photoProfessional: base64 ,username:User.displayName.toLowerCase().trim()};
    updateDataUser(user)
      .then((response) => response.json())
      .then(async (data) => {
        //console.log(data);
        props.setUserAction({ isLogin: true,  ...user  });
        let dataUser = { isLogin: true,  ...user  };
        localStorage.setItem("user", JSON.stringify(dataUser));
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        alert("Hubo un error", err);
      });

    // fetch(
    //   "https://us-central1-nowcompanyglobal.cloudfunctions.net/uploadProfileImage",
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then(async (data) => {
    //     setStatex(base64, "photoProfessional");
    //     //console.log(base64);
    //     // toDataURL(data.urlPhotoProfessiona,(resp)=>{
    //     //   //console.log("DATA BASE 64", resp);
    //     // })
    //     setSelectedFile(data.urlPhotoProfessional);
    //     const user = { ...User, photoProfessional: data.base64};
    //     updateDataUser(user )
    //       .then((response) => response.json())
    //       .then(async (data) => {
    //         props.setUserAction({ isLogin: true,  ...user  });
    //         let dataUser = { isLogin: true,  ...user  };
    //         localStorage.setItem("user", JSON.stringify(dataUser));
    //         setisLoading(false);
    //       })
    //       .catch((err) => {
    //         setisLoading(false);
    //         alert("Hubo un error", err);
    //       });
    //   })
    //   .catch((err) => {
    //     setisLoading(false);
    //     alert("Error al subir imagen");
    //   });
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div className="pet-page">
      {isLoading ? "true":"false"}
      {isLoading ? <Loader></Loader> : null}
      <div className="pet_list">
        <ButtonPetAdd></ButtonPetAdd>
      </div>
      <div className="pet_info">
        <div>
          <FormControl className={classes.formControl}>
            <TextField id="outlined-basic" label="Nombre" variant="outlined" />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              id="date"
              label="Fecha Nacimiento"
              type="date"
              defaultValue="2017-05-24"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-label">Tipo</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-label">Raza</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={Raza}
              onChange={handleChange}
            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <img className="pet_preview"></img>
          <div className="pet_btn_upload">
            {" "}
            <Button
              variant="contained"
              component="label"
              className="btn-upload"
            >
              Subir foto
              <input
                type="file"
                hidden
                onChange={(e) => handlechangePhoto(e)}
              />
            </Button>
          </div>
        </div>
        <div className="pet_actions">
          <Button className="btn-delete">Eliminar</Button>
          <Button className="btn-success">Guardar</Button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  setUserAction: (data) => dispatch(setUserAction(data)),
  getUserAction: () => dispatch(getUserAction),
});
export default connect(mapStateToProps, mapDispatchToProps)(Pet);
