import { useEffect, useRef } from "react";

export default function useTitle ( {title,type}){

    const prevTitle = useRef(document.title);
    useEffect(() => {
       const previousTitle = prevTitle.current;
        const newTitle =  `${title} ${type === '' ? '' : ' | MipetNow' }`;
       document.title =newTitle;
       return () => document.title = previousTitle
    }, [title])
}