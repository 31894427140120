import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setUserAction } from 'User/userAction';
// import { Helmet, HelmetProvider } from 'react-helmet-async';
import './CloseSession.scss';

const CloseSessionPage = ({ ...props }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    localStorage.removeItem("user");
    dispatch(setUserAction(null));
    dispatch(setUserAction({ isLogin: false }));
    
  }, [])
  return (
    <>
     {/* <HelmetProvider> */}
       {/* <Helmet> */}
        <title>CloseSession</title>
        <meta
          name="keywords"
          content="Content"
        />
        <meta
          name="description"
          content="Description"
        />
      {/* </Helmet> */}
      <div className="closesession">
        
        <div className="closesession__content">
          
        </div>
      </div>
    {/* </HelmetProvider> */}
    </>
  );
};

export default CloseSessionPage;
