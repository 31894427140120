import { user } from "../Common/normalizers";
import { getUserData } from "../User/userService";
import { loginAPI, loginClinicaAPI, loginTokenAPI } from "./endpoint";

export const getUserNormalizer = (data) => {
  //console.log("get data ", data);
  return getUserData(data)
    .then((response) => response.json())
    .then(async (data) => {
      var { body } = data;
      const bodyR = JSON.parse(body);
      //console.log("get user normalizer");
      var resp;
      if (bodyR.data.type === "2") {
        const sucursalesNormalizer = bodyR.data.sucursales.map((sucursal) => {
          return {
            nombre: sucursal.M.nombre.S,
            uuid: sucursal.M.uuid.S,
            direccion: sucursal.M.direccion.S,
            email: sucursal.M.email.S,
          };
        });
        const empleadosNormalizer = bodyR.data.empleados.map((empleado) => {
          return {
            uuid: empleado.M.uuid.S,
            name: empleado.M.name.S,
            lastName: empleado.M.lastName.S,
            email: empleado.M.email.S,
            email_sucursal: empleado.M.email_sucursal.S,
          };
        });
        resp = {
          ...bodyR.data,
          sucursales: sucursalesNormalizer,
          empleados: empleadosNormalizer,
        };
      } else {
        resp = { ...bodyR.data };
      }
      return resp;
    });
};

export const getLogin = async (data) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      token: data.token,
      email: data.email,
    }),
  };
  var api = "";
  // switch (data.type) {
  //   case 0:
  //     api = loginTokenAPI()
  //     break;
  //   case 1:
  //     api = loginAPI();
  //     break;
  //   case 2:
  //     api = loginClinicaAPI();
  //     break;
  //   case 3:
  //     api = loginAPI();
  //     break;
  //   default:
  //     break;
  // }
  // return fetch(loginTokenAPI(data.type), requestOptions)
  //   .then((response) => response.json())
  //   .then(async (data) => {
  //     if (
  //       data.code === "auth/wrong-password" ||
  //       data.userByEmail === "auth/wrong-password"
  //     ) {
  //       alert("Clave incorrecta, vuelve a intentar");
  //       return;
  //     }
  //     if (
  //       data.code === "auth/too-many-requests" ||
  //       data.userByEmail === "auth/too-many-requests"
  //     ) {
  //       alert(
  //         "Has intentado iniciar sesion muchas veces, vuelve en 5 minutos :D"
  //       );
  //       return;
  //     }
  //     if (data) {
  //       data = { ...data, isLogin: true };
  //       localStorage.setItem("user", JSON.stringify(data));

  //       return data;
  //     }
  //     return null;
  //   });
};
