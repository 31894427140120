import axios from "axios";

export const getDataProfileClinica = async (tag) => {
  // const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: {
  //         tag:data.tag
  //     },
  // };
  return await axios
    .post("https://yfu5vkrnm1.execute-api.us-west-2.amazonaws.com/dev", {
      tag: tag,
    })
    .then((resp) => {
        //console.log("llamada : ",resp);
        return resp;
    });
  // return fetch(
  //     "https://yfu5vkrnm1.execute-api.us-west-2.amazonaws.com/dev",
  //     requestOptions
  //   ).then((response) => response.json())
  //   .then(async (data)=> {
  //       //console.log(data)
  //       return data;
  //   })
};
