import React, { useEffect, useRef, useState } from "react";
import "./ProfileClinica.scss";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
//import Button from '../Components/Button/Button'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { dispatch } from "d3-dispatch";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Modal from "react-modal";
import { makeStyles } from "@material-ui/core/styles";
//import Button from 'lib-storybook-nowcompany';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import { green } from "@material-ui/core/colors";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { createSelector } from "reselect";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import WatchLaterOutlinedIcon from "@material-ui/icons/WatchLaterOutlined";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import PhotoSizeSelectActualOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActualOutlined";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import Skeleton from "react-loading-skeleton";
import { getDataProfileClinica } from "./ProfileClinicaService";
import { setClinicaSelectedAction } from "Pages/ExploreClinicas/ExploreClinicasAction";
import useTitle from "Hooks/useTitle";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    "@media only screen and (max-width: 991px)": {
      width: "80%",
    },
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 250,
    width: 250,
    position: "relative",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export const ProfileClinica = (props) => {
  const dispatch = useDispatch();
  const [ClinicaSelected, setClinicaSelected] = useState({});
  const [LinkDirecto, setLinkDirecto] = useState(false);
  const [PositionImg, setPositionImg] = useState({});
  useEffect(() => {
    async function getData(tag) {
      // setSum(Sum);
      const result = await getDataProfileClinica(tag).then((resp) => {
        return resp.data[0];
      });
      await dispatch(setClinicaSelectedAction({ ...result }));
      setClinicaSelected({ ...result });
      //return result;
    }
    if (ClinicaSelected.name_clinica === undefined) {
      let tag = window.location.pathname.split("/");
      getData(tag[2]);
      const url_string = new URL(window.location.href);
      var esLinkDirecto = url_string.searchParams.get("from");

      setLinkDirecto(esLinkDirecto === null ? true : false);
      // setLinkDirecto()
    } else {
      //Mostrart pagina de error
    }
  }, []);
  const User = useSelector((state) => state.user);
  const history = useHistory();
  const classes = useStyles();
  const [CollapseAgenda, setCollapseAgenda] = useState(false);
  const [OpenActions, setOpenActions] = useState(true);
  const [Direction, setDirection] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const refCol = useRef(null);
  const [ShowLoader, setShowLoader] = useState(true)

  useTitle({ title: ClinicaSelected.name_clinica, type: "clinica" });
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeModal() {
    setIsOpen(false);
  }
  const setShowModal_ = (provider) => {
    // setShowModal(!ShowModal);

    if (ShowModal) {
      closeModal();
    } else {
      //setProviderSelected({ ...provider });
      openModal();
    }
    //console.log("State 1",props.state.showmodal);
  };

  const reportNetworkError = () => {
    alert("This action could not be completed");
  };
  const handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;
    //let title = prompt("Ingresa nombre de cliente");
    //handleOpenModal();
    // calendarApi.unselect(); // clear date selection

    // if (title) {
    //   calendarApi.addEvent(
    //     {
    //       // will render immediately. will call handleEventAdd
    //       title,
    //       start: selectInfo.startStr,
    //       end: selectInfo.endStr,
    //       allDay: selectInfo.allDay,

    //     },
    //     true
    //   ); // temporary=true, will get overwritten when reducer gives new events
    // }
  };
  const handleEventClick = (clickInfo) => {
    // if (
    //   alert(
    //     `Are you sure you want to delete the event '${clickInfo.event.title}'`
    //   )
    // ) {
    //   clickInfo.event.remove(); // will render immediately. will call handleEventRemove
    // }
    console.log(clickInfo);
  };

  // handlers that initiate reads/writes via the 'action' props
  // ------------------------------------------------------------------------------------------

  const handleDates = (rangeInfo) => {
    // props
    // .requestEvents(rangeInfo.startStr, rangeInfo.endStr)
    // .catch(reportNetworkError);
  };

  const handleEventAdd = (addInfo) => {
    console.log(addInfo);
    // props.createEvent(addInfo.event.toPlainObject()).catch(() => {
    //   reportNetworkError();
    //   addInfo.revert();
    // });
  };

  const handleEventChange = (changeInfo) => {
    // props.updateEvent(changeInfo.event.toPlainObject()).catch(() => {
    //   reportNetworkError();
    //   changeInfo.revert();
    // });
  };

  const handleEventRemove = (removeInfo) => {
    // props.deleteEvent(removeInfo.event.id).catch(() => {
    //   reportNetworkError();
    //   removeInfo.revert();
    // });
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };
  const openModalMessage = () => {
    setIsOpen(true);
    console.log(modalIsOpen);
  };
  useEffect(() => {
    if (ClinicaSelected.direction === undefined) {
      return;
    }
    console.log(ClinicaSelected.direction);
    var s =
      ClinicaSelected.direction.split(" ").join("+") +
      "+" +
      ClinicaSelected.commune.split(" ").join("+");
    console.log("new path map", s);
    console.log(
      `https://www.google.com/maps/embed/v1/place?key=AIzaSyAmCuGdeXjLuB1tANy3BjVfxBeJlNtNF6s&q=${s}`
    );
    //return s;
    setDirection(s);

    setPositionImg({ ...ClinicaSelected.photo_profile_config });
  }, [ClinicaSelected]);
  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 500);
    
  }, [])
  const backPage = () => {
    console.log("go back");

    LinkDirecto ? history.push("/explore-clinicas") : history.goBack();
  };

  const dynamicMap = () => {
    console.log("dynamic map ");
    if (Direction === "") return;
    const w =
      refCol.current.offsetWidth >= 900 ? 500 : refCol.current.offsetWidth;
    const h = refCol.current.offsetHeight;
    return (
      <iframe
        width={w}
        height="200"
        style={{ border: 0 }}
        loading="lazy"
        allowFullScreen
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAmCuGdeXjLuB1tANy3BjVfxBeJlNtNF6s&q=${Direction}`}
      ></iframe>
    );
  };
  return (
    <>

   {ShowLoader ? (
     <div>
        <Skeleton
      style={{ margin: "0px .5rem" }}
      count={1}
      width={'100%'}
      height={300}
    />
     <Skeleton
      style={{ margin: "0px .5rem" }}
      count={1}
      width={'100%'}
      height={300}
    />
     </div>
   ) : (
        <div className="profile-clinica-container">
          <div className="options">
            {LinkDirecto ? (
              <div></div>
            ) : (
              <Button onClick={backPage}>Volver</Button>
            )}
            <Button
              variant="contained"
              style={{ background: "white" }}
              onClick={() => {
                console.log("open wsp");
                window.open(
                  `https://wa.me/${ClinicaSelected.number_phone}`,
                  "_blank"
                );
              }}
            >
              <WhatsAppIcon
                style={{ color: "#128c7e", background: "white", fontSize: 40 }}
              ></WhatsAppIcon>
            </Button>
          </div>
          <div className="title">
            <h1>{ClinicaSelected.name_clinica}</h1>
            <h2>{ClinicaSelected.tag_name}</h2>
          </div>
          <div className="photo-portada" style={{
                backgroundImage: `url(${ClinicaSelected.photo_portada})`,
                backgroundPositionY: `${PositionImg.y}%` || 0,
              }}>
            {/* <img
              alt="imagen-clinica"
              
            ></img> */}
          </div>
          <div className="body">
            <div className="section__sections--desktop">
              <ul>
                <li>
                  <a href="#about">Sobre nosotros</a>
                </li>
                <li>
                  <a href="#calendar">Nuestros horarios</a>
                </li>
                <li>
                  <a href="#direction">Ubicacion</a>
                </li>
                <li>
                  <a href="#gallery">Galeria de fotos</a>
                </li>
                <li>
                  <a href="#ratings">Calificaciones</a>
                </li>
              </ul>
            </div>
            <div className="section__sections--mobile">
              <ul>
                <li>
                  <a href="#calendar">
                    <WatchLaterOutlinedIcon
                      style={{ fontSize: 40 }}
                    ></WatchLaterOutlinedIcon>
                  </a>
                </li>
                <li>
                  <a href="#direction">
                    <RoomOutlinedIcon
                      style={{ fontSize: 40 }}
                    ></RoomOutlinedIcon>
                  </a>
                </li>
                <li>
                  <a href="#gallery">
                    <PhotoSizeSelectActualOutlinedIcon
                      style={{ fontSize: 40 }}
                    ></PhotoSizeSelectActualOutlinedIcon>
                  </a>
                </li>
                <li>
                  <a href="#feedback">
                    <FeedbackOutlinedIcon
                      style={{ fontSize: 40 }}
                    ></FeedbackOutlinedIcon>
                  </a>
                </li>
              </ul>
            </div>
            <section className="section__description" id="about">
              <div className="content">
                <p>{ClinicaSelected.description} </p>
              </div>
            </section>
            <section className={`section_agenda`} id="calendar">
              <h3>Revisa nuestros horarios</h3>

              {/* <div className="header">
            <h4>Agenda tu proxima visita</h4>
            <div
              className=""
              onClick={() => {
                setCollapseAgenda(!CollapseAgenda);
              }}
            >
              {CollapseAgenda ? (
                <ExpandLessIcon fontSize="large"></ExpandLessIcon>
              ) : (
                <ExpandMoreIcon fontSize="large"></ExpandMoreIcon>
              )}
            </div>
          </div>

          <div className={`content ${CollapseAgenda ? "collapse" : "expand"}`}>
            <div className="options">
              <div className="option">
                <div className="option_header">Profesional</div>
              </div>
              <div className="option">
                <div className="option_header">Selecciona un día</div>
                <div>
                  {" "}
                  <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    weekends={false}
                    events={[
                      { title: "event 1", date: "2019-04-01" },
                      { title: "event 2", date: "2019-04-02" },
                    ]}
                  />
                </div>
              </div>
              <div className="option">
                <div className="option_header">Selecciona una hora</div>
              </div>
            </div>
          </div> */}
            </section>

            <section className={`section_map`} id="direction" ref={refCol}>
              <div className="header">
                {/* <h4>Donde estamos ubicados?</h4> */}
              </div>
              <div className="content">
                <div>
                  Estamos ubicados en{" "}
                  <b>
                    <a>
                      {ClinicaSelected.direction}, {ClinicaSelected.commune}
                    </a>
                  </b>
                </div>
                <div>
                  {/*TODO solucionar direccion dinamica, debe ser direccion + comuna 
              / o en su defecto, al guardar la direccion, tambien guarde las coordenadas, para ello
                cuando se ingrese la direccion, comuna y region, este debe ir a buscar una foto del mapa donde se esta marcando, para que asi el usuario pueda corregir.
                Lo otro que es posible es prediccion de direcciones.
            */}
                  {ClinicaSelected ? <div>{dynamicMap()}</div> : null}
                </div>
              </div>
            </section>
            <section className={`section_gallery`} id="gallery">
              <div className="header"></div>
              <div className="content">
                <div className="gallery">
                  <Grid container className={classes.root} spacing={0}>
                    <Grid item xs={12}>
                      <Grid container justify="center" spacing={0}>
                        {[
                          { src: "" },
                          { src: "" },
                          { src: "" },
                          { src: "" },
                          { src: "" },
                        ].map((value, i) => (
                          <Grid key={i} item style={{ margin: "20px" }}>
                            <Paper className={classes.paper}></Paper>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </section>
            <section className={`section_recomendaciones`} id="ratings">
              <h3>Calificaciones</h3>
            </section>
          </div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Informacion"
            ariaHideApp={false}
          >
            <div className="modal_content_message">
              <h3>
                Hola, somos {ClinicaSelected.name_clinica}, te responderemos a
                la brevedad
              </h3>
              <div className="emisor">
                <p>De : </p>
                <input
                  disabled={true}
                  value={User.name + " " + User.last_name}
                ></input>
              </div>
              <div className="mensaje">
                <textarea></textarea>
              </div>
              <div className="options">
                <p onClick={closeModal}>Cancelar</p>
                <Button variant="contained" color="primary">
                  Enviar
                </Button>
              </div>
            </div>
          </Modal>
        </div>
      
       ) }
     
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileClinica);
