import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import './CardPromo.scss';
import { Button } from '@material-ui/core';
/**
 * @name CardPromo
 * @param {*} props 
 */
const CardPromo = (props) => {
    return (
        <div className="card-promo_container"> 
            <div className="card-promo_container-title">
                <p>{props.title}</p>
            </div>
            <div className="card-promo_container-body">
                <p>
                    {props.content}
                </p>
            </div>
            <div className="card-promo_container-option">
                <Button fullWidth onClick={props.onClick}>Ir a ver</Button>
            </div>
        </div>
    )
}

CardPromo.propTypes = {

}

export default CardPromo
