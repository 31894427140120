import axios from "axios";
import { setExploreClinicasAction } from "./ExploreClinicasAction";

export const getExploreClinicasService = async (data) => {
  return await axios
    .post("http://api.mipetnow.com/get-location", {
      commune:data,
    })
    .then((resp) => resp)
    .then((resp)=>{
      // //console.log('resp getExploreClinicasService : : ',resp.data)
      //setExploreClinicasAction(resp.data);
      return resp.data;
    });
};
