import React, { Fragment, useState } from "react";
import "./SearchBar.scss";
import { TextField, Button, IconButton, InputAdornment, Input } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
/**
 * @name SearchBar
 * @param {*} props
 */
const SearchBar = (props) => {
  return (
    <div className="searchbar_container">
      <div className="searchbar_container__content">
        <div className="searchbar_container__content--inputs">
          <div className="content-input">
            <TextField
              id="input-text-field"
              placeholder="Clinica"
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end"> <SearchIcon /> </InputAdornment>,
              }}
             
            />
          </div>
          <div className="content-input">
            <Button  variant="outlined" aria-label="search" fullWidth >
              Filtrar
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

SearchBar.propTypes = {};

export default SearchBar;
