import { GET_SUCURSALES, GET_SUCURSAL_EDIT, GET_SUCURSAL_ELIMINAR, SET_SUCURSALES, SET_SUCURSAL_EDIT, SET_SUCURSAL_ELIMINAR } from "./SucursalAction";

const initialSucursalesState = {
  
};
export const sucursalesReducer = (state = initialSucursalesState, action) => {
  switch (action.type) {
    case SET_SUCURSALES:
      return Object.assign({}, state, action.data);
    case GET_SUCURSALES:
      return Object.assign({}, state, action.response);
    default:
      return state;
  }
};

const initialSucursalEditState = {
  
};
export const sucursalReducer = (state = initialSucursalEditState, action) => {
  switch (action.type) {
    case SET_SUCURSAL_EDIT:
      return Object.assign({}, state, action.data);
    case GET_SUCURSAL_EDIT:
      return Object.assign({}, state, action.response);
    default:
      return state;
  }
};


const initialSucursalEliminarState = {
  
};
export const sucursalEliminarReducer = (state = initialSucursalEliminarState, action) => {
  switch (action.type) {
    case SET_SUCURSAL_ELIMINAR:
      return Object.assign({}, state, action.data);
    case GET_SUCURSAL_ELIMINAR:
      return Object.assign({}, state, action.response);
    default:
      return state;
  }
};
