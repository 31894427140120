import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LocalCafeIcon from "@material-ui/icons/LocalCafe";
import StorefrontRoundedIcon from "@material-ui/icons/StorefrontRounded";
import PetsRoundedIcon from "@material-ui/icons/PetsRounded";
import {
  getProfesionalAction,
  getUserAction,
  setProfesionalAction,
  setUserAction,
} from "../User/userAction";
import Modal from "@material-ui/core/Modal";
import { Button, Input, MenuList, TextField } from "@material-ui/core";
import GoogleLogin from "react-google-login";
import { useHistory } from "react-router-dom";
import "./Header.scss";
import AuthLive from "../Pages/AuthLive/AuthLive";
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const Header = (props) => {
  const dispatch = useDispatch();

  const setUser = (data) => {
    dispatch(setUserAction(data));
  };
  let history = useHistory();
  const User = useSelector((state) => state.user);
  const { callback } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMessage, setanchorMessage] = useState(null);
  const [anchorNotification, setanchorNotification] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [open, setOpen] = React.useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleIniciarSesion = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    handleOpenModal();
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleSesionClose = () => {
    localStorage.removeItem("user");
    handleMenuClose();
    setUserAction(null);
    setUserAction({ isLogin: false });
    history.push("/");
    window.location.reload();
  };
  const handleClickMenu = () => {};

  const menuId = "primary-search-account-menu";
  const menuItems = [];
  menuItems.push(
    <MenuItem key={menuItems.length} onClick={handleMenuClose}>
      Mi perfil
    </MenuItem>
  );
  menuItems.push(
    <MenuItem key={menuItems.length} onClick={handleSesionClose}>
      Cerrar sesion
    </MenuItem>
  );
  const openMessageMenu = (event) => {
    setanchorMessage(event.currentTarget);
  };

  const closeMessageMenu = () => {
    setanchorMessage(null);
  };
  const openNotificationMenu = (event) => {
    console.log("open not");
    setanchorNotification(event.currentTarget);
  };

  const closeNotificationMenu = () => {
    setanchorNotification(null);
  };
  const goToExploreClinicas = () => {
    history.push("/explore-clinicas");
  };
  const goToExploreTiendas = () => {
    history.push("/explore-tiendas");
  };

  const goToBlog = () => {
    window.open(`/blog`, "_blank");
  };
  // const messageMenu =
  //   User !== null && User && User.messages === undefined ? null : (
  //     <Menu
  //       id="simple-menu"
  //       anchorEl={anchorMessage}
  //       anchorOrigin={{
  //         vertical: "bottom",
  //         horizontal: "left",
  //       }}
  //       transformOrigin={{
  //         vertical: "top",
  //         horizontal: "right",
  //       }}
  //       keepMounted
  //       open={Boolean(anchorMessage)}
  //       onClose={closeMessageMenu}
  //     >
  //       {User.messages.length <= 0 ? (
  //         <MenuItem>Sin mensajes</MenuItem>
  //       ) : (
  //         User !== null &&
  //         User &&
  //         User.messages.map((mess, i) => {
  //           return (
  //             <MenuItem key={i} onClick={closeMessageMenu}>
  //               <b>{mess.author}</b>: {mess.message}
  //             </MenuItem>
  //           );
  //         })
  //       )}
  //     </Menu>
  //   );
  // const notificationMenu =
  //   User !== null && User && User.notifications === undefined ? null : (
  //     <Menu
  //       id="simple-menu"
  //       // className="menu-item"
  //       anchorEl={anchorNotification}
  //       anchorOrigin={{
  //         vertical: "bottom",
  //         horizontal: "left",
  //       }}
  //       transformOrigin={{
  //         vertical: "top",
  //         horizontal: "right",
  //       }}
  //       keepMounted
  //       open={Boolean(anchorNotification)}
  //       onClose={closeNotificationMenu}
  //     >
  //       {User.notifications.length <= 0 ? (
  //         <MenuItem>Nada nuevo</MenuItem>
  //       ) : (
  //         User !== null &&
  //         User &&
  //         User.notifications.map((mess, i) => {
  //           return (
  //             <MenuItem key={i} onClick={closeNotificationMenu}>
  //               <p
  //                 onClick={() => {
  //                   history.push(mess.action);
  //                 }}
  //               >
  //                 {mess.label}
  //               </p>
  //             </MenuItem>
  //           );
  //         })
  //       )}
  //     </Menu>
  //   );
  const renderMenu =
    User.isLogin === false ? (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        onClick={handleClickMenu}
        style={{ minHeight: "3rem" }}
      >
        <MenuItem key={0} onClick={handleIniciarSesion}>
          Iniciar Sesion
        </MenuItem>
      </Menu>
    ) : (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        onClick={handleClickMenu}
      >
        <MenuItem key={1} onClick={handleSesionClose}>
          Cerrar sesion
        </MenuItem>
      </Menu>
    );
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = () => {
    return User.isLogin === false ? (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem onClick={handleIniciarSesion}>Iniciar Sesion</MenuItem>
      </Menu>
    ) : (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton
            aria-label="mensajes"
            color="inherit"
            onClick={openMessageMenu}
          >
            <Badge
              badgeContent={User.isLogin === true ? User.messages.length : null}
              color="secondary"
            >
              <MailIcon />
            </Badge>
          </IconButton>
        </MenuItem>
        <MenuItem key={0} onClick={handleMenuClose}>
          <IconButton
            aria-label="mensajes"
            color="inherit"
            onClick={openMessageMenu}
          >
            <Badge badgeContent={User.messages.length} color="secondary">
              <AccountCircle />
            </Badge>
          </IconButton>
        </MenuItem>
        <MenuItem key={1} onClick={handleSesionClose}>
          <IconButton
            aria-label="mensajes"
            color="inherit"
            onClick={openMessageMenu}
          >
            <Badge badgeContent={User.messages.length} color="secondary">
              <ExitToAppIcon />
            </Badge>
          </IconButton>
        </MenuItem>
      </Menu>
    );
  };
  return (
    <>
      <div className="header-container">
        <AppBar position="static" elevation={0}>
          <Toolbar>
            {User.isLogin ? (
              <IconButton
                edge="start"
                className="menu-button"
                color="inherit"
                aria-label="open drawer"
                onClick={User.isLogin ? callback(true) : null}
              >
                <MenuIcon />
              </IconButton>
            ) : null}

            <Typography
              className="title"
              variant="h6"
              noWrap
              onClick={() => {
                history.push("/");
              }}
            >
              MipetNow{" "}
              {process.env.REACT_APP_ENV != "PROD"
                ? ` - ${process.env.REACT_APP_ENV}`
                : null}
            </Typography>
            <div className={"section-button__desktop"}>
              <Button
                edge="start"
                className={"button-desktop"}
                color="inherit"
                aria-label="open drawer"
                onClick={goToExploreTiendas}
              >
                <StorefrontRoundedIcon /> <p>Tiendas</p>
              </Button>
              <Button
                edge="start"
                className={"button-desktop"}
                color="inherit"
                aria-label="open drawer"
                onClick={goToExploreClinicas}
              >
                <PetsRoundedIcon /> <p>Clinicas</p>
              </Button>
              {/* <Button
                edge="start"
                className={"button-desktop"}
                color="inherit"
                aria-label="open drawer"
                onClick={goToBlog}
              >
                <LocalCafeIcon /> <p>Blog</p>
              </Button> */}
            </div>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/* {!User.isLogin ? (
                <div></div>
              ) : (
                <div>
                  <IconButton
                    aria-label="mensajes"
                    color="inherit"
                    onClick={openMessageMenu}
                  >
                    <Badge
                      badgeContent={
                        User.isLogin && User.messages && User.messages.length
                      }
                      color="secondary"
                    >
                      <MailIcon />
                    </Badge>
                  </IconButton>
                  <IconButton
                    aria-label="notificaciones"
                    color="inherit"
                    onClick={openNotificationMenu}
                  >
                    <Badge
                      badgeContent={
                        User.isLogin &&
                        User.notifications &&
                        User.notifications.length
                      }
                      color="secondary"
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </div>
              )} */}
              <p styles={{ fontSize: 16 }}>
                {User.name + " " + User.last_name}
              </p>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              {!User.isLogin ? (
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  className="button-user"
                >
                  <AccountCircle />
                  <p>Login</p>
                </IconButton>
              ) : (
                <div>
                  {/* <IconButton
                    aria-label="mensajes"
                    color="inherit"
                    onClick={openMessageMenu}
                  >
                    <Badge
                      badgeContent={
                        User.isLogin && User.messages && User.messages.length
                      }
                      color="secondary"
                    >
                      <MailIcon />
                    </Badge>
                  </IconButton> */}
                  {/* <IconButton
                    aria-label="show 17 new notifications"
                    color="inherit"
                    onClick={openNotificationMenu}
                  >
                    <Badge
                      badgeContent={
                        User.isLogin &&
                        User.notifications &&
                        User.notifications.length
                      }
                      color="secondary"
                    >
                      <NotificationsIcon />
                    </Badge>
                  </IconButton> */}
                 
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </div>
              )}
            </div>
          </Toolbar>
        </AppBar>
        <>{renderMobileMenu}</>
        <>{renderMenu}</>
        {/* <>{messageMenu}</>
        <>{notificationMenu}</> */}
        {/* <div className="menu-styled">{notificationMenu}</div> */}
      </div>

      <AuthLive
        handleCloseModal={handleCloseModal}
        open={open}
        setUser={setUser}
      ></AuthLive>
    </>
  );
};

export default Header;
