import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExploreTiendasPage from "./ExploreTiendasPage";
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import useTitle from "Hooks/useTitle";
const ExploreTiendasContainer = () => {
  //React Router
  const history = useHistory();

  //React Redux
  const dispatch = useDispatch();

  //React States

  //React Events

  const handleClick = () => {};

  const handleClickNextPage = () => {};
  useTitle({title:'Tiendas'})

  return (
    <div>
      <ExploreTiendasPage
        handleClick={handleClick}
        handleClickNextPage={handleClickNextPage}
      />
    </div>
  );
};

export default ExploreTiendasContainer;
