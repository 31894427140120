import React from 'react'
import { useSelector } from 'react-redux'
import QRCode from 'react-qr-code';
import './ShareQr.scss';
export const ShareQr = (props) => {
    const user = useSelector(state => state.user);
    return (
        <div className="share-qr-container">
            <div className="share-qr-container__title">
                <h1>Usa este QR para que te conozcan</h1>
            </div>
            <div className="share-qr-container__qr"><QRCode value={`https://dev.mipetnow.com/profile-clinica/${user.tag_name}`} /></div>
        </div>
    )
}


export default (ShareQr)
