import React, { useEffect, useState } from "react";
import "./ProfileProfessional.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
//import Button from '../Components/Button/Button'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getProfesionalAction, setProfesionalAction } from "../../User/userAction";
import { dispatch } from "d3-dispatch";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Modal from "react-modal";
import { makeStyles } from "@material-ui/core/styles";
//import Button from 'lib-storybook-nowcompany';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { green } from '@material-ui/core/colors';

import { createSelector } from "reselect";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    "@media only screen and (max-width: 991px)": {
      width: "80%",
    },
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 250,
    width: 250,
    position: "relative",
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export const ProfileProfessional = (props) => {


  const history = useHistory();
  const classes = useStyles();
  const [CollapseAgenda, setCollapseAgenda] = useState(false);
  const [OpenActions, setOpenActions] = useState(true);
  const [Direction, setDirection] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [ShowModal, setShowModal] = useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }
  function closeModal() {
    setIsOpen(false);
  }
  const setShowModal_ = (provider) => {
    // setShowModal(!ShowModal);

    if (ShowModal) {
      closeModal();
    } else {
      //setProviderSelected({ ...provider });
      openModal();
    }
    ////console.log("State 1",props.state.showmodal);
  };


  const reportNetworkError = () => {
    alert("This action could not be completed");
  };
  const handleDateSelect = (selectInfo) => {
    let calendarApi = selectInfo.view.calendar;
    //let title = prompt("Ingresa nombre de cliente");
    //handleOpenModal();
    // calendarApi.unselect(); // clear date selection

    // if (title) {
    //   calendarApi.addEvent(
    //     {
    //       // will render immediately. will call handleEventAdd
    //       title,
    //       start: selectInfo.startStr,
    //       end: selectInfo.endStr,
    //       allDay: selectInfo.allDay,

    //     },
    //     true
    //   ); // temporary=true, will get overwritten when reducer gives new events
    // }
  };
  const handleEventClick = (clickInfo) => {
    // if (
    //   alert(
    //     `Are you sure you want to delete the event '${clickInfo.event.title}'`
    //   )
    // ) {
    //   clickInfo.event.remove(); // will render immediately. will call handleEventRemove
    // }
    //console.log(clickInfo);
  };

  // handlers that initiate reads/writes via the 'action' props
  // ------------------------------------------------------------------------------------------

  const handleDates = (rangeInfo) => {
    // props
    // .requestEvents(rangeInfo.startStr, rangeInfo.endStr)
    // .catch(reportNetworkError);
  };

  const handleEventAdd = (addInfo) => {
    //console.log(addInfo);
    // props.createEvent(addInfo.event.toPlainObject()).catch(() => {
    //   reportNetworkError();
    //   addInfo.revert();
    // });
  };

  const handleEventChange = (changeInfo) => {
    // props.updateEvent(changeInfo.event.toPlainObject()).catch(() => {
    //   reportNetworkError();
    //   changeInfo.revert();
    // });
  };

  const handleEventRemove = (removeInfo) => {
    // props.deleteEvent(removeInfo.event.id).catch(() => {
    //   reportNetworkError();
    //   removeInfo.revert();
    // });
  };

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </>
    );
  };
  const openModalMessage = () => {
    setIsOpen(true)
    //console.log(modalIsOpen)
  }
  useEffect(() => {
    if(props.profesional.direction === undefined){
      return;
    }
    //console.log(props.profesional.direction);
    var s = props.profesional.direction.split(" ").join("+");
    //return s;
    setDirection(s);
  }, [props.profesional.direction]);
  return (
    <div className="profile-professional-container">
      <div className="options">
        
        <Button
          
          onClick={() => {
            //console.log('go back')
            history.goBack();
          }}
        >
          Volver
        </Button>
        <Button  variant="contained" style={{background:'green'}} onClick={()=>{openModalMessage()}}>
          <ChatBubbleOutlineIcon style={{ color: 'white' }} ></ChatBubbleOutlineIcon>
        </Button>
       
      </div>
      <div className="title">
        <h1>{props.profesional.displayName}</h1>
        <h6>{props.profesional.username}</h6>
      </div>
      <div
        style={{
          backgroundImage: `url(${props.profesional.photo_professional})`,
        }}
        className="photo-portada"
      ></div>
      <div className="body">
        <div className={`section_agenda`}>
          <div className="header">
            <h4>Agenda tu proxima visita</h4>
            <div
              className=""
              onClick={() => {
                setCollapseAgenda(!CollapseAgenda);
              }}
            >
              {CollapseAgenda ? (
                <ExpandLessIcon fontSize="large"></ExpandLessIcon>
              ) : (
                <ExpandMoreIcon fontSize="large"></ExpandMoreIcon>
              )}
            </div>
          </div>

          <div className={`content ${CollapseAgenda ? "collapse" : "expand"}`}>
            <div className="options">
              <div className="option">
                <div className="option_header">Profesional</div>
              </div>
              <div className="option">
                <div className="option_header">Selecciona un día</div>
                <div>
                  {" "}
                  <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    weekends={false}
                    events={[
                      { title: "event 1", date: "2019-04-01" },
                      { title: "event 2", date: "2019-04-02" },
                    ]}
                  />
                </div>
              </div>
              <div className="option">
                <div className="option_header">Selecciona una hora</div>
              </div>
            </div>
          </div>
        </div>
        <div className={`section_description`}>
          <div className="content">{props.profesional.description}</div>
        </div>
        <div className={`section_map`}>
          <div className="header">
            <h4>Donde estamos ubicados?</h4>
          </div>
          <div className="content">
            <div>
              Estamos ubicados en{" "}
              <b>
                <a>Cardenal Raul Silva Henriquez 319</a>
              </b>
            </div>
            <div>
              <iframe
                width="600"
                height="450"
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAmCuGdeXjLuB1tANy3BjVfxBeJlNtNF6s&q=${Direction}`}
              ></iframe>
            </div>
          </div>
        </div>
        <div className={`section_gallery`}>
          <div className="header"></div>
          <div className="content">
            <div className="gallery">
              <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                  <Grid container justify="center" spacing={0}>
                    {[
                      { src: "" },
                      { src: "" },
                      { src: "" },
                      { src: "" },
                      { src: "" },
                    ].map((value, i) => (
                      <Grid key={i} item style={{ margin: "20px" }}>
                        <Paper className={classes.paper}></Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className={`section_recomendaciones`}>Recomendaciones</div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Informacion"
        ariaHideApp={false}
      >
        <div className="modal_content_message">
          <h3>Hola, somos {props.profesional.displayName}, te responderemos a la brevedad</h3>
          <div className="emisor"><p>De : </p><input disabled={true} value={props.user.name + ' '+props.user.lastName}></input></div>
          <div className="mensaje">
            <textarea></textarea>
          </div>
          <div className="options">
                      <p onClick={closeModal}>Cancelar</p>
                      <Button variant="contained" color="primary" >Enviar</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profesional: state.profesional,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProfesionalAction: () => dispatch(getProfesionalAction),
  setProfesionalAction: (data) => dispatch(setProfesionalAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileProfessional);
