import React, { Component } from "react";
import { connect } from "react-redux";
import "./AdminGallery.scss";
import { dispatch } from "d3-dispatch";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// import Button from 'static';
import { useHistory, useLocation, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 250,
    width: 250,
    position: "relative",
  },
  control: {
    padding: theme.spacing(1),
  },
}));
export const AdminGallery = (props) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className="administrator-gallery">
      <div className="section-upload">
          {/* <Button></Button> */}
      </div>
      <div className="section-gallery">
        <div className="header">
            <h4>Mis Fotos</h4>
        </div>
        <div className="content">
          <Grid container className={classes.root} spacing={0}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={0}>
                {[
                  { src: "" },
                  { src: "" },
                  { src: "" },
                  { src: "" },
                  { src: "" },
                ].map((value, i) => (
                  <Grid key={i} item style={{ margin: "10px" }}>
                    <Paper className={classes.paper}></Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminGallery);
