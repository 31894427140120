import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import "./App.scss";
import { compose } from "redux";
import LoginEmail from "./Pages/LoginEmail/LoginEmail";
import PageInit from "./Pages/PageInit/PageInit";
import { Header } from "./Header/Header";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import GridOnIconOutlined from "@material-ui/icons/GridOnOutlined";
import ApartmentOutlinedIcon from "@material-ui/icons/ApartmentOutlined";
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import {
  setUserAction,
  getUserAction,
  getProfesionalAction,
  setProfesionalAction,
} from "./User/userAction";
import FaceIcon from "@material-ui/icons/Face";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import StoreRoundedIcon from "@material-ui/icons/StoreRounded";
import QuestionAnswerRounded from "@material-ui/icons/QuestionAnswerRounded";
import ShareRounded from "@material-ui/icons/ShareRounded";
import MessageIcon from "@material-ui/icons/Message";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { useHistory } from "react-router-dom";
import { Profile } from "./Pages/Profile/Profile";
import { CalendarCenter } from "./Pages/CalendarCenter/CalendarCenter";
import { Register } from "./Register/Register";
import { ProfileProfessional } from "./Pages/ProfileProfessional/ProfileProfessional";
import FicheroClinico from "./Pages/FicheroClinico/FicheroClinico";
// import Inventario from "./Pages/Inventario/Inventario";
import AuthLive from "./Pages/AuthLive/AuthLive";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import ProfileComercial from "./Pages/ProfileComercial/ProfileComercial";
import { CenterMessage } from "./Pages/CenterMessage/CenterMessage";
import { QuestionAnswer } from "./Pages/QuestionAnswer/QuestionAnswer";
import EmpleadosProfesionales from "./Pages/EmpleadosProfesionales/EmpleadosProfesionales";
import Sucursales from "./Pages/Sucursales/Sucursales";
import CrearSucursal from "./Pages/Sucursales/Crear/CrearSucursal";
import DetalleSucursal from "./Pages/Sucursales/Detalle/DetalleSucursal";
import ModificarSucursal from "./Pages/Sucursales/Modificar/ModificarSucursal";
import CrearEmpleado from "./Pages/EmpleadosProfesionales/Crear/CrearEmpleado";
import ModificarEmpleado from "./Pages/EmpleadosProfesionales/Modificar/ModificarEmpleado";
import DetalleEmpleado from "./Pages/EmpleadosProfesionales/Detalle/DetalleEmpleado";
import ExploreClinicasContainer from "./Pages/ExploreClinicas";
import ExploreTiendasContainer from "./Pages/ExploreTiendas";
import { ProfileClinica } from "Pages/ProfileClinica/ProfileClinica";
import Dashboard from 'Pages/Dashboard/Dashboard';
import Cookies from "universal-cookie";
import axios from "axios";
import { EnumApi } from "Common/Enums";
import BlogContainer from "Pages/Blog";
import { ShareQr } from "Pages/ShareQr/ShareQr";
import CloseSessionContainer from "Pages/CloseSession";

const mapStateToProps = (state) => {
  return state;
};

/**
 * recargar pagina
 * ir a buscar data de usuario.
 * reemplazar userInLs (usuario en local storage)
 *
 *
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getUserAction: () => dispatch(getUserAction),
    setUserAction: (data) => dispatch(setUserAction(data)),
    getProfesionalAction: () => dispatch(getProfesionalAction),
    setProfesionalAction: (data) => dispatch(setProfesionalAction(data)),
  };
};

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export const App = (props) => {
  const cookies = new Cookies();
  cookies.set("mipetnow.com", "Mipetnow", { sameSite: false });
  let history = useHistory();
  const classes = useStyles();
  const [stateDrawer, setstateDrawer] = useState(false);
  const [isLoged, setisLoged] = useState(false);
  const [userInLS, setuserInLS] = useState({});
  const userSelector = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    
    const item = localStorage.getItem("user");
    const userData = item != null ? JSON.parse(item):{};
    // //console.log(item.isLogin)
    if (userData && userData.isLogin) {

      try {
        setIsLoading(true);
        const refreshData =  axios
        .post(EnumApi[userData.type], {
          username: userData.email,
          email: userData.email,
        })
        .then((resp) => {
          if (resp) {
            if (resp.status === 403) {
              alert("Usuario o clave incorrecta");
              return;
            }
            let data = { ...resp.data, isLogin: true };
            props.setUserAction({ ...data });
            setuserInLS({...data})
            localStorage.setItem("user", JSON.stringify(data));
            setIsLoading(false);
            return data;
          }
        });
        return refreshData;
      } catch (err) {
        console.warn("Setting localStorage went wrong: ", err);
        return props.value;
      }
    } else {
      return;
    }
  }, []);
 
  const toggleDrawer = (open) => (event) => {
    if (props.user.isLogin === false) return;
    setisLoged(props.user.isLogin);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    //if(open){

    //}
    setstateDrawer(open);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {props.user.isLogin === false ? null : (
        <List>
          <ListItem
            button
            onClick={() => {
              history.push("/");
            }}
          >
            <ListItemIcon>
              <HomeRoundedIcon></HomeRoundedIcon>
            </ListItemIcon>
            <ListItemText primary={"Inicio"} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              history.push("/profile");
            }}
          >
            <ListItemIcon>
              <FaceIcon></FaceIcon>
            </ListItemIcon>
            <ListItemText primary={"Perfil"} />
          </ListItem>
          {props.user.type === "2" ? (
            <ListItem
              button
              onClick={() => {
                history.push("/profile-comercial");
              }}
            >
              <ListItemIcon>
                <StoreRoundedIcon></StoreRoundedIcon>
              </ListItemIcon>
              <ListItemText primary={"Perfil Comercial"} />
            </ListItem>
          ) : null}

          {/* <ListItem
            button
            onClick={() => {
              history.push("/CalendarCenter");
            }}
          >
            <ListItemIcon>
              <CalendarToday></CalendarToday>
            </ListItemIcon>
            <ListItemText primary={"Calendario"} />
          </ListItem> */}
          {/* <ListItem
            button
            onClick={() => {
              history.push("/CenterMessage");
            }}
          >
            <ListItemIcon>
              <MessageIcon></MessageIcon>
            </ListItemIcon>
            <ListItemText primary={"Mensajes"} />
          </ListItem> */}
          <ListItem button>
            <ListItemIcon>
              <FavoriteIcon></FavoriteIcon>
            </ListItemIcon>
            <ListItemText primary={"Favoritos"} />
          </ListItem>
          {props.user.type === "2" ? (
            <>
              {/* <ListItem
                button
                onClick={() => {
                  history.push("/sucursales");
                }}
              >
                <ListItemIcon>
                  <ApartmentOutlinedIcon></ApartmentOutlinedIcon>
                </ListItemIcon>
                <ListItemText primary={"Sucursales"} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  history.push("/Inventario");
                }}
              >
                <ListItemIcon>
                  <GridOnIconOutlined></GridOnIconOutlined>
                </ListItemIcon>
                <ListItemText primary={"Inventario"} />
              </ListItem>
              <ListItem
                button
                onClick={() => {
                  history.push("/empleados");
                }}
              >
                <ListItemIcon>
                  <PeopleAltOutlinedIcon></PeopleAltOutlinedIcon>
                </ListItemIcon>
                <ListItemText primary={"Empleados Profesionales"} />
              </ListItem> */}
            </>
          ) : null}

          <ListItem
            button
            onClick={() => {
              history.push("/QuestionAnswer");
            }}
          >
            <ListItemIcon>
              <QuestionAnswerRounded></QuestionAnswerRounded>
            </ListItemIcon>
            <ListItemText primary={"Preguntas Frecuentes"} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              history.push("/share-qr");
            }}
          >
            <ListItemIcon>
              <ShareRounded></ShareRounded>
            </ListItemIcon>
            <ListItemText primary={"Compartir QR"} />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              history.push("/close-session");
            }}
          >
            <ListItemIcon>
              <ExitToAppOutlinedIcon></ExitToAppOutlinedIcon>
            </ListItemIcon>
            <ListItemText primary={"Cerrar Sesion"} />
          </ListItem>
        </List>
      )}
    </div>
  );

  return (
    <div className="App-container">
      <Header callback={toggleDrawer} {...props} {...userInLS}></Header>
      <div>
        <React.Fragment key={"left"}>
          <Drawer
            anchor={"left"}
            open={stateDrawer}
            onClose={toggleDrawer(false)}
          >
            {list("left")}
          </Drawer>
        </React.Fragment>
      </div>
      <div className="App-container-body">
      <Switch>
        <Route path="/auth">
          <AuthLive />
        </Route>
        <Route path="/loginEmail">
          <LoginEmail />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/blog">
          <BlogContainer />
        </Route>
        <Route path="/profile">
          <PrivateRoute path="/profile">
            <Profile {...props} {...userInLS} />
          </PrivateRoute>
        </Route>
        <Route path="/profile-comercial">
          <PrivateRoute path="/profile-comercial">
            <ProfileComercial {...props} {...userInLS} />
          </PrivateRoute>
        </Route>
        <Route path="/profile-professional/:id">
          <ProfileProfessional {...props} {...userInLS} />
        </Route>
        <Route path="/profile-clinica/:id">
          <ProfileClinica {...props} {...userInLS} />
        </Route>
        <Route path="/CalendarCenter">
          <PrivateRoute path="/CalendarCenter">
            <CalendarCenter {...props} {...userInLS}></CalendarCenter>
          </PrivateRoute>
        </Route>
        <Route path="/QuestionAnswer">
          <PrivateRoute path="/QuestionAnswer">
            <QuestionAnswer {...props} {...userInLS}></QuestionAnswer>
          </PrivateRoute>
        </Route>
        <Route path="/CenterMessage">
          <PrivateRoute path="/CenterMessage">
            <CenterMessage {...props} {...userInLS}></CenterMessage>
          </PrivateRoute>
        </Route>
        <Route path="/FicheroClinico/(id)?/:id?">
          <PrivateRoute path="/FicheroClinico/(id)?/:id?">
            <FicheroClinico {...props} {...userInLS}></FicheroClinico>
          </PrivateRoute>
        </Route>
        {/* <Route path="/inventario">
          <PrivateRoute path="/inventario">
            <Inventario {...props} {...userInLS}></Inventario>
          </PrivateRoute>
        </Route> */}
        <Route exact path="/sucursales">
          <PrivateRoute path="/sucursales">
            <Sucursales {...props} {...userInLS}></Sucursales>
          </PrivateRoute>
        </Route>
        <Route exact path="/sucursales/crear">
          <PrivateRoute path="/sucursales/crear">
            <CrearSucursal {...props} {...userInLS}></CrearSucursal>
          </PrivateRoute>
        </Route>
        <Route exact path="/sucursales/:id">
          <PrivateRoute path="/sucursales/:id">
            <DetalleSucursal {...props} {...userInLS}></DetalleSucursal>
          </PrivateRoute>
        </Route>
        <Route exact path="/sucursales/editar/:id">
          <PrivateRoute path="/sucursales/editar/:id">
            <ModificarSucursal {...props} {...userInLS}></ModificarSucursal>
          </PrivateRoute>
        </Route>
        <Route exact path="/empleados">
          <PrivateRoute path="/empleados">
            <EmpleadosProfesionales
              {...props}
              {...userInLS}
            ></EmpleadosProfesionales>
          </PrivateRoute>
        </Route>
        <Route exact path="/empleados/crear">
          <PrivateRoute path="/empleados/crear">
            <CrearEmpleado {...props} {...userInLS}></CrearEmpleado>
          </PrivateRoute>
        </Route>
        <Route exact path="/empleados/:id">
          <PrivateRoute path="/empleados/:id">
            <DetalleEmpleado {...props} {...userInLS}></DetalleEmpleado>
          </PrivateRoute>
        </Route>
        <Route exact path="/empleados/editar/:id">
          <PrivateRoute path="/empleados/editar/:id">
            <ModificarEmpleado {...props} {...userInLS}></ModificarEmpleado>
          </PrivateRoute>
        </Route>
        <Route path="/dashboard">
          <PrivateRoute path="/dashboard">
            <PageInit {...props} {...userInLS}></PageInit>
          </PrivateRoute>
        </Route>
        <Route path="/dashboard-clinica">
          <PrivateRoute path="/dashboard-clinica">
            <Dashboard {...props} {...userInLS}></Dashboard>
          </PrivateRoute>
        </Route>
        <Route path="/share-qr">
          <PrivateRoute path="/share-qr">
            <ShareQr {...props} {...userInLS}></ShareQr>
          </PrivateRoute>
        </Route>
        <Route path="/explore-clinicas">
          <ExploreClinicasContainer></ExploreClinicasContainer>
        </Route>
        <Route path="/explore-tiendas">
          <ExploreTiendasContainer></ExploreTiendasContainer>
        </Route>
        <Route path="/close-session">
          <CloseSessionContainer></CloseSessionContainer>
        </Route>
        <Route path="/">
          <PageInit {...props} {...userInLS} />
        </Route>
      </Switch>
      </div>
    </div>
  );
};


export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
