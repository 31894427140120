import React, { useRef, useState } from 'react';
// import parserHTML from 'html-react-parser';
import { useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './ExploreTiendas.scss';
import SearchBar from '../../Components/SearchBar/SearchBar';

const ExploreTiendasPage = ({ ...props }) => {
  
  return (
    <HelmetProvider>
      <Helmet>
        <title>MiPetNow - Tiendas</title>
        <meta
          name="keywords"
          content="Content"
        />
        <meta
          name="description"
          content="Description"
        />
      </Helmet>
      <div className="exploretiendas">
        <SearchBar></SearchBar>
        <div className="exploretiendas__content">
          
        </div>
      </div>
    </HelmetProvider>
  );
};

export default ExploreTiendasPage;
