import React, { useEffect, useRef, useState } from "react";
// import parserHTML from 'html-react-parser';
import { connect, useDispatch, useSelector } from "react-redux";
// import ExploreClinicasPage from "./ExploreClinicasPage";
import Geocode from "react-geocode";
import * as regionsandcommunes from "../../Common/regionsandcomunes.json";
import axios from "axios";
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./ExploreClinicas.scss";
import SearchBar from "../../Components/SearchBar/SearchBar";
import ProviderCard from "Components/ProviderCard/ProviderCard";
import CardClinica from "Components/Cards/CardClinica/CardClinica";
import { getClinicasGeoAPI } from "services/endpoint";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getExploreClinicasAction,
  setCommuneAction,
} from "./ExploreClinicasAction";

Geocode.setApiKey("AIzaSyAmCuGdeXjLuB1tANy3BjVfxBeJlNtNF6s");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();

const ExploreClinicasPage = (props) => {
  const { clinicas, commune } = useSelector((state) => state.exploreClinicas);
  const [DataLoaded, setDataLoaded] = useState(false);
  //React Router
  const history = useHistory();

  //React Redux
  const dispatch = useDispatch();
  const [CommuneGeo, setCommuneGeo] = useState({});
  const [CommuneGeoCode, setCommuneGeoCode] = useState(() => {
    return commune !== "" ? [{ name: commune }] : [{}];
  });
  const [Regiones, setRegiones] = useState(() => {
    let regiones = regionsandcommunes.default.regions;
    const listregiones = regiones.map((region) => {
      let objRegion = { id: region.number + "", value: region.name };
      return objRegion;
    });
    return listregiones;
  });
  const [Region, setRegion] = useState("");
  // const [Clinicas, setClinicas] = useState([]);
  useEffect(() => {
    // //console.log("estado actual de CommuneGeoCode : ", CommuneGeoCode);
    // if(CommuneGeoCode[0].name !== undefined) return;
    if (navigator.geolocation) {
      const coords = navigator.geolocation.watchPosition(function (position) {
        Geocode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          (response) => {
            let city, state, country;
            for (
              let i = 0;
              i < response.results[0].address_components.length;
              i++
            ) {
              for (
                let j = 0;
                j < response.results[0].address_components[i].types.length;
                j++
              ) {
                switch (response.results[0].address_components[i].types[j]) {
                  case "locality":
                    city = response.results[0].address_components[i].long_name;
                    break;
                  case "administrative_area_level_1":
                    state = response.results[0].address_components[i].long_name;
                    break;
                  case "country":
                    country =
                      response.results[0].address_components[i].long_name;
                    break;
                }
              }
            }

            setCommuneGeo(city);
            let region = regionsandcommunes.default.regions.filter((region) => {
              return region.name === state;
            });
            setRegion(region);
            props.setCommuneAction(city);
            setCommuneGeoCode(
              region[0].communes.filter((c) => {
                if (c.name === city) {
                  return c.name;
                }
              })
            );
          },
          (error) => {
            console.error("error", error);
          }
        );
      });
    }else{
      //console.log("no hay gps activado ")
    }
  }, []);
  useEffect(() => {
    //llamar api /clinicas/get-location
    // if(commune !== '') return;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        commune: CommuneGeoCode[0].name,
      }),
    };
    const commune = CommuneGeoCode[0].name;
    dispatch(getExploreClinicasAction(commune));
  }, [CommuneGeoCode[0]]);
  useEffect(() => {
    // if(clinicas.clinicas.lenght > 0){
    //   //console.log('llegaron las clinicas')
    // }
    setDataLoaded(
      clinicas && clinicas.clinicas && clinicas.clinicas.length > 0
        ? true
        : false
    );
  }, [clinicas]);
  return (
    <HelmetProvider>
      <Helmet>
        <title>MiPetNow - Clinicas</title>
        <meta name="keywords" content="Content" />
        <meta name="description" content="Description" />
      </Helmet>
      <div className="exploreclinicas">
        <SearchBar></SearchBar>
        <div className="exploreclinicas__content">
          <div className="exploreclinicas__content-body">
            {!DataLoaded ? (
              <h2>Buscando clinicas en tu sector</h2>
            ) : (
              <h2>Hemos encontrado las siguientes clinicas</h2>
            )}
            <div className="content-body__data">
              {!DataLoaded ? (
                <div className="content-body__data-skeleton">
                  <Skeleton
                    style={{ margin: "0px .5rem" }}
                    count={1}
                    width={300}
                    height={300}
                  />
                  <Skeleton
                    style={{ margin: "0px .5rem" }}
                    count={1}
                    width={300}
                    height={300}
                  />
                  <Skeleton
                    style={{ margin: "0px .5rem" }}
                    count={1}
                    width={300}
                    height={300}
                  />
                </div>
              ) : (
                clinicas &&
                clinicas.clinicas &&
                clinicas.clinicas.map((c, i) => {
                  return <CardClinica data={c} key={i}></CardClinica>;
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  );
};
const mapStateToProps = (state) => ({
  state: state,
});

const mapDispatchToProps = (dispatch) => ({
  getExploreClinicasAction: (req) => dispatch(getExploreClinicasAction(req)),
  setCommuneAction: (data) => dispatch(setCommuneAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExploreClinicasPage);
// export default ExploreClinicasPage;
