import React, { useState } from "react";
import PropTypes from "prop-types";
import "./DashboardProfessional.scss";
import { connect } from "react-redux";
import { Graph } from "react-d3-graph";
import { PageInit } from "../../Pages/PageInit/PageInit";
import { getUserAction, setUserAction } from "../../User/userAction";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { Button } from "@material-ui/core";
// import {Button as Btn } from 'lib-storybook-nowcompany';
import { useHistory } from "react-router";
import CollapsibleTable from "./TableCitas";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
export const DashboardProfessional = (props) => {
  let history = useHistory();
  const [OpenActions, setOpenActions] = useState(true);
  const [DataView, setDataView] = useState({
    name: "Sin seleccionar",
    value: 0,
    color: "#000000",
  });
  // graph payload (with minimalist structure)
  const data = [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 100 },
    { name: "Group D", value: 200 },
  ];

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  // the graph configuration, just override the ones you need
  const myConfig = {
    nodeHighlightBehavior: true,
    node: {
      color: "lightgreen",
      size: 120,
      highlightStrokeColor: "blue",
    },
    link: {
      highlightColor: "lightblue",
    },
  };

  const onClickNode = function (nodeId) {
    window.alert(`Clicked node ${nodeId}`);
  };

  const onClickLink = function (source, target) {
    window.alert(`Clicked link between ${source} and ${target}`);
  };

  return (
    <div className="DashboardProfessional_container">
      {/* <PageInit /> */}
      {/* <h2>Profesional</h2> */}
      <h1>Dashboard</h1>
      <div className="panel">
        <div className="title">
          <h4>Acciones Rapidas</h4>{" "}
          <div className="mobile" onClick={()=>{setOpenActions(!OpenActions)}}>
            {OpenActions ? (
              <ExpandLessIcon fontSize="large"></ExpandLessIcon>
            ) : (
              <ExpandMoreIcon fontSize="large"></ExpandMoreIcon>
            )}
          </div>
        </div>
        <div className={`panel_container${OpenActions ? '__open' : '__close'}`}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/CalendarCenter");
            }}
          >
            Agenda
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/FicheroClinico");
            }}
          >
            Fichero clinico
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/inventario");
            }}
          >
            Inventario
          </Button>
        </div>
      </div>
      {/* <div className="section_graph">

        <PieChart width={200} height={200}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            blendStroke={true}
            isAnimationActive={true}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                
              />
            ))}
          </Pie>
        </PieChart>
        {DataView.name !== "" ? (
          <div
            style={{
              borderColor: DataView.color,
              borderWidth: "1px",
              borderStyle: "solid",
              height: "100px",
              width: "100%",
            }}
          >
            <h5>{DataView.name}</h5>
            <h4>{DataView.value}</h4>
          </div>
        ) : null}
      </div> */}
      <div className="citas_container">
        <div className="citas_table"></div>
        <CollapsibleTable></CollapsibleTable>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  setUserAction: (data) => dispatch(setUserAction(data)),
  getUserAction: () => dispatch(getUserAction),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardProfessional);
