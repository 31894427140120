import React, { useState } from "react";
import { connect } from "react-redux";
import { getUserAction, setUserAction } from "../User/userAction";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";

import "./Register.scss";
import img_vet from "./vet.jpeg";
import { Loader } from "../Components/Loader/Loader";
import { IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { FormCliente } from "./FormCliente/FormCliente";
import { FormClinica } from "./FormClinica/FormClinica";
import { FormTienda } from "./FormTienda/FormTienda";
import { FormProfesional } from "./FormProfesional/FormProfesional";
const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    width: "100%",
    maxWidth: "400px",
    //display: "block",
  },
  inputs: {
    display: "block",
    width: "100%",
    maxWidth: "400px",
  },
  buttonRegister: {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    marginTop: "3rem",
  },
  buttonSecondary: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    marginTop: "3rem",
  },
  // textField: {
  //     marginLeft: theme.spacing(1),
  //     marginRight: theme.spacing(1),
  //     width: '100%',
  //   },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const tipos = [
  { id: 0, value: "Cliente" },
  { id: 1, value: "Profesional" },
  { id: 2, value: "Proveedor" },
];
export const Register = (props) => {
  const [TypeUser, setTypeUser] = useState(-1);
  const [email, setemail] = useState("");
  const [Rut, setRut] = useState("");
  const [pass, setpass] = useState("");
  const [nombre, setnombre] = useState("");
  const [tipo, settipo] = useState("");
  const [apellido, setapellido] = useState("");
  const classes = useStyles();
  const [ShowForm, setShowForm] = useState(true);
  const [Error, setError] = useState({});
  const [PasswordShow, setPasswordShow] = useState({ showPassword: false });
  const [ShowImage, setShowImage] = useState(true);
  let history = useHistory();

  const handleChangeEmail = (event) => {
    setemail(event.target.value);
  };
  const handleChangeNombre = (event) => {
    setnombre(event.target.value);
  };
  const handleChangeApellido = (event) => {
    setapellido(event.target.value);
  };
  const handleChangeTipo = (event) => {
    settipo(event.target.value);
  };
  const handleChangePass = (event) => {
    setpass(event.target.value);
  };
  const handleChangeRut = (event) => {
    //validar Rut
    setRut(event.target.value);
  };
  const handleClickShowPassword = () => {
    setPasswordShow({ showPassword: !PasswordShow.showPassword });
    setpass(pass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = async () => {
    //console.log(TypeUser);
    setShowForm(false);
  };
  const handleShowForm = () => {
    setShowImage(false);
  };
  const goLogin = () => {
    history.push("/");
  };
  const handleChangeTypeUser = (e) => {
    //console.log("e", e.target.value);
    setTypeUser(e.target.value);
  };
  return (
    <div
      className="registerEmail"
      style={{
        height:
          window.innerWidth > 991 ? `${window.innerHeight - 64}px` : `${70}vh`,
      }}
    >
      <div
        className="registerEmail--container"
        style={{
          height:
            window.innerWidth > 991
              ? `${window.innerHeight - 64}px`
              : `${70}vh`,
        }}
      >
        {ShowForm ? (
          <div className="registerEmail--container__select-type">
            <h1>
              Hola, bienvenido a <strong>mipetnow</strong>!
            </h1>
            <span className="registerEmail--container__select-type_select">
              <h1>Soy</h1>

              <FormControl
                variant="outlined"
                className="registerEmail--container__select-type_select_selector"
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Tipo de usuario
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={TypeUser}
                  onChange={handleChangeTypeUser}
                  label="Tipo de usuario"
                >
                  <MenuItem value={-1}>
                    <em> uno de estos usuarios</em>
                  </MenuItem>
                  <MenuItem value={0}>Dueño de mascotas</MenuItem>
                  <MenuItem value={1}>Profesional</MenuItem>
                  <MenuItem value={2}>Clinica</MenuItem>
                  <MenuItem value={3}>Tienda</MenuItem>
                  <MenuItem value={4}>Estetica</MenuItem>
                </Select>
              </FormControl>
            </span>
            <div className="registerEmail--container__select-type_select_options">
              <Button
                variant="contained"
                disabled={TypeUser < 0}
                onClick={() => handleSubmit()}
                fullWidth
              >
                Siguiente
              </Button>
            </div>
          </div>
        ) : (
          <>
            {!ShowForm && TypeUser === 0 ? (
              <FormCliente
                handleShowForm={handleShowForm}
                setTypeUser={setTypeUser}
                Error={Error}
              ></FormCliente>
            ) : null}
            {!ShowForm && TypeUser === 2 ? (
              <FormClinica
                handleShowForm={handleShowForm}
                setTypeUser={setTypeUser}
                Error={Error}
              ></FormClinica>
            ) : null}
            {!ShowForm && TypeUser === 1 ? (
              <FormProfesional
                handleShowForm={handleShowForm}
                setTypeUser={setTypeUser}
                Error={Error}
              ></FormProfesional>
            ) : null}
            {!ShowForm && TypeUser === 3 ? (
              <FormTienda
                handleShowForm={handleShowForm}
                setTypeUser={setTypeUser}
                Error={Error}
              ></FormTienda>
            ) : null}
          </>
        )}
        <>
          {" "}
          {ShowImage ? (
            <div className="img-promo">
              <img
                src={"https://i.ibb.co/6vvb6F3/vet.jpg"}
                style={{ objectFit: "cover", objectPosition: "right" }}
              ></img>
            </div>
          ) : null}{" "}
        </>
      </div>
      {/* ) : TypeUser === 2 ? (
        <div className="form-clinica">
          <h1>Felicitaciones!</h1>
          <h2>
            Te acabas de registrar en la red social para mascotas más grande de
            chile!
          </h2>
          <div className="importante">
            <p>
              Puedes iniciar sesion, agregar fotos a tu galeria, y configurar tu
              perfil, el cual sera publico posterior a validar la veracidad de
              tus datos.
            </p>
          </div>
          <div className="info">
            <p>
              No olvides escribirnos a{" "}
              <strong>hello@nowcompanyglobal.com</strong> si tienes alguna duda,
              queda, idea, ayuda, lo que sea, intentaremos apoyarte.
            </p>
          </div>
          <div className="options">
            <Button variant="outlined" onClick={goLogin}>
              Iniciar Sesion
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <h1>Felicitaciones!</h1>
          <h2>
            Te acabas de registrar en la red social para mascotas más grande!
          </h2>
          <h3>
            No olvides escribirnos a <strong>hello@nowcompanyglobal.com</strong>{" "}
            si tienes alguna duda, queda, idea, ayuda, lo que sea, intentaremos
            apoyarte ;
          </h3>
        </div>
      )} */}
    </div>
  );
};
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  setUserAction: (data) => dispatch(setUserAction(data)),
  getUserAction: () => dispatch(getUserAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
