import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory, withRouter } from "react-router-dom";

import "./CrearSucursal.scss";
const CrearSucursal = (props) => {
  let history = useHistory();
  const [User, setUser] = useState(() => {
    return { ...props.user };
  });
  const [Direction, setDirection] = useState("");
  const [NombreSucursal, setNombreSucursal] = useState("");
  const [Validado, setValidado] = useState(false);
  const [EmailSucursal, setEmailSucursal] = useState('')
  const [UrlDir, setUrlDir] = useState("");
  const refCol = useRef(null);
  const handleChangeDirection = (e) => {
    setDirection(e.target.value);
    setUrlDir(
      `https://www.google.com/maps/embed/v1/place?key=AIzaSyAmCuGdeXjLuB1tANy3BjVfxBeJlNtNF6s&q=${Direction}`
    );
  };
  const handleChangeNombreSucursal = (e) => {
    setNombreSucursal(e.target.value);
  };
  const handleChangeEmailSucursal = (e) => {
    setEmailSucursal(e.target.value);
  };
  const handleChangeValidado = (e) => {
    setValidado(!Validado);
  };
  const dynamicMap = () => {
    if (Direction === "") return;
    //console.log(refCol);
    const w = refCol.current.offsetWidth;
    const h = refCol.current.offsetHeight;
    return (
      <iframe
        width={w}
        height="200"
        style={{ border: 0 }}
        loading="lazy"
        allowFullScreen
        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAmCuGdeXjLuB1tANy3BjVfxBeJlNtNF6s&q=${Direction}`}
      ></iframe>
    );
  };

  const handleClickCrear = async () => {
    const url = " https://evssnnfx32.execute-api.us-west-2.amazonaws.com/dev";
    const payload = {
      email: props.user.email,
      email_sucursal:EmailSucursal,
      nombre: NombreSucursal,
      direccion: Direction,
    };
    await fetch(url, {
      method: "POST",

      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (data) => {
        data = JSON.parse(data.body);
        //console.log(data);
        const user = {
          ...User,
          sucursales: [
            ...props.user.sucursales,
            {
              nombre: data.sucursalCreada.nombre,
              direccion: data.sucursalCreada.direccion,
              uuid: data.sucursalCreada.uuid,
              email:data.sucursalCreada.email
            },
          ],
          cantidadSucursales: data.cantidadSucursales,
        };
        props.setUserAction({ isLogin: true, ...user });
        localStorage.setItem("user", JSON.stringify(user));
        history.push("/sucursales");
      });
  };
  const handleClickBack = () => {
    history.goBack();
  }
  return (
    <div className="crear-sucursal">
      <div className="crear-sucursal_title">
        <h1>Registra aqui tus sucursales</h1>
        <p>Tienes {props.user.sucursales.length}/3 sucursales</p>
      </div>
      <div className="crear-sucursal_content">
        <Paper elevation={3} className="paper">
          <div className="form">
            <div className="col-1" ref={refCol}>
              <TextField
                className="input-form"
                id="outlined-basic"
                label="Nombre Sucursal"
                variant="outlined"
                fullWidth
                onChange={(e) => handleChangeNombreSucursal(e)}
              />
              <TextField
                className="input-form"
                id="outlined-basic"
                label="Email Sucursal"
                variant="outlined"
                fullWidth
                onChange={(e) => handleChangeEmailSucursal(e)}
              />
              <TextField
                className="input-form"
                id="outlined-basic"
                label="Dirección"
                variant="outlined"
                fullWidth
                onChange={(e) => handleChangeDirection(e)}
              />
              <Button
                onClick={handleChangeValidado}
                className="button"
                variant="contained"
                color="primary"
                fullWidth
                disabled={Direction === ""}
              >
                Mostrar mapa
              </Button>
              {Validado ? <div>{dynamicMap()}</div> : null}
            </div>
            <div className="col-2"></div>
          </div>
          <div className="options">
            <Button
              className="button"
              variant="outlined"
              color="primary"
              onClick={() => {
                handleClickBack()
              }}
            >
              Cencelar
            </Button>
            <Button
              className="button"
              variant="contained"
              color="primary"
              onClick={handleClickCrear}
            >
              Crear
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default CrearSucursal;
