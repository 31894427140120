import "date-fns";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import React, { useState } from "react";
import "./CalendarPage.scss";
import { Provider } from "react-redux";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Calendar } from "../../../Components/Calendar/Calendar";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import rootReducer from "./reducer";
import { DemoApp } from "../../../Components/Calendar/DemoApp";

// let store = createStore(rootReducer, applyMiddleware(thunk));

const CalendarPage = (props) => {
  const [User, setUser] = useState(() => {
    return { ...props.user };
  });
  const [Sucursal, setSucursal] = useState(-1);
  const [Empleado, setEmpleado] = useState(-1);
  const handleChangeSucursal = (event) => {
    setSucursal(event.target.value);
  };
  const [selectedDate, setSelectedDate] = React.useState(
    new Date()
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [dateSelected, setdateSelected] = useState(null)

  return (
    <div className="calendar-page-container">
      <div className="calendar-page-container__title">
        <h2>Tus Agendas</h2>
      </div>
      <div className="calendar-page-container__content">
        <Paper elevation={3} className="paper">
          <div className="select_sucursal">
            Selecciona una sucursal
            <FormControl variant="outlined" className="sucursal">
              <InputLabel id="demo-simple-select-outlined-label">
                Sucursal
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={Sucursal}
                onChange={handleChangeSucursal}
                label="Sucursal"
              >
                {User.sucursales.map((sucursal, i) => {
                  return <MenuItem value={i}>{sucursal.nombre}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>
        </Paper>
        <Paper elevation={3} className="paper">
          <div className="select_profesional">
            Selecciona un(a) profesional
            <FormControl variant="outlined" className="profesional">
              <InputLabel id="demo-simple-select-outlined-label">
                Profesional
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={Empleado}
                onChange={handleChangeSucursal}
                label="Profesional"
                disabled={Sucursal === ""}
              >
                {User.empleados.map((empleado, i) => {
                  return <MenuItem value={i}>{empleado.nombre}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </div>
        </Paper>
        <Paper elevation={3} className="paper">
          <div className="vista-agendas">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justifyContent="space-around">
                {/* <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date picker inline"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        /> */}
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date picker dialog"
                  format="MM/dd/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  views={'month'}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
                {/* <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label="Time picker"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        /> */}
              </Grid>
            </MuiPickersUtilsProvider>
           <Calendar></Calendar>
          </div>
        </Paper>
      </div>
      <div className="calendar-page-container__options"></div>
    </div>
  );
};

export default CalendarPage;
