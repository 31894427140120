import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import './ButtonPetAdd.scss';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
/**
 * @name ButtonPetAdd
 * @param {*} props 
 */
const ButtonPetAdd = (props) => {
    return (
        <div className="ButtonPetAdd_container"> 
           <Button><AddIcon></AddIcon> Agregar</Button>
        </div>
    )
}

ButtonPetAdd.propTypes = {

}

export default ButtonPetAdd
