import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProviderCard from "../../Components/ProviderCard/ProviderCard";
import { Header } from "../../Header/Header";
import "./PageInit.scss";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import Dashboard from "../Dashboard/Dashboard";
import {
  getUserAction,
  setProfesionalAction,
  setUserAction,
} from "../../User/userAction";
import { getProfessionalsProfilesData } from "./PageInitService";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { useHistory } from "react-router-dom";

import {
  getProfessionalProfilesAction,
  setProfessionalProfilesAction,
} from "./PageInitAction";
import { Loader } from "../../Components/Loader/Loader";
import { Button } from "@material-ui/core";
import CardPromo from "../../Components/CardPromo/CardPromo";
import CardClinicaDestacada from "../../Components/CardClinicaDestacada/CardClinicaDestacada";
import CardTiendaDestacada from "../../Components/CardTiendaDestacada/CardTiendaDestacada";

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  getUserAction: () => dispatch(getUserAction),
  setUserAction: (data) => dispatch(setUserAction(data)),
  getProfessionalProfilesAction: () => dispatch(getProfessionalProfilesAction),
  setProfessionalProfilesAction: (data) =>
    dispatch(setProfessionalProfilesAction(data)),
});

export const PageInit = (props) => {
  let history = useHistory();
  const user = useSelector(state => state.user)
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(true);
  const [ShowView, setShowView] = useState(false)
  const [Professionals, setProfessionals] = useState([]);
  const [spacing, setSpacing] = React.useState(4);
  useEffect(() => {
    //console.log('is loading : ',isLoading)
    if(user.type==="2"){
      history.push("/dashboard-clinica");
      setisLoading(false);
      return;
    }
    setShowView(true);
    setisLoading(false)
  }, [user])
  const promos = [
    {
      id: 0,
      title: "Promocion A",
      content: "Promocion A : 30% dcto",
      tienda: "Tienda A",
    },
    {
      id: 1,
      title: "Promocion B",
      content: "Promocion B : 40% dcto",
      tienda: "Tienda B",
    },
    {
      id: 2,
      title: "Promocion C",
      content: "Promocion C : 50% dcto",
      tienda: "Tienda C",
    },
  ];
  const clinicas_destacadas = [
    {
      id: 0,
      title: "Clinica A",
      content: "Promocion A : 30% dcto",
      tienda: "Clinica A",
    },
    {
      id: 1,
      title: "Clinica B",
      content: "Promocion B : 40% dcto",
      tienda: "Clinica B",
    },
    {
      id: 2,
      title: "Clinica C",
      content: "Promocion C : 50% dcto",
      tienda: "Clinica C",
    },
  ];
  const tiendas_destacadas = [
    {
      id: 0,
      title: "Promocion A",
      content: "Promocion A : 30% dcto",
      tienda: "Tienda A",
    },
    {
      id: 1,
      title: "Promocion B",
      content: "Promocion B : 40% dcto",
      tienda: "Tienda B",
    },
    {
      id: 2,
      title: "Promocion C",
      content: "Promocion C : 50% dcto",
      tienda: "Tienda C",
    },
  ];
  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  };

  const onClickPromo = (id, tienda) => {
    //console.log(id);
  };
  return (
    <div className="page-init">
      {isLoading ? <Loader></Loader> : null}
     
      <Dashboard {...props}></Dashboard>
      {isLoading && ShowView && (user.type === 0 || user.isLogin == false) ? (
        <>
          <div>
            <h1>Noticias</h1>
            <div>
              <div className="news_banner">
                <div className="news_banner-content">
                  <h2>Cuidado de tu mascota</h2>
                  <p>lorem ipsum</p>
                  <Button onClick={() => console.log("Ver Más")}>
                    Ver más
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h1>Promociones</h1>
            <div className="promos">
              {promos.map((promo, i) => {
                return (
                  <CardPromo
                    title={promo.title}
                    content={promo.content}
                    id={promo.id}
                    key={i}
                    onClick={() => onClickPromo(promo.id)}
                  ></CardPromo>
                );
              })}
            </div>
          </div>
          <div>
            <h1>Clinicas Destacadas</h1>
            <div className="clinicas_destacadas">
              {clinicas_destacadas.map((promo, i) => {
                return (
                  <CardClinicaDestacada
                    title={promo.title}
                    content={promo.content}
                    id={promo.id}
                    key={i}
                    onClick={() => onClickPromo(promo.id)}
                  ></CardClinicaDestacada>
                );
              })}
            </div>
          </div>
          <div>
            <h1>Tiendas Destacadas</h1>
            <div className="tiendas_destacadas">
              {tiendas_destacadas.map((promo, i) => {
                return (
                  <CardTiendaDestacada
                    title={promo.title}
                    content={promo.content}
                    id={promo.id}
                    key={i}
                    onClick={() => onClickPromo(promo.id)}
                  ></CardTiendaDestacada>
                );
              })}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default PageInit;
