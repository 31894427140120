import React, { useEffect, useState } from "react";
import "./EmpleadosProfesionales.scss";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import {
  Button,
  Fab,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import {
  getProfesionalAction,
  getUserAction,
  setProfesionalAction,
  setUserAction,
} from "../../User/userAction";
import {
  setEmpleadosEditAction,
  setEmpleadosEliminarAction,
} from "./EmpleadosProfesonalesAction";
import CardEmploye from "../../Components/CardEmploye/CardEmploye";

const EmpleadosProfesionales = (props) => {
  let history = useHistory();

  const [User, setUser] = useState(() => {
    return { ...props.user };
  });
  const [openModal, setOpenModal] = useState(false);
  const [Eliminar, setEliminar] = useState("");

  const handleClickGoAddSucursal = () => {
    history.push("/sucursales");
  };

  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleChangeInputEliminar = (e) => {
    // //console.log(e.target.value);
    setEliminar(e.target.value);
  };
  const handleClickAddProfesional = () => {
    // //console.log("go add prof");
    history.push("/empleados/crear");
  };
  const handleClickGoToEditar = (e, row) => {
    e.stopPropagation();
    history.push(`/empleados/editar/${row.uuid}`);
  };
  const handleClickEliminarProfesional = async (e, row) => {
    e.stopPropagation();
    await props.setEmpleadosEliminarAction(row);
    handleOpen();
    //console.log(row);
  };
  const handleClickDetalle = (e, row) => {
    e.stopPropagation();
    history.push(`/empleados/${row.uuid}`);
  };
  const handleClickEliminar = async (e, row) => {
    const url = "https://y4w1yetyxb.execute-api.us-west-2.amazonaws.com/dev";
    var newEmpleados = props.user.empleados;
    let pos = props.user.empleados.findIndex(
      (x) => x.uuid == props.empleadosEliminar.uuid
    );
    newEmpleados = newEmpleados.length <= 1 ? [] : newEmpleados.splice(pos, 1);
    // //console.log(newEmpleados);
    const payload = {
      email: props.user.email,
      empleados: newEmpleados,
    };
    await fetch(url, {
      method: "POST",

      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (data) => {
        data = JSON.parse(data.body);
        // //console.log(data);
        const user = {
          ...User,
          empleados: [...newEmpleados],
          cantidadEmpleados: data.cantidadEmpleados,
        };
        props.setUserAction({ isLogin: true, ...user });
        localStorage.setItem("user", JSON.stringify(user));
        // //console.log(data);
        handleClose();
      });
  };
  const body = (
    <div className="modal-content-eliminar">
      <h1>Eliminar este empleado</h1>
      <h3>
        ¿Seguro quieres <strong>ELIMINAR</strong> este empleado?
      </h3>
      <div>
        <p>
          Para eliminar, escribe eliminar en el cuadro de abajo, luego presiona
          Eliminar.
        </p>
      </div>
      <div>
        <TextField
          className="input-form"
          id="outlined-basic"
          label="Eliminar"
          variant="outlined"
          fullWidth
          onChange={(e) => handleChangeInputEliminar(e)}
        ></TextField>
      </div>
      <div className="date-options">
        <Button variant="outlined" color="secondary">
          Cancelar
        </Button>
        <Button
          disabled={Eliminar.toString().toLowerCase() !== "eliminar"}
          variant="contained"
          color="primary"
          onClick={() => handleClickEliminar()}
        >
          Eliminar
        </Button>
      </div>
    </div>
  );
  return (
    <div className="empleados-profesionales-container">
      {/* <div className="empleados-profesionales_title">
        <h1>Nuestros profesionales</h1>
        <p></p>
      </div> */}
      <div className="empleados-profesionales-container_content">
        {props.user.sucursales.length <= 0 ? (
          <p className="text-alert-no-sucursales">
            No tienes sucursales para agregar profesionales, presiona{" "}
            <strong
              onClick={() => {
                handleClickGoAddSucursal();
              }}
            >
              Aqui
            </strong>{" "}
            para agregar una sucursal
          </p>
        ) : null}
        {window.innerWidth >= 992 ? (
          <>
            <Paper elevation={3} className="paper">
              <div className="empleados-profesionales-container_options">
                <div className="empleados-profesionales-container_title">
                  <h1>Empleados</h1>
                </div>
                <Fab
                  color="primary"
                  aria-label="add"
                  onClick={() => {
                    handleClickAddProfesional();
                  }}
                  disabled={props.user.sucursales.length <= 0 ? true : false}
                >
                  <AddOutlinedIcon />
                </Fab>
              </div>
              <div className="empleados-profesionales-container_content--table">
                <TableContainer component={Paper}>
                  <Table
                    className="table"
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">ID</TableCell>
                        <TableCell align="left">Nombre</TableCell>
                        <TableCell align="left">Correo</TableCell>

                        <TableCell align="left">Direccion</TableCell>
                        <TableCell align="center">Editar</TableCell>
                        <TableCell align="center">Eliminar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.user.empleados.map((row, i) => (
                        <TableRow
                          key={row.uuid}
                          onClick={(e) => handleClickDetalle(e, row)}
                          className="table-row"
                        >
                          <TableCell align="left">
                            <p>{row.uuid}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p>{row.name + " " + row.lastName}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p>{row.email}</p>
                          </TableCell>
                          <TableCell align="left">
                            <p>{row.direccion}</p>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={(e) => handleClickGoToEditar(e, row)}
                            >
                              Editar
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={(e) =>
                                handleClickEliminarProfesional(e, row)
                              }
                            >
                              Eliminar
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className="modal-calendar-add"
              >
                {body}
              </Modal>
            </Paper>
          </>
        ) : (
          <>
            <Paper elevation={3} className="paper">
              <div className="empleados-profesionales-container_options">
                <div className="empleados-profesionales-container_title">
                  <h1>Empleados</h1>
                </div>
                <Fab
                  color="primary"
                  aria-label="add"
                  onClick={() => {
                    handleClickAddProfesional();
                  }}
                  disabled={props.user.sucursales.length <= 0 ? true : false}
                >
                  <AddOutlinedIcon />
                </Fab>
              </div>
            </Paper>
            <div className="container-cards">
              {props.user.empleados.map((empleado, i) => (
                <CardEmploye empleado={empleado} index={i} key={i}></CardEmploye>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserAction: () => dispatch(getUserAction),
    setUserAction: (data) => dispatch(setUserAction(data)),
    getProfesionalAction: () => dispatch(getProfesionalAction),
    setProfesionalAction: (data) => dispatch(setProfesionalAction(data)),
    setEmpleadosEditAction: (data) => dispatch(setEmpleadosEditAction(data)),
    setEmpleadosEliminarAction: (data) =>
      dispatch(setEmpleadosEliminarAction(data)),
  };
};

const mapStateToProps = (state) => {
  return state;
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(EmpleadosProfesionales);
