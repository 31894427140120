import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './Blog.scss';

const BlogPage = ({ ...props }) => {
  
  return (
    <>
     <HelmetProvider> 
        <Helmet>
        <title>MiPetnow - Blog</title>
        <meta
          name="keywords"
          content="Content"
        />
        <meta
          name="description"
          content="Description"
        />
       </Helmet> 
      <div className="blog">
        
        <div className="blog__content">
          Blog
        </div>
      </div>
     </HelmetProvider>
    </>
  );
};

export default BlogPage;
