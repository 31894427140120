import React, { useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import ReactDOM from "react-dom";
import { useHistory, withRouter } from "react-router-dom";

import "./CrearEmpleado.scss";
import { Visibility, VisibilityOff } from "@material-ui/icons";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
  };
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const genders = [
  { id: "M", value: "Masculino" },
  { id: "F", value: "Femeninio" },
  { id: "O", value: "Otro" },
];
const CrearEmpleado = (props) => {
  // //console.log(props);
  let history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const [User, setUser] = useState(() => {
    return { ...props.user };
  });
  const [Nombre, setNombre] = useState("");
  const [Apellidos, setApellidos] = useState("");
  const [Email, setEmail] = useState("");
  const [Direccion, setDireccion] = useState("");
  const [Rut, setRut] = useState("");
  const [Contacto, setContacto] = useState("");
  const [especialidadesSelect, setEspecialidadesSelect] = useState([]);
  const [PasswordShow, setPasswordShow] = useState({ showPassword: false });
  const [pass, setpass] = useState("");
  const [Sucursal, setSucursal] = useState("");
  const [Genero, setGenero] = useState(-1);
  const [UrlDir, setUrlDir] = useState("");
  const refCol = useRef(null);
  const [SucursalObject, setSucursalObject] = useState("");
  //#region hanlders
  const handleClickCrear = async () => {
    //obtener objeto especialidades
    //recorrer especialidades seleccionadas
    //por cada especialidadSeleccionada
    var espObj = [];
    especialidadesSelect.forEach((esp) => {
      especialidades.find((especialidad) => {
        // //console.log(especialidad.value);
        // //console.log(esp);
        if (especialidad.value === esp) {
          // //console.log("encontrado");
          espObj.push(especialidad);
          return;
        }
      });
    });
    // //console.log(espObj);
    const url = "https://9gndq8m1qg.execute-api.us-west-2.amazonaws.com/dev";
    const payload = {
      email: Email,
      name: Nombre,
      lastName: Apellidos,
      rut: Rut,
      disabled: true,
      emailVerified: false,
      gender: genders[Genero].id,
      type: "1",
      application: "mipetnow",
      sucursal: SucursalObject.nombre,
      email_sucursal: User.email,
      clinica_name: User.nameClinica,
      empleados: [],
      especialidades: espObj,
    };
    // //console.log(payload);
    await fetch(url, {
      method: "POST",

      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (data) => {
        data = JSON.parse(data.body);
        const user = {
          ...User,
          empleados: [
            ...props.user.empleados,
            {
              name: data.empleadoSucursal.name,
              lastName: data.empleadoSucursal.lastName,
              email: data.empleadoSucursal.email,
              email_sucursal: data.empleadoSucursal.email_sucursal,
              uuid: data.empleadoSucursal.uuid,
              especialidades: espObj,
            },
          ],
          cantidadEmpleados: data.cantidadEmpleados,
        };
        props.setUserAction({ isLogin: true, ...user });
        localStorage.setItem("user", JSON.stringify(user));
        history.push("/empleados");
        //history.goBack();
      });
  };
  const handleClickBack = () => {
    history.goBack();
  };
  const handleDelete = async (especialidad) => {
    // console.info("especialidad : ", especialidad);
    var tempEspecialidades = [...especialidadesSelect];
    let pos = especialidadesSelect.findIndex((x) => x === especialidad);
    // //console.log("pos :", pos);
    // //console.log("tempEspecialidades : ", tempEspecialidades);
    // //console.log(
    //   "tempEspecialidades[pos] : : ",
    //   tempEspecialidades[especialidad]
    // );

    tempEspecialidades.splice(especialidad, 1);
    // //console.log("tempEspecialidades : ", tempEspecialidades);

    await setEspecialidadesSelect(tempEspecialidades);
  };
  const handleChangeEspecialidades = (event) => {
    setEspecialidadesSelect(event.target.value);
  };
  const especialidades = [
    { id: 0, value: "Perros y gatos" },
    { id: 1, value: "Vacunas" },
    { id: 2, value: "Exoticos" },
    { id: 3, value: "Especialidades" },
    { id: 4, value: "Examenes de sangre" },
    { id: 5, value: "Imagenologia" },
  ];

  const handleClickShowPassword = () => {
    setPasswordShow({ showPassword: !PasswordShow.showPassword });
    setpass(pass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChangePass = (event) => {
    setpass(event.target.value);
  };
  const handleChangeSucursal = (event) => {
    setSucursal(event.target.value);
  };
  const handleChangeObjectSucursal = (index) => {
    // //console.log(User.sucursales[index]);
    setSucursalObject(User.sucursales[index]);
  };
  const handleChangeGenero = (event) => {
    setGenero(event.target.value);
  };
  const handleChangeNombre = (event) => {
    setNombre(event.target.value);
  };
  const handleChangeApellidos = (event) => {
    setApellidos(event.target.value);
  };
  const handleChangeRut = (event) => {
    setRut(event.target.value);
  };
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeDireccion = (event) => {
    setDireccion(event.target.value);
  };
  const handleChangeContacto = (event) => {
    setContacto(event.target.value);
  };
  //#endregion
  return (
    <div className="crear-empleado">
      <div className="crear-empleado_content">
        <Paper elevation={3} className="paper">
          {/* <h2>Datos</h2> */}
          <div className="crear-empleado_title">
            <h1>Nuevo profesional !</h1>
            <p>Tienes {props.user.empleados.length}/3 Profesionales</p>
          </div>
          <div className="form_parent">
            {/* <div className="col-1" ref={refCol}> */}
            <div className="form_parent__content--nombre">
              <TextField
                className="input-form"
                id="outlined-basic"
                label="Nombre"
                variant="outlined"
                fullWidth
                onChange={handleChangeNombre}
              />
            </div>
            <div className="form_parent__content--apellidos">
              <TextField
                className="input-form"
                id="outlined-basic"
                label="Apellidos"
                variant="outlined"
                fullWidth
                onChange={handleChangeApellidos}
              />
            </div>

            <div className="form_parent__content--email">
              <TextField
                className="input-form"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                fullWidth
                onChange={handleChangeEmail}
              />
            </div>
            <div className="form_parent__content--rut">
              <TextField
                className="input-form"
                id="outlined-basic"
                label="Rut"
                variant="outlined"
                fullWidth
                onChange={handleChangeRut}
              />
            </div>
            <div className="form_parent__content--direccion">
              {" "}
              <TextField
                className="input-form"
                id="outlined-basic"
                label="Dirección"
                variant="outlined"
                fullWidth
                onChange={handleChangeDireccion}
              />
            </div>
            {/* <div className="form_parent__content--password">
              {" "}
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={PasswordShow.showPassword ? "text" : "password"}
                  value={pass}
                  onChange={handleChangePass}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {PasswordShow.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </div> */}
            <div className="form_parent__content--contacto">
              {" "}
              <TextField
                className="input-form"
                id="outlined-basic"
                label="Numero Contacto"
                variant="outlined"
                fullWidth
                onChange={handleChangeContacto}
              />
            </div>
            <div className="form_parent__content--sucursal">
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Sucursal
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={Sucursal}
                  onChange={handleChangeSucursal}
                  label="Sucursal"
                >
                  {User.sucursales.map((sucursal, i) => {
                    return (
                      <MenuItem
                        value={i}
                        onClick={() => handleChangeObjectSucursal(i)}
                      >
                        {sucursal.nombre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>

            <div className="form_parent__content--genero">
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Genero
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={Genero}
                  onChange={handleChangeGenero}
                  label="Sucursal"
                >
                  {genders.map((genero, i) => {
                    return <MenuItem value={i}>{genero.value}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </div>
            {/* <div className="form_parent__content"></div>
            <div className="form_parent__content"></div>
            <div className="form_parent__content"></div>
            <div className="form_parent__content"></div> */}

            {/* </div> */}
            {/* <div className="col-2"> */}
          </div>
          {/* </div> */}
          <div className="container_select">
            <p>
              Aquí debes seleccionar todas las especialidades que ejercera el
              profesional en el centro, estas seran visibles por los clientes
              que deseen agendar
            </p>
            <div className="select">
              <FormControl
                variant="outlined"
                className="select-especialidades"
                variant="outlined"
              >
                <InputLabel id="demo-mutiple-name-label">
                  Selecciona las especialidades
                </InputLabel>
                <Select
                  labelId="demo-mutiple-name-label"
                  id="demo-mutiple-name"
                  multiple
                  value={especialidadesSelect}
                  onChange={handleChangeEspecialidades}
                  input={<Input />}
                  MenuProps={MenuProps}
                >
                  {especialidades.map((esp) => (
                    <MenuItem
                      key={esp.id}
                      value={esp.value}
                      style={getStyles(esp.value, especialidadesSelect, theme)}
                    >
                      {esp.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="bubbles">
              {especialidadesSelect.map((esp, i) => {
                return (
                  <Chip
                    label={esp}
                    key={i}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                    }}
                    onDelete={() => handleDelete(i)}
                    className="bubble"
                  />
                );
              })}
            </div>
          </div>
          <div className="options">
            <Button
              className="button"
              variant="outlined"
              color="primary"
              onClick={() => {
                handleClickBack();
              }}
            >
              Cencelar
            </Button>
            <Button
              className="button"
              variant="contained"
              color="primary"
              onClick={handleClickCrear}
            >
              Crear
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default CrearEmpleado;
