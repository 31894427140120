import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./WorkingProgress.scss";
import gatowip from "./gatowip.gif";
/**
 * @name WorkingProgress
 * @param {*} props
 */
const WorkingProgress = (props) => {
  return (
    <div className="WorkingProgress_container">
      <h1>
        Hey! estamos trabajando para que pronto tengas más y mejores beneficios!
      </h1>
      <div className="content-img">
        <img src={gatowip}></img>
      </div>
    </div>
  );
};

WorkingProgress.propTypes = {};

export default WorkingProgress;
