import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./CardDefault.scss";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
/**
 * @name CardDefault
 * @param {*} props
 */
const CardDefault = (props) => {
    
  return (
    <div className="carddefault_container">
      <Card >
        <CardContent>
          {props.children}
        </CardContent>
        <CardActions>
        </CardActions>
      </Card>
    </div>
  );
};

CardDefault.propTypes = {};

export default CardDefault;
