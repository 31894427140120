import React, { useEffect, useState } from "react";
import "./Sucursales.scss";
import {
  Button,
  Fab,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { useHistory, withRouter } from "react-router-dom";
import { getUserNormalizer } from "../../services/user";
import {
  getProfesionalAction,
  getUserAction,
  setProfesionalAction,
  setUserAction,
} from "../../User/userAction";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  setSucursalEditAction,
  getSucursalEliminarAction,
  setSucursalEliminarAction,
} from "./SucursalAction";



const Sucursales = (props) => {
  let history = useHistory();
  const [User, setUser] = useState(() => {
    return { ...props.user };
  });
  const [NewRows, setNewRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [Eliminar, setEliminar] = useState("");
  const handleChangeInputEliminar = (e) => {
    //console.log(e.target.value);
    setEliminar(e.target.value);
  };
  const handleClickEliminar = async () => {
    const url = "https://y4w1yetyxb.execute-api.us-west-2.amazonaws.com/dev";
    var newSucursales = props.user.sucursales;
    let pos = props.user.sucursales.findIndex(
      (x) => x.uuid == props.sucursalEliminar.uuid
    );
    newSucursales =
      newSucursales.length <= 1 ? [] : newSucursales.splice(pos, 1);
    //console.log(newSucursales);
    const payload = {
      email: props.user.email,
      sucursales: newSucursales,
    };
    await fetch(url, {
      method: "POST",

      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then(async (data) => {
        data = JSON.parse(data.body);
        //console.log(data);
        const user = {
          ...User,
          sucursales: [...newSucursales],
          cantidadSucursales: data.cantidadSucursales,
        };
        props.setUserAction({ isLogin: true, ...user });
        localStorage.setItem("user", JSON.stringify(user));
        //console.log(data);
        handleClose();
      });
  };
  const body = (
    <div className="modal-content-eliminar">
      <h1>Eliminar Sucursal</h1>
      <h3>
        ¿Seguro quieres <strong>ELIMINAR</strong> esta sucursal?
      </h3>
      <div>
        <p>
          Para eliminar, escribe eliminar en el cuadro de abajo, luego presiona
          Eliminar.
        </p>
      </div>
      <div>
        <TextField
          className="input-form"
          id="outlined-basic"
          label="Eliminar"
          variant="outlined"
          fullWidth
          onChange={(e) => handleChangeInputEliminar(e)}
        ></TextField>
      </div>
      <div className="date-options">
        <Button variant="outlined" color="secondary">
          Cancelar
        </Button>
        <Button
          disabled={Eliminar.toString().toLowerCase() !== "eliminar"}
          variant="contained"
          color="primary"
          onClick={() => handleClickEliminar()}
        >
          Eliminar
        </Button>
      </div>
    </div>
  );

  const handleOpen = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };
  const handleClickAddSucursal = () => {
    history.push("/sucursales/crear");
  };
  const handleClickGoToEditar = async (row) => {
    //console.log(row);
    await props.setSucursalEditAction(row);
    history.push(`/sucursales/editar/${row.uuid}`);
  };
  const handleClickEliminarSucursal = async (row) => {
    await props.setSucursalEliminarAction(row);
    handleOpen();
    //console.log(row);
  };
  return (
    <div className="sucursales-container">
      <div className="sucursales-container_options">
        <div className="sucursales-container_title">
          <h1>Tus sucursales</h1>
        </div>
        <Fab color="primary" aria-label="add" onClick={handleClickAddSucursal}>
          <AddOutlinedIcon />
        </Fab>
      </div>
      <div className="sucursales-container_content">
        <TableContainer component={Paper}>
          <Table className="table" size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">ID</TableCell>
                <TableCell align="left">Nombre</TableCell>
                <TableCell align="left">Direccion</TableCell>
                <TableCell align="center">Editar</TableCell>
                <TableCell align="center">Eliminar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.user.sucursales.map((row, i) => (
                <TableRow key={row.uuid}>
                  <TableCell align="left">{row.uuid}</TableCell>
                  <TableCell align="left">{row.nombre}</TableCell>
                  <TableCell align="left">{row.direccion}</TableCell>
                  <TableCell align="center">
                    <Button onClick={() => handleClickGoToEditar(row)}>
                      Editar
                    </Button>
                  </TableCell>
                  <TableCell align="center">
                    <Button onClick={() => handleClickEliminarSucursal(row)}>
                      Eliminar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className="modal-calendar-add"
      >
        {body}
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserAction: () => dispatch(getUserAction),
    setUserAction: (data) => dispatch(setUserAction(data)),
    getProfesionalAction: () => dispatch(getProfesionalAction),
    setProfesionalAction: (data) => dispatch(setProfesionalAction(data)),
    setSucursalEditAction: (data) => dispatch(setSucursalEditAction(data)),
    setSucursalEliminarAction: (data) =>
      dispatch(setSucursalEliminarAction(data)),
  };
};

const mapStateToProps = (state) => {
  return state;
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Sucursales);
