import axios from "axios";

export const uploadImage = async ({
  _id,
  image,
  folder,
  description,
  attr,
}) => {
  const formData = new FormData();
  formData.append("file", image);
  formData.append("description", description);
  return await axios
    .post(
      `https://iw0wcot8bj.execute-api.us-west-2.amazonaws.com/dev?id=${_id}&folder=${folder}&attr=${attr}`,
      formData,
      { headers: { "Content-Type": "multipart/form-data" } }
    )
    .then((res) => {
      //console.log("File success");
      // //console.log(res);
      return true;
    })
    .catch((err) => {
      alert("Oops! Algo ha ocurrido, intentalo otra vez!");
      return false;
    });
};
