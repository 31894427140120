import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom:30
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 4,
  },
  cover: {
    width: 151,
  },
});

export default function CardEmploye(props) {
  //console.log(props);
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const { empleado } = props;
  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {empleado.name + ' ' + empleado.lastName}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {empleado.email}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {empleado.uuid}
          </Typography>
        </CardContent>
      </div>
      <CardMedia
        className={classes.cover}
        image="/static/images/cards/live-from-space.jpg"
        title="Live from space album cover"
      />
    </Card>
  );
}
