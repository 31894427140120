import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import promiseMiddleware from '../../services/promiseMiddleware';


const middlewareBuilder = () => {

  let middleware = {};  
  let universalMiddleware = [thunk, promiseMiddleware];
  let allComposeElements = [];

  middleware = applyMiddleware(...universalMiddleware);
  allComposeElements = [
    middleware
  ];

  return allComposeElements;

};

// falta validacion para ocultar en production
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const finalCreateStore = composeEnhancers(...middlewareBuilder())(createStore);

export default function configureStore(initialState) {
  const store = finalCreateStore(rootReducer, initialState);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}