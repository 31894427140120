export const loginAPI = (type) => {
  if (process.env.NODE_ENV === "development") {
    return "https://4xoiftli8j.execute-api.us-west-2.amazonaws.com/dev";
  }
  if (process.env.NODE_ENV === "production") {
    return "https://4xoiftli8j.execute-api.us-west-2.amazonaws.com/prod";
    // return "http://localhost:3009/api/users/login";
  }
};
export const loginTokenAPI = (type) => {
  const t = {
    0: "users",
    1: "profesionals",
    2: "clinicas",
    3: "tiendas",
    4: "esteticas",
  };
  if (process.env.NODE_ENV === "development") {
    // return `http://localhost:3009/api/${t[type]}/login-token`;
    switch (type) {
        case 0:
          return "https://jlxg724igb.execute-api.us-west-2.amazonaws.com/dev";
        case 2:
          return "https://6wlcn0wy83.execute-api.us-west-2.amazonaws.com/dev";
        default:
          break;
      }
  }
  if (process.env.NODE_ENV === "production") {
    switch (type) {
      case 0:
        return "https://jlxg724igb.execute-api.us-west-2.amazonaws.com/dev";
      case 2:
        return "https://6wlcn0wy83.execute-api.us-west-2.amazonaws.com/dev";
      default:
        break;
    }
    // return "https://4xoiftli8j.execute-api.us-west-2.amazonaws.com/prod";
    // return `http://localhost:3009/api/${t[type]}/login-token`;
  }
};
export const registerAPI = () => {
  if (process.env.NODE_ENV === "development") {
    // return "http://localhost:3009/api/users/register";
    return "https://9lrgyifu17.execute-api.us-west-2.amazonaws.com/dev";
  }
  if (process.env.NODE_ENV === "production") {
    // return 'https://9lrgyifu17.execute-api.us-west-2.amazonaws.com/prod';
    return "https://9lrgyifu17.execute-api.us-west-2.amazonaws.com/prod";
  }
};
export const updateUserAPI = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3009/api/users/update-user";
  }
  if (process.env.NODE_ENV === "production") {
    return "http://localhost:3009/api/users/update-user";
  }
};

export const loginClinicaAPI = (type) => {
  const url = `https://skjs0lzafj.execute-api.us-west-2.amazonaws.com/${process.env.REACT_APP_ENV}`;
  //console.log(url)
  if (process.env.NODE_ENV === "development") {
    return "https://skjs0lzafj.execute-api.us-west-2.amazonaws.com/dev";
  }
  if (process.env.NODE_ENV === "production") {
    return " https://skjs0lzafj.execute-api.us-west-2.amazonaws.com/prod";
  }
};
export const registerClinicaAPI = () => {
  if (process.env.NODE_ENV === "development") {
    return "https://o8p3sus5n3.execute-api.us-west-2.amazonaws.com/dev";
  }
  if (process.env.NODE_ENV === "production") {
    return "https://o8p3sus5n3.execute-api.us-west-2.amazonaws.com/dev";
    // return "http://localhost:3009/api/clinicas/register";
  }
};
export const updateClinicaAPI = () => {
  //console.log(process.env.NODE_ENV)
  if (process.env.NODE_ENV === "development") {
    return "https://a9lcglukjh.execute-api.us-west-2.amazonaws.com/dev";
  }
  if (process.env.NODE_ENV === "production") {
    return "https://a9lcglukjh.execute-api.us-west-2.amazonaws.com/prod";
  }
};
export const getClinicasGeoAPI = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://acme.mipetnow.com/api/clinicas/get-location";
  }
  if (process.env.NODE_ENV === "production") {
    return "http://35.162.226.98:3009/api/clinicas/get-location";
  }
};
