import React, { useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import "./index.scss";
import { cleanFormatRut, formatRut, validateRut } from "../../Common/utils";
import { registerClinicaAPI } from "services/endpoint";
import { useHistory } from "react-router-dom";
import UserPool from "Pages/AuthLive/AuthViews/UserPool";
import {
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    width: "100%",
    maxWidth: "400px",
    //display: "block",
  },
  inputs: {
    display: "block",
    width: "100%",
    maxWidth: "400px",
  },
  buttonRegister: {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    marginTop: "3rem",
  },
  buttonSecondary: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    marginTop: "3rem",
  },
  // textField: {
  //     marginLeft: theme.spacing(1),
  //     marginRight: theme.spacing(1),
  //     width: '100%',
  //   },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const FormClinica = (props) => {
  const [email, setemail] = useState("");
  const [Rut, setRut] = useState("");
  const [pass, setpass] = useState("");
  const [nombre, setnombre] = useState("");
  const [tipo, settipo] = useState("");
  const [apellido, setapellido] = useState("");
  const classes = useStyles();
  const [ShowForm, setShowForm] = useState(true);
  const [Error, setError] = useState({});
  const [PasswordShow, setPasswordShow] = useState({ showPassword: false });
  const [NombreClinica, setNombreClinica] = useState("");
  const [ErrorRut, setErrorRut] = useState(false);
  const [ErrorPass, setErrorPass] = useState(false)
  let history = useHistory();

  const handleChangeEmail = (event) => {
    setemail(event.target.value);
  };
  const handleChangeNombre = (event) => {
    setnombre(event.target.value);
  };
  const handleChangeApellido = (event) => {
    setapellido(event.target.value);
  };
  const handleChangeTipo = (event) => {
    settipo(event.target.value);
  };
  const handleChangePass = (event) => {
    setpass(event.target.value);
  };
  const handleChangeRut = (event) => {
    //validar Rut
    setRut(event.target.value);
  };
  const handleOnBlurRut = () => {
    if (validateRut(Rut)) {
      setErrorRut(false);
      setRut(formatRut(Rut));
    } else {
      setErrorRut(true);
      return false;
    }
  };
  const handleOnFocus = () => {
    setRut(cleanFormatRut(Rut));
  };
  const handleClickShowPassword = () => {
    setPasswordShow({ showPassword: !PasswordShow.showPassword });
    setpass(pass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeNombreClinica = (event) => {
    setNombreClinica(event.target.value);
  };
  const goHome = () => {
    history.push("/");
  };

  const setCognitoUserAttribute = (attributeKey, attributeValue) => {
    let data = {
      Name: attributeKey,
      Value: attributeValue,
    };

    return new CognitoUserAttribute(data);
  };
  const signupCognitoUser = (values) => {
    let { email, password, name, last_name, name_clinica, rut } = values;
    let attributeList = [];

    // const userPool = UserPool()

    attributeList.push(setCognitoUserAttribute("custom:name", name));
    attributeList.push(setCognitoUserAttribute("custom:last_name", last_name));
    attributeList.push(setCognitoUserAttribute("custom:name_company", name_clinica));
    attributeList.push(setCognitoUserAttribute("custom:rut", rut));
     attributeList.push(setCognitoUserAttribute("custom:type", '2'));
     attributeList.push(setCognitoUserAttribute("family_name", last_name));
     attributeList.push(setCognitoUserAttribute("profile", ''));
     attributeList.push(setCognitoUserAttribute("name", name));

    return new Promise((resolve, reject) =>
      UserPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      })
    );
  };
  const handleSubmit = async () => {
    const payload = {
      email: email,
      name: nombre,
      last_name: apellido,
      name_clinica: NombreClinica,
      tag_name: NombreClinica.toLowerCase().replace(" ", ""),
      password: pass,
      display_name: nombre + " " + apellido,
      disabled: true,
      email_verified: false,
      rut: Rut,
      gender: "",
      type: 2,
      application: "mipetnow",
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    };
    const data = {
      name: nombre,
      last_name: apellido,
      name_clinica: NombreClinica,
      rut: Rut,
      email: email,
      password: pass,
    };
    await signupCognitoUser(data)
      .then((res) => {
        setErrorPass(false)
        console.log(
          "Your registration is successful. Instructions have been sent to your email."
        );
        alert('Tu cuenta ha sido creada con exito')
        // setShowForm(false);
        //      props.handleShowForm(false)
      })
      .catch((err) => {
        setErrorPass(true)
        //console.log(err.code, err.message);
      });
    // let attributeList = [];
    // attributeList.push(setCognitoUserAttribute('name', nombre));
    // const emailAttribute = new CognitoUserAttribute({
    //   Name: 'custom:name',
    //   Value: nombre
    //  });
    // await UserPool.signUp(email, pass, [emailAttribute], null, (err, data) => {
    //   if (err) {
    //     console.error(err);
    //   }
    //   //console.log(data);
    // });
    //TODO cambiar por axios.
    // fetch(registerClinicaAPI(), requestOptions)
    //   .then((response) => response.json())
    //   .then(async (data) => {
    //     //console.log("data : ", data);
    //     if (data.statusCode === 409) {
    //       setError({
    //         message:
    //           "El correo ingresado ya se encuentra registra, intenta con otro.",
    //       });
    //       return;
    //     }

    //     setShowForm(false);
    //     props.handleShowForm(false)
    //   })
    //   .catch((err) => {
    //     //console.log("err ", err);
    //   });
  };

  return (
    <>
      {!ShowForm ? (
        <div className="form-clinica">
          <h1>Felicitaciones!</h1>
          <h2>
            Te acabas de registrar en la red social para mascotas más grande de
            chile!
          </h2>
          <div className="importante">
            <p>
              Puedes iniciar sesion, agregar fotos a tu galeria, y configurar tu
              perfil, el cual sera publico posterior a validar la veracidad de
              tus datos.
            </p>
          </div>
          <div className="info">
            <p>
              No olvides escribirnos a <strong>hello@mipetnow.com</strong> si
              tienes alguna duda, queja, idea, ayuda, lo que sea, intentaremos
              apoyarte.
            </p>
          </div>
          <div className="options">
            <Button variant="outlined" onClick={goHome}>
              Ir al inicio
            </Button>
          </div>
        </div>
      ) : (
        <div className="registerEmail--inputs">
          <div className="title">
            <h3>Registrate aqui!</h3>
            <p>
              Despues de registrarte recibiras un correo con la información que
              debes enviar para darte de alta, esto con el fin de evitar
              fraudes.
            </p>
          </div>
          <TextField
            id="standard-helperText"
            label="Rut Clinica"
            value={Rut}
            onChange={handleChangeRut}
            onBlur={handleOnBlurRut}
            onFocus={handleOnFocus}
            className={classes.inputs}
            fullWidth
            variant="outlined"
          />
          {ErrorRut ? <p>El rut ingresado no es valido</p>: null}
          <TextField
            id="standard-helperText"
            label="Nombre Clinica"
            value={NombreClinica}
            onChange={handleChangeNombreClinica}
            className={classes.inputs}
            fullWidth
            variant="outlined"
          />
          <TextField
            id="standard-helperText"
            label="Email"
            value={email}
            onChange={handleChangeEmail}
            className={classes.inputs}
            fullWidth
            variant="outlined"
          />
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={PasswordShow.showPassword ? "text" : "password"}
              value={pass}
              onChange={handleChangePass}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {PasswordShow.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
            {ErrorPass ? <p>Recuerda, debe tener al menos una letra Mayuscula, una  minuscula, y numeros.</p> : null }
          </FormControl>

          <TextField
            id="standard-helperText"
            label="Nombre"
            value={nombre}
            onChange={handleChangeNombre}
            className={classes.inputs}
            fullWidth
            variant="outlined"
          />
          <TextField
            id="standard-helperText"
            label="Apellido"
            value={apellido}
            onChange={handleChangeApellido}
            className={classes.inputs}
            fullWidth
            variant="outlined"
          />
          <Button
            variant="contained"
            color="primary"
            className="button-primary"
            onClick={handleSubmit}
            fullWidth
          >
            Crear Cuenta
          </Button>
          <p style={{ color: "red" }}>{Error.message}</p>
          <Button
            variant="outlined"
            className="button-secondary"
            onClick={() => {
              props.setTypeUser(-1);
              props.handleShowForm(-1);
            }}
            fullWidth
          >
            Soy otro tipo de usuario
          </Button>
        </div>
      )}
    </>
  );
};
