import { exploreclinicasReducer } from "Pages/ExploreClinicas/ExploreClinicasReducer";
import { combineReducers } from "redux";
import { empleadosEditReducer, empleadosEliminarReducer, empleadosReducer } from "../../Pages/EmpleadosProfesionales/EmpleadosProfesonalesReducer";
import { professionalsProfilesReducer } from "../../Pages/PageInit/PageInitReducer";
import { sucursalReducer,sucursalesReducer, sucursalEliminarReducer } from "../../Pages/Sucursales/SucursalReducer";

import {profesionalReducer, userReducer} from "../../User/userReducer";

export default combineReducers({
  user:userReducer,
  profesional:profesionalReducer,
  professionalsProfiles:professionalsProfilesReducer,
  sucursal:sucursalReducer,
  sucursales:sucursalesReducer,
  sucursalEliminar:sucursalEliminarReducer,
  empleado:empleadosEditReducer,
  empleadosEdit:empleadosEditReducer,
  empleadosEliminar:empleadosEliminarReducer,
  exploreClinicas:exploreclinicasReducer
});

