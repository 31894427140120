import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import './CardClinicaDestacada.scss';
import { Button } from '@material-ui/core';
/**
 * @name CardClinicaDestacada
 * @param {*} props 
 */
const CardClinicaDestacada = (props) => {
    return (
        <div className="card-clinica-destacada_container"> 
             <div className="card-clinica-destacada_container-title">
                <p>Tienda 1</p>
            </div>
            <div className="card-clinica-destacada_container-body">
                <p>
                    Tenemos de promocion 3 productos x 1
                </p>
            </div>
            <div className="card-clinica-destacada_container-option">
                <Button fullWidth>Ir a ver</Button>
            </div>
        </div>
    )
}

CardClinicaDestacada.propTypes = {

}

export default CardClinicaDestacada
