import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Graph } from "react-d3-graph";
import { PageInit } from "../../Pages/PageInit/PageInit";
import { getUserAction, setUserAction } from "../../User/userAction";
export const DashboardCliente = (props) => {
  const User = useSelector((state) => state.user);

  // graph payload (with minimalist structure)
  const data = {
    nodes: [{ id: "Harry" }, { id: "Sally" }, { id: "Alice" }],
    links: [
      { source: "Harry", target: "Sally" },
      { source: "Harry", target: "Alice" },
    ],
  };

  // the graph configuration, just override the ones you need
  const myConfig = {
    nodeHighlightBehavior: true,
    node: {
      color: "lightgreen",
      size: 120,
      highlightStrokeColor: "blue",
    },
    link: {
      highlightColor: "lightblue",
    },
  };

  const onClickNode = function (nodeId) {
    window.alert(`Clicked node ${nodeId}`);
  };

  const onClickLink = function (source, target) {
    window.alert(`Clicked link between ${source} and ${target}`);
  };

  return (
    <div>
     <h3>Busca y agenda tu proxima visita</h3>
    </div>
  );
};



export default DashboardCliente;
