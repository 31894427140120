import {
  GET_EXPLORECLINICAS,
  GET_EXPLORECLINICAS_SUCCESS,
  SET_EXPLORECLINICAS,
  GET_EXPLORECLINICAS_FAILURE,
  SET_COMMUNE,
  SET_CLINICA_SELECTED,
} from './ExploreClinicasAction';

const initialState = {
  clinicas:[],
  commune:'',
  clinica_selected:{}
}

export const exploreclinicasReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_EXPLORECLINICAS_SUCCESS:{
      return Object.assign({}, state, {clinicas: action.response});
    }
    case SET_EXPLORECLINICAS:{
      return Object.assign({}, state, action.data);
    }
    case SET_COMMUNE:{
      return Object.assign({},state,{commune:action.data})
    }
    case SET_CLINICA_SELECTED:{
      return Object.assign({},state,{clinica_selected:action.data})
    }
    default:
      return state;
  }
};