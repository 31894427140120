import { makeStyles, Paper, Tooltip } from "@material-ui/core";
import React from "react";
import "./DashboardClinica.scss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import CardDefault from "Components/Cards/CardDefault/CardDefault";
import useTitle from "Hooks/useTitle";
import Grid from "@material-ui/core/Grid";

// import {Button} from "@nowcompany/storybook/dist/index";
// import {Button} from "@nowcompany/storybook"
const useStyles = makeStyles({
  table: {},
});

function createData(dia, hora, paciente) {
  return { dia, hora, paciente };
}

const rows = [
  createData("11-11-2021", "", 6.0, 24, 4.0),
  createData("10-11-2021", 237, 9.0, 37, 4.3),
  createData("09-11-2021", 262, 16.0, 24, 6.0),
  createData("08-11-2021", 305, 3.7, 67, 4.3),
  createData("07-11-2021", 356, 16.0, 49, 3.9),
];

const data = {
  labels: ["1", "2", "3", "4", "5", "6"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      fill: false,
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgba(255, 99, 132, 0.2)",
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};
const DashboardClinica = () => {
  const classes = useStyles();
  const User = useSelector((state) => state.user);
  useTitle({ title: "Dashboard" });
  const b = {
    primary: true,
    label: "Button",
  };
  return (
    <div className="dashboard-clinica">
      <div className="dashboard-clinica_title">
        <h1>Bienvenido a tu dashboard</h1>
        <h3>Aqui podras encontrar información imporante para tu negocio</h3>
      </div>
      <div className="dashboard-clinica_metrics">
       
          <CardDefault>
            <div style={{ textAlign: "center" }}>
              <h1>{User.profile_metric && User.profile_metric.visited || 0}</h1>
              <p>Total de visitas a tu perfil</p>
            </div>
          </CardDefault>
          <CardDefault>
            <div style={{ textAlign: "center" }}>
              <h1>{User.profile_metric && User.profile_metric.visited || 0}</h1>
              <p>Total de visitas a tu perfil</p>
            </div>
          </CardDefault>
          <CardDefault>
            <div style={{ textAlign: "center" }}>
              <h1>{User.profile_metric && User.profile_metric.visited || 0}</h1>
              <p>Total de visitas a tu perfil</p>
            </div>
          </CardDefault>
          <CardDefault>
            <div style={{ textAlign: "center" }}>
              <h1>{User.profile_metric && User.profile_metric.visited || 0}</h1>
              <p>Total de visitas a tu perfil</p>
            </div>
          </CardDefault>
          <CardDefault>
            <div style={{ textAlign: "center" }}>
              <h1>{User.profile_metric && User.profile_metric.visited || 0}</h1>
              <p>Total de visitas a tu perfil</p>
            </div>
          </CardDefault>
        
      </div>
      <div className="dashboard-clinica_parent">
        <div className="dashboard-clinica_parent__events">
          {/* <Paper elevation={3} className="paper">
            <p>Hoy has aparecido en <strong>10</strong> busquedas</p>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Día</TableCell>
                    <TableCell align="left">Hora</TableCell>
                    <TableCell align="left">Cantidad</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.hora}>
                      <TableCell>{row.dia}</TableCell>
                      <TableCell align="right">{row.hora}</TableCell>
                      <TableCell align="right">{row.cantidad}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper> */}
        </div>
        <div className="dashboard-clinica_parent__sales">
          {/* <Paper elevation={3} className="paper">
            <p>Resumen de Ventas</p>
            <Line data={data} options={options} />
          </Paper> */}
        </div>
        <div className="dashboard-clinica_parent__inventory">
          {/* <Paper elevation={3} className="paper">
            <p>Inventario</p>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Hora</TableCell>
                    <TableCell align="left">Día</TableCell>
                    <TableCell align="left">Paciente</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.hora}>
                      <TableCell>{row.hora}</TableCell>
                      <TableCell align="right">{row.dia}</TableCell>
                      <TableCell align="right">{row.paciente}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper> */}
        </div>
        <div className="dashboard-clinica_parent__clients">
          {/* <Paper elevation={3} className="paper">
            <p>Clientes</p>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Hora</TableCell>
                    <TableCell align="left">Día</TableCell>
                    <TableCell align="left">Paciente</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.hora}>
                      <TableCell>{row.hora}</TableCell>
                      <TableCell align="right">{row.dia}</TableCell>
                      <TableCell align="right">{row.paciente}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper> */}
        </div>
      </div>{" "}
    </div>
  );
};

export default DashboardClinica;
