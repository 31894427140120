import { getEmpleadosEditService, getEmpleadosEliminarService, getEmpleadosService } from "./EmpleadosProfesonalesService";


export const GET_EMPLEADOS = 'GET_EMPLEADOS';
export const SET_EMPLEADOS = 'SET_EMPLEADOS';
export const GET_EMPLEADOS_REQUEST = 'GET_EMPLEADOS_REQUEST';
export const GET_EMPLEADOS_SUCCESS = 'GET_EMPLEADOS_SUCCESS';
export const GET_EMPLEADOS_FAILURE = 'GET_EMPLEADOS_FAILURE';
export const getEmpleadosAction = req => ({
    type: GET_EMPLEADOS,
    req,
    promise: getEmpleadosService(req),
});

export const setEmpleadosAction = data => ({
    type: SET_EMPLEADOS,
    data
});


export const GET_EMPLEADOS_EDIT = 'GET_EMPLEADOS_EDIT';
export const SET_EMPLEADOS_EDIT = 'SET_EMPLEADOS_EDIT';
export const GET_EMPLEADOS_EDIT_REQUEST = 'GET_EMPLEADOS_EDIT_REQUEST';
export const GET_EMPLEADOS_EDIT_SUCCESS = 'GET_EMPLEADOS_EDIT_SUCCESS';
export const GET_EMPLEADOS_EDIT_FAILURE = 'GET_EMPLEADOS_EDIT_FAILURE';
export const getEmpleadosEditAction = req => ({
    type: GET_EMPLEADOS_EDIT,
    req,
    promise: getEmpleadosEditService(req),
});

export const setEmpleadosEditAction = data => ({
    type: SET_EMPLEADOS_EDIT,
    data
});

export const GET_EMPLEADOS_ELIMINAR = 'GET_EMPLEADOS_ELIMINAR';
export const SET_EMPLEADOS_ELIMINAR = 'SET_EMPLEADOS_ELIMINAR';
export const GET_EMPLEADOS_ELIMINAR_REQUEST = 'GET_EMPLEADOS_ELIMINAR_REQUEST';
export const GET_EMPLEADOS_ELIMINAR_SUCCESS = 'GET_EMPLEADOS_ELIMINAR_SUCCESS';
export const GET_EMPLEADOS_ELIMINAR_FAILURE = 'GET_EMPLEADOS_ELIMINAR_FAILURE';
export const getEmpleadosEliminarAction = req => ({
    type: GET_EMPLEADOS_ELIMINAR,
    req,
    promise: getEmpleadosEliminarService(req),
});

export const setEmpleadosEliminarAction = data => ({
    type: SET_EMPLEADOS_ELIMINAR,
    data
});