
import { getUserNormalizer } from "../services/user";

export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";

export const setUserAction = data => ({
  type: SET_USER,
  data,
});

export const getUserAction = (req) => ({
  type: GET_USER,
  req,
  promise: getUserNormalizer(req),
});


export const GET_PROFESSIONAL = 'GET_PROFESSIONAL';
export const SET_PROFESSIONAL = 'SET_PROFESSIONAL';
export const GET_PROFESSIONAL_REQUEST = 'GET_PROFESSIONAL_REQUEST';
export const GET_PROFESSIONAL_SUCCESS = 'GET_PROFESSIONAL_SUCCESS';
export const GET_PROFESSIONAL_FAILURE = 'GET_PROFESSIONAL_FAILURE';
export const getProfesionalAction = req => ({
    type: GET_PROFESSIONAL,
    req,
});

export const setProfesionalAction = data => ({
    type: SET_PROFESSIONAL,
    data
});