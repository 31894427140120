import React, { Component, useState, useRef, useEffect } from "react";
import "./Profile.scss";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { getUserAction, setUserAction } from "../../User/userAction";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Loader } from "../../Components/Loader/Loader";
import { ProfilePrivate } from "./private/profilePrivate";
import ProfilePublic from "./public/profilePublic";
import Pet from "./pet/pet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box div={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export const Profile = (props) => {
  ////console.log("regionsandcommunes", regionsandcommunes.default.regions);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [User, setUser] = useState(() => {
    //console.log(props.user);
    if (props.user.isLogin) {
      return props.user;
    }
  });
  // const setStatex = (event, k) => {
  //   setUser({ ...User, [k]: event.target.value });
  // };
  // const saveData = () => {
  //   setisLoading(true)
  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({
  //       ...User,
  //     }),
  //   };
  //   fetch(
  //     "https://us-central1-nowcompanyglobal.cloudfunctions.net/updateDataUser",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then(async (data) => {
  //       //console.log("data ", data);

  //       setisLoading(false)
  //     })
  //     .catch((err)=>{
  //       setisLoading(false)
  //       alert("Hubo un error", err)
  //     });
  //   props.setUserAction({ isLogin: true, data: { ...User } });
  //   let data = { isLogin: true, data: { ...User } }
  //       localStorage.setItem("user", JSON.stringify(data));
  // };
  const handleChange = (event, newValue) => {
    setValue(newValue);
    //console.log(User.type);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Mis datos" {...a11yProps(0)} />
          {/* {User.type === 2 ? <Tab label="Perfil Publico"></Tab> : null} */}
          {User.type === 2 ? <Tab label="Datos de pagos"></Tab> : null}
          {User.type === 2 ? <Tab label="Mi suscripcion"></Tab> : null}
          {/* {User.type === "1" ? (
            <Tab label="Mi perfil publico" {...a11yProps(1)} />
          ) : null}
          {User.type === "0" ? (
            <Tab label="Mis mascotas" {...a11yProps(1)} />
          ) : null}
          {User.type === "1" ? (
            <Tab label="Mis mascotas" {...a11yProps(2)} />
          ) : null} */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ProfilePrivate {...props}></ProfilePrivate>
      </TabPanel>
      {/* {User.type === 2 ? (
        <TabPanel value={value} index={1}>
          <h1>Perfil publico</h1>
        </TabPanel>
      ) : null} */}
      {User.type === 2 ? (
        <TabPanel value={value} index={1}>
          <h1>Datos de pagos</h1>
        </TabPanel>
      ) : null}
      {User.type === 2 ? (
        <TabPanel value={value} index={2}>
          <h1>Mi suscripción</h1>
        </TabPanel>
      ) : null}
      {/* {User.type === "1" ? <TabPanel value={value} index={1}>
        <ProfilePublic {...props}></ProfilePublic>
      </TabPanel> : null}
      { User.type === "0" ? <TabPanel value={value} index={1}>
        <Pet {...props}></Pet>
      </TabPanel> : null}
      {User.type === "1"  ? <TabPanel value={value} index={2}>
        <Pet {...props}></Pet>
      </TabPanel> : null} */}
    </div>
  );
};
const mapStateToProps = (state) => {
  //console.log("state : ", state);
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  setUserAction: (data) => dispatch(setUserAction(data)),
  getUserAction: () => dispatch(getUserAction),
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
