import {getProfessionalsProfilesData} from './PageInitService';
export const GET_PROFESSIONALS_PROFILES = 'GET_PROFESSIONALS_PROFILES';
export const SET_PROFESSIONALS_PROFILES = 'SET_PROFESSIONALS_PROFILES';
export const GET_PROFESSIONALS_PROFILES_REQUEST = 'GET_PROFESSIONALS_PROFILES_REQUEST';
export const GET_PROFESSIONALS_PROFILES_SUCCESS = 'GET_PROFESSIONALS_PROFILES_SUCCESS';
export const GET_PROFESSIONALS_PROFILES_FAILURE = 'GET_PROFESSIONALS_PROFILES_FAILURE';
export const getProfessionalProfilesAction = req => ({
    type: GET_PROFESSIONALS_PROFILES,
    req,
    promise: getProfessionalsProfilesData(req),
});

export const setProfessionalProfilesAction = data => ({
    type: SET_PROFESSIONALS_PROFILES,
    data
});