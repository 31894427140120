import {
  SET_USER,
  GET_USER,
  SET_PROFESSIONAL,
  GET_PROFESSIONAL
} from './userAction';


const initialState = {
  isLogin:false
};
export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return Object.assign({}, state, action.data);
    case GET_USER:
      return Object.assign({}, state, action.response);
    default:
      return state;
  }
};



const initialProfState = {
  
};
export const profesionalReducer = (state = initialProfState, action) => {
  switch (action.type) {
    case SET_PROFESSIONAL:
      return Object.assign({}, state, action.data);
    case GET_PROFESSIONAL:
      return Object.assign({}, state, action.response);
    default:
      return state;
  }
};


