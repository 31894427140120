import { GET_EMPLEADOS, SET_EMPLEADOS } from "./EmpleadosProfesonalesAction";

const initialEmpleadosState = {};

export const empleadosReducer = (state = initialEmpleadosState, action) => {
  switch (action.type) {
    case SET_EMPLEADOS:
      return Object.assign({}, state, action.data);
    case GET_EMPLEADOS:
      return Object.assign({}, state, action.response);
    default:
      return state;
  }
};

const initialEmpleadosEditState = {};

export const empleadosEditReducer = (state = initialEmpleadosEditState, action) => {
  switch (action.type) {
    case SET_EMPLEADOS:
      return Object.assign({}, state, action.data);
    case GET_EMPLEADOS:
      return Object.assign({}, state, action.response);
    default:
      return state;
  }
};


const initialEmpleadosEliminarState = {};

export const empleadosEliminarReducer = (state = initialEmpleadosEliminarState, action) => {
  switch (action.type) {
    case SET_EMPLEADOS:
      return Object.assign({}, state, action.data);
    case GET_EMPLEADOS:
      return Object.assign({}, state, action.response);
    default:
      return state;
  }
};
