import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useState } from "react";
import { REGEX_RUT, formatRut, validateRut, cleanFormatRut } from "../../Common/utils";
import "./index.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {
    //margin: theme.spacing(1),
    width: "100%",
    maxWidth: "400px",
    //display: "block",
  },
  inputs: {
    display: "block",
    width: "100%",
    maxWidth: "400px",
  },
  buttonRegister: {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    marginTop: "3rem",
  },
  buttonSecondary: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    marginTop: "3rem",
  },
  // textField: {
  //     marginLeft: theme.spacing(1),
  //     marginRight: theme.spacing(1),
  //     width: '100%',
  //   },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const FormTienda = (props) => {
  const [email, setemail] = useState("");
  const [Rut, setRut] = useState("");
  const [pass, setpass] = useState("");
  const [nombre, setnombre] = useState("");
  const [tipo, settipo] = useState("");
  const [apellido, setapellido] = useState("");
  const classes = useStyles();
  const [ShowForm, setShowForm] = useState(true);
  const [Error, setError] = useState({});
  const [PasswordShow, setPasswordShow] = useState({ showPassword: false });

  const handleChangeEmail = (event) => {
    setemail(event.target.value);
  };
  const handleChangeNombre = (event) => {
    setnombre(event.target.value);
  };
  const handleChangeApellido = (event) => {
    setapellido(event.target.value);
  };
  const handleChangeTipo = (event) => {
    settipo(event.target.value);
  };
  const handleChangePass = (event) => {
    setpass(event.target.value);
  };
  const handleChangeRut = (event) => {
    //validar Rut
    setRut(event.target.value)
  };
  const handleOnBlurRut = () => {
    if (validateRut(Rut)) {
      setRut(formatRut(Rut))
    }
    else {
      return false;
    }
  }
  const handleOnFocus = () => {
    setRut(cleanFormatRut(Rut))
  }
  const handleClickShowPassword = () => {
    setPasswordShow({ showPassword: !PasswordShow.showPassword });
    setpass(pass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="registerEmail--inputs">
      <div className="title">
        <h3>Registrate aqui</h3>
        <p>
          Despues de registrarte, recibiras un correo con informacion sobre que debes hacer para darte de alta.
        </p>
      </div>
      {/* <form onSubmit={handleSubmit}> */}
      <TextField
        id="standard-helperText"
        label="Rut"
        value={Rut}
        onChange={handleChangeRut}
        onBlur={handleOnBlurRut}
        onFocus={handleOnFocus}
        className={classes.inputs}
        fullWidth
        variant="outlined"
        variant="outlined"
      />
      <TextField
        id="standard-helperText"
        label="Email"
        value={email}
        onChange={handleChangeEmail}
        className={classes.inputs}
        fullWidth
        variant="outlined"
      />
      <FormControl variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={PasswordShow.showPassword ? "text" : "password"}
          value={pass}
          onChange={handleChangePass}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {PasswordShow.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>
      <TextField
        id="standard-helperText"
        label="Nombre"
        value={nombre}
        onChange={handleChangeNombre}
        className={classes.inputs}
        fullWidth
        variant="outlined"
      />
      <TextField
        id="standard-helperText"
        label="Apellido"
        value={apellido}
        onChange={handleChangeApellido}
        className={classes.inputs}
        fullWidth
        variant="outlined"
      />
      <Button
        variant="contained"
        color="primary"
        className="button-primary"
        onClick={props.handleSubmit}
        fullWidth
      >
        Crear Cuenta
      </Button>
      <p style={{ color: "red" }}>{props.Error.message}</p>
      <Button
        variant="outlined"
        className="button-secondary"
        onClick={() => {
          props.setTypeUser(-1);
          props.handleShowForm(-1)
        }}
        fullWidth
      >
        Soy otro tipo de usuario
      </Button>
    </div>
  );
};
