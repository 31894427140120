import React, { Component, useState, useRef, useEffect } from "react";
import "./index.scss";
import { connect, useSelector } from "react-redux";
import { getUserAction, setUserAction } from "../../../User/userAction";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import * as regionsandcommunes from "../../../Common/regionsandcomunes.json";
import { Loader } from "../../../Components/Loader/Loader";
import * as defaultProfile from "./defaultProfile.png";
import { convertBase64 } from "../../../Common/utils";
import { getUserData, updateDataUser } from "../../../User/userService";
import { updateUserAPI, updateClinicaAPI } from "services/endpoint";
import { DriveEtaTwoTone } from "@material-ui/icons";
import axios from "axios";
import { uploadImage } from "Common/services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => ({
  setUserAction: (data) => dispatch(setUserAction(data)),
  getUserAction: () => dispatch(getUserAction),
});
export const ProfilePrivate = (props) => {
  const classes = useStyles();
  // const [User, setUser] = useState(() => {
  //   //console.log("props.user : : - : ", props.user);
  //   return props.user;
  // });
  const User = useSelector((state) => state.user);
  const [Base64, setBase64] = useState(() => {
    if (props.user.photo_profile) return props.user.photo_profile;
    return "";
  });
  const [PrevBase64, setPrevBase64] = useState("");
  const [Email, setEmail] = useState(User.email);
  const [Name, setName] = useState(User.name);
  const [LastName, setLastName] = useState(User.last_name);
  const [Description, setDescription] = useState(User.description);
  const [Direction, setDirection] = useState(User.direction);
  const [Region, setRegion] = useState(User.region);
  const [Commune, setCommune] = useState(User.commune);
  const [isLoading, setisLoading] = useState(false);
  const [TempRegion, setTempRegion] = useState("");
  const [NameClinica, setNameClinica] = useState(User.name_clinica);
  const [NumberPhone, setNumberPhone] = useState(User.number_phone || "");
  // const [Region, setRegion] = useState("");
  const [Regiones, setRegiones] = useState(() => {
    let regiones = regionsandcommunes.default.regions;
    const listregiones = regiones.map((region) => {
      let objRegion = { id: region.number + "", value: region.name };
      return objRegion;
    });
    return listregiones;
  });
  const [file, setFile] = useState();
  const [FileChanged, setFileChanged] = useState(false);
  const refFile = useRef(null);
  const [Photo, setPhoto] = useState(() => {
    return User.photo_profile || "";
  });
  useEffect(() => {
    setPrevBase64(Base64);
    // setFileChanged(Base64 === PrevBase64 ? false : true);

  }, []);
  useEffect(() => {
    let region = regionsandcommunes.default.regions.filter((region) => {
      return region.number === User.region;
    });
    //console.log("region : ", region);
    setTempRegion(region);
  }, [User]);

  const handleChangeRegion = (event) => {
    // setUser({ ...User, region: event.target.value });
    setRegion(event.target.value);
    let region = regionsandcommunes.default.regions.filter((region) => {
      return region.number === event.target.value;
    });
    //console.log("TEMP REGION : ", region);
    setTempRegion(region);
  };
  const handleChangeComuna = (event) => {
    //console.log(event.target.value);
    setCommune(event.target.value);
    // setUser({ ...User, commune: event.target.value });
  };
  const handleOnChangeNumberPhone = (value) => {
    if(value[value.length - 1] === undefined || value.length >= 12){
      value = value.slice(0,value[value.length - 1])
      return;
    }
    setNumberPhone(value);
  }
  const handlechangePhoto = async (e) => {
    //console.log("handle click input change pohoto");
    e.preventDefault();
    setisLoading(true);

    var file = e.target.files[0];
    setFile(file);
    let base64 = file === undefined ? "" : await convertBase64(file);
    setPrevBase64(Base64);
    setBase64(base64);

    setisLoading(false);
    setFileChanged(Base64 === PrevBase64 ? false : true);
  };
  const submit = async (event) => {
    event.preventDefault();
    const q1 = User.photo_profile ? User.photo_profile : '';
    const q2 = q1.split("/");
    const q3 = q2.length >= 6 ? q2[6] : '';
    const result = await uploadImage({
      _id: User._id,
      image: file,
      folder: "pic_profile",
      description: "test",
      attr: "photo_profile",
      last_id: q3,
    });
    if(result){
      setPrevBase64(Base64)
    }
    //const result = await postImage({ image: file, description: "test" });
    //setImages([result.image, ...images])
  };
  const saveData = async () => {
    //console.log("save data");
    setisLoading(true);
    const formData = new FormData();
    formData.append("photo_profile", refFile.current.files[0]);
    const dataToUpdate = {
      email: Email,
      name: Name,
      last_name: LastName,
      direction: Direction,
      description: Description,
      commune: Commune,
      region: Region,
      uuid: User._id,
      name_clinica: NameClinica,
      number_phone: NumberPhone,
    };
    //console.log("dataToUpdate : ", dataToUpdate);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...dataToUpdate,
      }),
    };

    if (User.type === "2") {
      await axios
        .post(updateClinicaAPI(), { ...dataToUpdate })
        .then((resp) => resp.data)
        .then((resp) => {
          //console.log(resp);
          props.setUserAction(resp.user);
          setisLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const setStatex = (event, k) => {
    // setUser({ ...User, [k]: event.target.value });
  };
  return (
    <div className="profile-page">
      {isLoading && User.email !== "" ? <Loader></Loader> : null}

      <div className="profile-page--container">
        <div className="profile--col1">
          <div className="profile_container">
              <div
                className="profile--img--container"
                onClick={() => {
                  refFile.current.click();
                }}
              >
                <div
                  className="profile--img"
                  style={{
                    backgroundImage: `url(${
                      Base64 === undefined || Base64 === ""
                        ? "https://i.ibb.co/bzj5jvH/default-Profile.png"
                        : Base64
                    })`,
                    backgroundSize: "cover"
                  }}
                ></div>
                <input
                  type="file"
                  hidden
                  ref={refFile}
                  onChange={(e) => handlechangePhoto(e)}
                />
                <span class="tooltiptext">Cambiar foto</span>
              </div>
            
            <div className="btn-container">
              <form onSubmit={submit}>
                <button type="submit" disabled={Base64 === PrevBase64 ? true : false}>
                  Guardar foto
                </button>
              </form>
            </div>
          </div>
          <div className="profile_container">
            <h3>{props.user.name + " " + props.user.last_name}</h3>
            <h4>{props.user.username}</h4>
            {props.user.type === "0" ? <p>Cliente</p> : null}
            {props.user.type === "1" ? <p>Profesional</p> : null}
            {props.user.type === "2" ? (
              <p>
                Administrador de <strong>{User.name_clinica}</strong>
              </p>
            ) : null}
          </div>
        </div>
        <div className="profile--col2">
          <div className="container">
            <div className="title">
              <h3>Edita tus datos</h3>
            </div>
            <Button
              variant="contained"
              className="button--save"
              color="primary"
              onClick={() => {
                saveData();
              }}
            >
              Guardar datos
            </Button>
          </div>
          <div className="name-lastname">
            <TextField
              id="standard-helperText"
              label="Nombre"
              value={Name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <TextField
              id="standard-helperText"
              label="Apellidos"
              value={LastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </div>

          <div className="clinica-contact">
            {/* {User.type !== 0 ? (
              <TextField
                id="standard-multiline-static"
                label="Descripción"
                multiline
                rows={4}
                fullWidth
                value={Description || ""}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            ) : null} */}
            {User.type === "2" ? (
              <>
                <TextField
                  id="standard-helperText"
                  label="Nombre Clinica"
                  value={NameClinica}
                  onChange={(e) => {
                    setNameClinica(e.target.value);
                  }}
                />
              </>
            ) : null}
            {User.type === "2" ? (
              <>
                <TextField
                  id="standard-helperText"
                  label="Numero movil"
                  value={NumberPhone}
                  type="number"
                  maxLength={11}
                  onChange={(e) => {
                    handleOnChangeNumberPhone(e.target.value);
                  }}
                />
              </>
            ) : null}
          </div>
          <div style={{display:'flex'}}>
            <TextField
              id="standard-helperText"
              label="Dirección"
              helperText={User.type === '2' ? "Donde atiendes": "Donde vives (esto es privado, no sera compartido sin tu permiso)"}
              value={Direction || ""}
              onChange={(e) => {
                setDirection(e.target.value);
              }}
              fullWidth
            />
          </div>
          <div className="region_commune">
            <FormControl>
              <InputLabel id="demo-simple-select-label">Region</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={Region || ""}
                onChange={handleChangeRegion}
              >
                {Regiones.map((region) => {
                  return (
                    <MenuItem key={region.id} value={region.id + ""}>
                      {region.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Comuna</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={Commune || ""}
                onChange={handleChangeComuna}
              >
                {TempRegion[0] &&
                  TempRegion[0]["communes"].map((commune, index) => {
                    return (
                      <MenuItem key={index} value={commune.name}>
                        {commune.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div style={{display:'flex'}}>
            <TextField
              id="standard-helperText"
              label="Email"
              type="email"
              helperText="Email de contacto"
              value={Email || ""}
              onChange={(e) => {
                setStatex(e, "email");
              }}
              fullWidth
            />
          </div>
        </div>
      </div>
      <div className="button_container">
        {" "}
        <Button
          variant="contained"
          className="button__save--mobile"
          color="primary"
          onClick={() => {
            saveData();
          }}
        >
          Guardar datos
        </Button>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePrivate);
